import React from "react";
import { getData, getRarityById } from "../../data/VikingsData.js";
import RarityRank from "./RarityRank";
import { OutlineButton } from "../../Globalstyles";

import {
    RecipeCardSection,
    WorksContent,
    RecipeCardWrapper,
    RecipeSmallCards,
    WorksCardContent,
    WorksTitle,
    WorksContainer,
    WelcomeContentTitle,
    WelcomeContentText,
    WelcomeText,
    RecipeContainer,
    RecipeTitle,
    RecipeContentContainer,
    RecipeBtn,
    RecipeTabContainer,
    RecipeWrapper,
} from "./Ranks.styles";


class Rarity extends React.Component {
    state = {
        datos: [],
        isLoaded: false,
    };

     componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.fetchData();
        }
    } 

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        let datos = getData(this.props.match.params.id);

        this.setState(
            {
                isLoaded: datos === undefined ? false : true,
                datos: datos,
            },
            () => console.log("Ya cargue los datos")
        );
    }
    render() {
        const { datos } = this.state;

        return (
            <>
                



                <WorksContent>
                    <WorksContainer>
                        <WelcomeContentText>
                            <WelcomeContentTitle>Minted Vikings Ranking</WelcomeContentTitle>

                            <WelcomeText>
                                <strong>Viking Punks </strong> will show data for the minted Vikings.{" "}
                                <strong>Every 100 new mints </strong> we will update until the # minted. Then we will recalculate the ranking with the new mints. <strong> (Calculated based on 1566 Mints) </strong>
                            </WelcomeText>
                            <WelcomeText>
                                <strong> Rarity </strong> is calculated using the
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://raritytools.medium.com/ranking-rarity-understanding-rarity-calculation-methods-86ceaeb9b98c"
                                    style={{
                                        color: "red",
                                        textDecoration: "none",
                                    }}
                                >
                                    {" "}
                                    following method.
                                </a>
                                (Site is on the works so expect search functionality and more!)
                            </WelcomeText>
     
                        </WelcomeContentText>

{/*                         <RecipeWrapper>
                            <RecipeContainer>
                                <RecipeContentContainer>
                                    <RecipeTabContainer>
                                        <RecipeBtn to="/ranks/bynft">
                                            <OutlineButton big bigFont bigRadius>
                                                Sort by Id
                                            </OutlineButton>
                                        </RecipeBtn>
                                        <RecipeBtn to="/ranks/byrarity">
                                            <OutlineButton big bigFont bigRadius>
                                                Sort by Rarity
                                            </OutlineButton>
                                        </RecipeBtn>
                                    </RecipeTabContainer>
                                </RecipeContentContainer>
                            </RecipeContainer>
                        </RecipeWrapper> */}


                        <WorksCardContent>
                            <RecipeCardWrapper>
                                <RecipeSmallCards>
                                    {datos.map((s) =>
                                    (
                                        <RarityRank {...this.props} key={s.NFT} viking={s}></RarityRank>
                                    ))}
                                </RecipeSmallCards>
                            </RecipeCardWrapper>
                        </WorksCardContent>
                    </WorksContainer>
                </WorksContent>
            </>
        );
    }
}

export default Rarity;
