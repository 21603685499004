import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import {SpacerSmall,TextTitle,StyledButton5,StyledButton ,Container,TextDescription,SpacerMedium,ResponsiveWrapper, StyledGif,SpacerXSmall,StyledButton10,StyledButton15,StyledButton20,StyledButton50,StyledButton100, SpacerLarge} from "./Mint.styles";
import i2 from "../../images/vgif.gif"
import { RedStrong } from './../Lore/Lore.styles';


function Mint() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Maybe it's your lucky day King!");
  const [claimingNft, setClaimingNft] = useState(false);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your Viking Punk! Taking one from Valhalla");

    setClaimingNft(true);



    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((1 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later or you rejected the transaction - No place for cowards!");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "WOW, you now own a Viking Punk. Go visit Opensea.io to view it."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };


  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);


  return (

      
      <Container flex={1} ai={"center"} style={{ padding: 24 }}>

        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
  
          <Container
            flex={1}
            jc={"flex-start"}
            ai={"center"}
            style={{
              padding: 24,
            }}
          >
            {Number(data.totalSupply) == 10000 ? (
              <>
                <TextTitle style={{ textAlign: "center" }}>
                  The sale has ended.
                </TextTitle>
                <SpacerSmall />
                <TextDescription style={{ textAlign: "center" }}>
                  You can still find a Viking Punk on{" "}
                  <a
                    target={"_blank"}
                  rel="noopener noreferrer"
                    href={"https://opensea.io/collection/the-viking-punks-v2"}
                  >
                    Opensea.io
                  </a>
                </TextDescription>
              </>
            ) : (
              <>
                <TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 32,
                    fontWeight: "bold",
                    width: "100%",
                    color: "Black",
                  }}
                >
                  Take a Viking Punk from Valhalla - Just <RedStrong>1</RedStrong> MATIC each.
                </TextTitle>
                <SpacerXSmall />
                <TextDescription style={{ textAlign: "center" }}>
                  Excluding gas fee.
                </TextDescription>
                <SpacerSmall />
                <TextDescription
                  style={{
                    textAlign: "center",
                    color: "red",
                    fontSize: 20,
                  }}
                >
                  {feedback}
                </TextDescription>
                <SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <Container ai={"center"} jc={"center"}>
                    <TextDescription style={{ textAlign: "center",fontSize: 20 }}>
                      Let's Connect to the Polygon Network!
                    </TextDescription>
                    <SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      Connect to your Wallet
                    </StyledButton>
                    <SpacerMedium />
                    <TextDescription
                      style={{ textAlign: "center", fontSize: 18 }}
                    >
                      {" "}
                      Check our Collection at{" "}
                      <a
                        target={"_blank"}
                        style={{
                          color: "red",
                        }}
                          rel="noopener noreferrer"
                        href={
                          "https://opensea.io/collection/the-viking-punks-v2"
                        }
                      >
                        Opensea.io
                      </a>
                    </TextDescription>
                    <TextTitle
              style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}
            >
            </TextTitle>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <SpacerSmall />
                        <TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </TextDescription>
                      </>
                    ) : null}
                  </Container>
                ) : (
                  <Container ai={"center"} jc={"center"}>
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(1);
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "MINT 1 VIKING"}
                    </StyledButton>
                    <SpacerSmall />
                    <StyledButton5
                      disabled={claimingNft ? 5 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(5);
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "MINT 5 VIKINGS"}{" "}
                    </StyledButton5>
                    <SpacerSmall />
                    <StyledButton10
                      disabled={claimingNft ? 10 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(10);
                        getData();
                      }}
                    >
                      {" "}
                      {claimingNft ? "BUSY" : "MINT 10 VIKINGS"}{" "}
                    </StyledButton10>
                    <SpacerSmall />
                    <StyledButton15
                      disabled={claimingNft ? 15 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(15);
                        getData();
                      }}
                    >
                      {" "}
                      {claimingNft ? "BUSY" : "MINT 15 VIKINGS"}{" "}
                    </StyledButton15>
                    <SpacerSmall />
                    <StyledButton20
                      disabled={claimingNft ? 20 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(20);
                        getData();
                      }}
                    >
                      {" "}
                      {claimingNft ? "BUSY" : "MINT 20 VIKINGS"}{" "}
                    </StyledButton20>


                    <SpacerSmall />
                    <StyledButton50
                      disabled={claimingNft ? 50 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(50);
                        getData();
                      }}
                    >
                      {" "}
                      {claimingNft ? "BUSY" : "LEGEND - MINT 50 VIKINGS"}{" "}
                    </StyledButton50>
                    <SpacerSmall />

                    <StyledButton100
                      disabled={claimingNft ? 100 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(100);
                        getData();
                      }}
                    >
                      {" "}
                      {claimingNft ? "BUSY" : "KING - MINT 100 VIKINGS"}{" "}
                    </StyledButton100>
                      <TextTitle
                        style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}
                      >
                        Vikings Minted:  {data.totalSupply} / 10000
                      </TextTitle>
                    <SpacerLarge />
                    <SpacerMedium />
        
                    <TextTitle
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: 14,
                      }}
                    >


                      
                      Please make sure you are connected to the right
                      network(Polygon Mainnet) and the correct address. 
                      
                        Note: Once you make the purchase, you cannot undo this
                      action.
                    </TextTitle>
                    <SpacerMedium />
                    <StyledGif alt={"Some Vikings"} src={i2} />
                  </Container>
                )}
              </>
            )}
          </Container>
        </ResponsiveWrapper>
        <SpacerSmall />      
      </Container>

  );
}

export default Mint;
