import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Hero, Footer } from "./components";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Rarity from "./components/Rarity/Rarity";
import Mint from "./components/Mint/Mint";
import Rank from "./components/Ranks/Rank";
import Roadmap from "./components/Roadmap/Roadmap";
import Lore from "./components/Lore/Lore";
import Rarities from "./components/Rarities/Rarities";
import GlobalStyles from "./Globalstyles";


function App() {
  return (
    <Router>
      <GlobalStyles />
      <Hero />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/mint" component={Mint} />
        <Route exact path="/rarities" component={Rarities} />
        <Route exact path="/rarities/:id" component={Rarity} />
        <Route exact path="/ranks" component={Rank} />
        <Route exact path="/ranks/:id" component={Rank} />
        <Route exact path="/roadmap" component={Roadmap} />
        <Route exact path="/lore" component={Lore} />
        <Route exact path="/backgrounds" component={About} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
