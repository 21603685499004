import { OutlineButton } from "../../Globalstyles";
import React from "react";

import {
  RecipeContainer,
  RecipeWrapper,
  RecipeTitle,
  RecipeContentContainer,
  RecipeTabContainer,
  RecipeBtn,
} from "./Rarities.styles";

class Rarities extends React.Component {
  render() {
    return (

      <>
          <RecipeWrapper>
            <RecipeContainer>
              <RecipeTitle>Rarities - Chech Your Viking</RecipeTitle>

              <RecipeContentContainer>

                <RecipeTabContainer>
                  <RecipeBtn to="/rarities/backgrounds">
                    <OutlineButton big bigFont bigRadius>
                      Backgrounds
                    </OutlineButton>
                  </RecipeBtn>

                  <RecipeBtn to="/rarities/heads">
                    <OutlineButton big bigFont bigRadius>
                      Heads
                    </OutlineButton>
                  </RecipeBtn>

                  <RecipeBtn to="/rarities/eyes">
                    <OutlineButton big bigFont bigRadius>
                      Eyes
                    </OutlineButton>
                  </RecipeBtn>

                  <RecipeBtn to="/rarities/beards">
                    <OutlineButton big bigFont bigRadius>
                      Beards
                    </OutlineButton>
                  </RecipeBtn>

                  <RecipeBtn to="/rarities/hairs">
                    <OutlineButton big bigFont bigRadius>
                      Hairs
                    </OutlineButton>
                  </RecipeBtn>

                  <RecipeBtn to="/rarities/mouth">
                    <OutlineButton big bigFont bigRadius>
                      Mouths
                    </OutlineButton>
                  </RecipeBtn>

                  <RecipeBtn to="/rarities/head-marks">
                    <OutlineButton big bigFont bigRadius>
                      Marks
                    </OutlineButton>
                  </RecipeBtn>

                  <RecipeBtn to="/rarities/military-rank">
                    <OutlineButton big bigFont bigRadius>
                      Ranks
                    </OutlineButton>
                  </RecipeBtn>

                  <RecipeBtn to="/rarities/eye-gadgets">
                    <OutlineButton big bigFont bigRadius>
                      Gadgets
                    </OutlineButton>
                  </RecipeBtn>

                  <RecipeBtn to="/rarities/helmets">
                    <OutlineButton big bigFont bigRadius>
                      Helmets
                    </OutlineButton>
                </RecipeBtn>
                
                </RecipeTabContainer>
              </RecipeContentContainer>
            </RecipeContainer>
          </RecipeWrapper>
      </>
    );
  }
}

export default Rarities;
