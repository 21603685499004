const vikings =[
 {
   "NFT": 1052,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask Full Golden",
   "Gadgets_Rarity": 163.93,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 401.32,
   "Rarity_Ranking": 1
 },
 {
   "NFT": 1471,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Hollow",
   "Eyes_Rarity": 136.99,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 391.64,
   "Rarity_Ranking": 2
 },
 {
   "NFT": 1527,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 373.67,
   "Rarity_Ranking": 3
 },
 {
   "NFT": 41,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 364.07,
   "Rarity_Ranking": 4
 },
 {
   "NFT": 286,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 357.02,
   "Rarity_Ranking": 5
 },
 {
   "NFT": 164,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Double Pirate Patch",
   "Gadgets_Rarity": 175.44,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 352.63,
   "Rarity_Ranking": 6
 },
 {
   "NFT": 1377,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Double Pirate Patch",
   "Gadgets_Rarity": 175.44,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 344.21,
   "Rarity_Ranking": 7
 },
 {
   "NFT": 1314,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask Full Golden",
   "Gadgets_Rarity": 163.93,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 343.96,
   "Rarity_Ranking": 8
 },
 {
   "NFT": 208,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Brown",
   "Beard_Rarity": 121.95,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 343.22,
   "Rarity_Ranking": 9
 },
 {
   "NFT": 539,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 340.7,
   "Rarity_Ranking": 10
 },
 {
   "NFT": 1362,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Double Pirate Patch",
   "Gadgets_Rarity": 175.44,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 339.76,
   "Rarity_Ranking": 11
 },
 {
   "NFT": 18,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 339.54,
   "Rarity_Ranking": 12
 },
 {
   "NFT": 233,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 323.69,
   "Rarity_Ranking": 13
 },
 {
   "NFT": 1439,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Double Pirate Patch",
   "Gadgets_Rarity": 175.44,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 313.4,
   "Rarity_Ranking": 14
 },
 {
   "NFT": 96,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 313.23,
   "Rarity_Ranking": 15
 },
 {
   "NFT": 1197,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Double Pirate Patch",
   "Gadgets_Rarity": 175.44,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 312.16,
   "Rarity_Ranking": 16
 },
 {
   "NFT": 33,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "VR Headset",
   "Gadgets_Rarity": 156.25,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 311.47,
   "Rarity_Ranking": 17
 },
 {
   "NFT": 72,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 302.47,
   "Rarity_Ranking": 18
 },
 {
   "NFT": 1328,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor Gold",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 302.38,
   "Rarity_Ranking": 19
 },
 {
   "NFT": 633,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Double Pirate Patch",
   "Gadgets_Rarity": 175.44,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 302.21,
   "Rarity_Ranking": 20
 },
 {
   "NFT": 514,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Bushy",
   "Beard_Rarity": 140.85,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 301.87,
   "Rarity_Ranking": 21
 },
 {
   "NFT": 1234,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Hollow",
   "Eyes_Rarity": 136.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 292.78,
   "Rarity_Ranking": 22
 },
 {
   "NFT": 1138,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 286,
   "Rarity_Ranking": 23
 },
 {
   "NFT": 1105,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 279.12,
   "Rarity_Ranking": 24
 },
 {
   "NFT": 1013,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Double Pirate Patch",
   "Gadgets_Rarity": 175.44,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 278.74,
   "Rarity_Ranking": 25
 },
 {
   "NFT": 424,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 276.36,
   "Rarity_Ranking": 26
 },
 {
   "NFT": 1313,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 274.06,
   "Rarity_Ranking": 27
 },
 {
   "NFT": 626,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 270.42,
   "Rarity_Ranking": 28
 },
 {
   "NFT": 441,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 268.65,
   "Rarity_Ranking": 29
 },
 {
   "NFT": 812,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 268.59,
   "Rarity_Ranking": 30
 },
 {
   "NFT": 714,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Bushy",
   "Beard_Rarity": 140.85,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 266.26,
   "Rarity_Ranking": 31
 },
 {
   "NFT": 1390,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 264.74,
   "Rarity_Ranking": 32
 },
 {
   "NFT": 1205,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask Full Golden",
   "Gadgets_Rarity": 163.93,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 262.78,
   "Rarity_Ranking": 33
 },
 {
   "NFT": 1401,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Hollow",
   "Eyes_Rarity": 136.99,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 262.29,
   "Rarity_Ranking": 34
 },
 {
   "NFT": 1321,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Brown",
   "Beard_Rarity": 121.95,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 257.41,
   "Rarity_Ranking": 35
 },
 {
   "NFT": 1537,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Hollow",
   "Eyes_Rarity": 136.99,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 257.04,
   "Rarity_Ranking": 36
 },
 {
   "NFT": 843,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 256.89,
   "Rarity_Ranking": 37
 },
 {
   "NFT": 457,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Hollow",
   "Eyes_Rarity": 136.99,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 256.84,
   "Rarity_Ranking": 38
 },
 {
   "NFT": 341,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 253.9,
   "Rarity_Ranking": 39
 },
 {
   "NFT": 1302,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask Full Golden",
   "Gadgets_Rarity": 163.93,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 250.64,
   "Rarity_Ranking": 40
 },
 {
   "NFT": 769,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 250.5,
   "Rarity_Ranking": 41
 },
 {
   "NFT": 1085,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 249.54,
   "Rarity_Ranking": 42
 },
 {
   "NFT": 6,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 248.84,
   "Rarity_Ranking": 43
 },
 {
   "NFT": 493,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 247.93,
   "Rarity_Ranking": 44
 },
 {
   "NFT": 1172,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "VR Headset",
   "Gadgets_Rarity": 156.25,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 247.77,
   "Rarity_Ranking": 45
 },
 {
   "NFT": 412,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Bushy",
   "Beard_Rarity": 140.85,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 246.36,
   "Rarity_Ranking": 46
 },
 {
   "NFT": 372,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask Full Golden",
   "Gadgets_Rarity": 163.93,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 245.78,
   "Rarity_Ranking": 47
 },
 {
   "NFT": 809,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask Full Golden",
   "Gadgets_Rarity": 163.93,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 245.55,
   "Rarity_Ranking": 48
 },
 {
   "NFT": 719,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "VR Headset",
   "Gadgets_Rarity": 156.25,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 245.17,
   "Rarity_Ranking": 49
 },
 {
   "NFT": 1000,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 244.89,
   "Rarity_Ranking": 50
 },
 {
   "NFT": 395,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "VR Headset",
   "Gadgets_Rarity": 156.25,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 244.67,
   "Rarity_Ranking": 51
 },
 {
   "NFT": 244,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask Full Golden",
   "Gadgets_Rarity": 163.93,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 243.02,
   "Rarity_Ranking": 52
 },
 {
   "NFT": 40,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 242.42,
   "Rarity_Ranking": 53
 },
 {
   "NFT": 1208,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 242,
   "Rarity_Ranking": 54
 },
 {
   "NFT": 114,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 239.23,
   "Rarity_Ranking": 55
 },
 {
   "NFT": 729,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 238.9,
   "Rarity_Ranking": 56
 },
 {
   "NFT": 640,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 238.74,
   "Rarity_Ranking": 57
 },
 {
   "NFT": 110,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 238.19,
   "Rarity_Ranking": 58
 },
 {
   "NFT": 1449,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Bushy",
   "Beard_Rarity": 140.85,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 237.76,
   "Rarity_Ranking": 59
 },
 {
   "NFT": 243,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 236.83,
   "Rarity_Ranking": 60
 },
 {
   "NFT": 108,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask Full Golden",
   "Gadgets_Rarity": 163.93,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 235.84,
   "Rarity_Ranking": 61
 },
 {
   "NFT": 1276,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 235.09,
   "Rarity_Ranking": 62
 },
 {
   "NFT": 104,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "VR Headset",
   "Gadgets_Rarity": 156.25,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 234.96,
   "Rarity_Ranking": 63
 },
 {
   "NFT": 200,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 234.69,
   "Rarity_Ranking": 64
 },
 {
   "NFT": 1124,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 234.42,
   "Rarity_Ranking": 65
 },
 {
   "NFT": 462,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 232.68,
   "Rarity_Ranking": 66
 },
 {
   "NFT": 1170,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 231.96,
   "Rarity_Ranking": 67
 },
 {
   "NFT": 1059,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 231.89,
   "Rarity_Ranking": 68
 },
 {
   "NFT": 868,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 231.26,
   "Rarity_Ranking": 69
 },
 {
   "NFT": 225,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Hollow",
   "Eyes_Rarity": 136.99,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 231.17,
   "Rarity_Ranking": 70
 },
 {
   "NFT": 710,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 229.31,
   "Rarity_Ranking": 71
 },
 {
   "NFT": 578,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Hollow",
   "Eyes_Rarity": 136.99,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 228.09,
   "Rarity_Ranking": 72
 },
 {
   "NFT": 221,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 227.65,
   "Rarity_Ranking": 73
 },
 {
   "NFT": 1524,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 227.5,
   "Rarity_Ranking": 74
 },
 {
   "NFT": 1513,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 227.46,
   "Rarity_Ranking": 75
 },
 {
   "NFT": 1411,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Double Pirate Patch",
   "Gadgets_Rarity": 175.44,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 226.4,
   "Rarity_Ranking": 76
 },
 {
   "NFT": 1247,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 226.35,
   "Rarity_Ranking": 77
 },
 {
   "NFT": 939,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 226.08,
   "Rarity_Ranking": 78
 },
 {
   "NFT": 453,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 224.9,
   "Rarity_Ranking": 79
 },
 {
   "NFT": 590,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 224.81,
   "Rarity_Ranking": 80
 },
 {
   "NFT": 1504,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 224.29,
   "Rarity_Ranking": 81
 },
 {
   "NFT": 488,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 222.54,
   "Rarity_Ranking": 82
 },
 {
   "NFT": 1104,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 221.8,
   "Rarity_Ranking": 83
 },
 {
   "NFT": 930,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 221.77,
   "Rarity_Ranking": 84
 },
 {
   "NFT": 1398,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 221.39,
   "Rarity_Ranking": 85
 },
 {
   "NFT": 666,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 221.01,
   "Rarity_Ranking": 86
 },
 {
   "NFT": 24,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 220.82,
   "Rarity_Ranking": 87
 },
 {
   "NFT": 715,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Bushy",
   "Beard_Rarity": 140.85,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 220.1,
   "Rarity_Ranking": 88
 },
 {
   "NFT": 773,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 219.95,
   "Rarity_Ranking": 89
 },
 {
   "NFT": 535,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 219.79,
   "Rarity_Ranking": 90
 },
 {
   "NFT": 231,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 218.65,
   "Rarity_Ranking": 91
 },
 {
   "NFT": 807,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 218.62,
   "Rarity_Ranking": 92
 },
 {
   "NFT": 1143,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 218.56,
   "Rarity_Ranking": 93
 },
 {
   "NFT": 433,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "VR Headset",
   "Gadgets_Rarity": 156.25,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 218.38,
   "Rarity_Ranking": 94
 },
 {
   "NFT": 258,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 218.25,
   "Rarity_Ranking": 95
 },
 {
   "NFT": 376,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Bushy",
   "Beard_Rarity": 140.85,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 217.62,
   "Rarity_Ranking": 96
 },
 {
   "NFT": 1133,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Brown",
   "Beard_Rarity": 121.95,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 217.36,
   "Rarity_Ranking": 97
 },
 {
   "NFT": 45,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Hollow",
   "Eyes_Rarity": 136.99,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 217.14,
   "Rarity_Ranking": 98
 },
 {
   "NFT": 100,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 216.34,
   "Rarity_Ranking": 99
 },
 {
   "NFT": 851,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 214.64,
   "Rarity_Ranking": 100
 },
 {
   "NFT": 445,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 214.26,
   "Rarity_Ranking": 101
 },
 {
   "NFT": 481,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "VR Headset",
   "Gadgets_Rarity": 156.25,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 213.25,
   "Rarity_Ranking": 102
 },
 {
   "NFT": 497,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 212.72,
   "Rarity_Ranking": 103
 },
 {
   "NFT": 542,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 211.49,
   "Rarity_Ranking": 104
 },
 {
   "NFT": 1369,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 211.29,
   "Rarity_Ranking": 105
 },
 {
   "NFT": 1249,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 211.18,
   "Rarity_Ranking": 106
 },
 {
   "NFT": 1373,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Bushy",
   "Beard_Rarity": 140.85,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 210.78,
   "Rarity_Ranking": 107
 },
 {
   "NFT": 377,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Bushy",
   "Beard_Rarity": 140.85,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 210.5,
   "Rarity_Ranking": 108
 },
 {
   "NFT": 19,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 210.48,
   "Rarity_Ranking": 109
 },
 {
   "NFT": 1559,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 210.22,
   "Rarity_Ranking": 110
 },
 {
   "NFT": 1529,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 210.13,
   "Rarity_Ranking": 111
 },
 {
   "NFT": 1472,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 209.82,
   "Rarity_Ranking": 112
 },
 {
   "NFT": 804,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 209.79,
   "Rarity_Ranking": 113
 },
 {
   "NFT": 1196,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 209.43,
   "Rarity_Ranking": 114
 },
 {
   "NFT": 498,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Bushy",
   "Beard_Rarity": 140.85,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 207.89,
   "Rarity_Ranking": 115
 },
 {
   "NFT": 1324,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 207.87,
   "Rarity_Ranking": 116
 },
 {
   "NFT": 1187,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 207.76,
   "Rarity_Ranking": 117
 },
 {
   "NFT": 1343,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 207.51,
   "Rarity_Ranking": 118
 },
 {
   "NFT": 609,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 207.06,
   "Rarity_Ranking": 119
 },
 {
   "NFT": 1284,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 207.04,
   "Rarity_Ranking": 120
 },
 {
   "NFT": 1555,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 206.92,
   "Rarity_Ranking": 121
 },
 {
   "NFT": 1375,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Mask Full Golden",
   "Gadgets_Rarity": 163.93,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 206.73,
   "Rarity_Ranking": 122
 },
 {
   "NFT": 15,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 205.27,
   "Rarity_Ranking": 123
 },
 {
   "NFT": 311,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Hollow",
   "Eyes_Rarity": 136.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 204.54,
   "Rarity_Ranking": 124
 },
 {
   "NFT": 220,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Bushy",
   "Beard_Rarity": 140.85,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 204.5,
   "Rarity_Ranking": 125
 },
 {
   "NFT": 1270,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 203.49,
   "Rarity_Ranking": 126
 },
 {
   "NFT": 273,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 202.64,
   "Rarity_Ranking": 127
 },
 {
   "NFT": 768,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 201.95,
   "Rarity_Ranking": 128
 },
 {
   "NFT": 268,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 200.53,
   "Rarity_Ranking": 129
 },
 {
   "NFT": 1078,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "VR Headset",
   "Gadgets_Rarity": 156.25,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 200.09,
   "Rarity_Ranking": 130
 },
 {
   "NFT": 780,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 199.97,
   "Rarity_Ranking": 131
 },
 {
   "NFT": 503,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 199.32,
   "Rarity_Ranking": 132
 },
 {
   "NFT": 524,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 199.17,
   "Rarity_Ranking": 133
 },
 {
   "NFT": 798,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 198.33,
   "Rarity_Ranking": 134
 },
 {
   "NFT": 419,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 198.17,
   "Rarity_Ranking": 135
 },
 {
   "NFT": 195,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 198.05,
   "Rarity_Ranking": 136
 },
 {
   "NFT": 1219,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 197.42,
   "Rarity_Ranking": 137
 },
 {
   "NFT": 1083,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 196.73,
   "Rarity_Ranking": 138
 },
 {
   "NFT": 544,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 196.37,
   "Rarity_Ranking": 139
 },
 {
   "NFT": 1508,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 196.21,
   "Rarity_Ranking": 140
 },
 {
   "NFT": 1310,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 196.15,
   "Rarity_Ranking": 141
 },
 {
   "NFT": 932,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 196.12,
   "Rarity_Ranking": 142
 },
 {
   "NFT": 43,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 196.09,
   "Rarity_Ranking": 143
 },
 {
   "NFT": 1412,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 195.9,
   "Rarity_Ranking": 144
 },
 {
   "NFT": 224,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "VR Headset",
   "Gadgets_Rarity": 156.25,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 195.53,
   "Rarity_Ranking": 145
 },
 {
   "NFT": 79,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 195.46,
   "Rarity_Ranking": 146
 },
 {
   "NFT": 743,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 195.4,
   "Rarity_Ranking": 147
 },
 {
   "NFT": 1450,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "VR Headset",
   "Gadgets_Rarity": 156.25,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 195.02,
   "Rarity_Ranking": 148
 },
 {
   "NFT": 816,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 194.07,
   "Rarity_Ranking": 149
 },
 {
   "NFT": 185,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 193.9,
   "Rarity_Ranking": 150
 },
 {
   "NFT": 1017,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 193.88,
   "Rarity_Ranking": 151
 },
 {
   "NFT": 636,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Mustache Brown",
   "Beard_Rarity": 121.95,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 193.75,
   "Rarity_Ranking": 152
 },
 {
   "NFT": 398,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 193.58,
   "Rarity_Ranking": 153
 },
 {
   "NFT": 909,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 193.3,
   "Rarity_Ranking": 154
 },
 {
   "NFT": 1066,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 193.21,
   "Rarity_Ranking": 155
 },
 {
   "NFT": 1415,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 192.78,
   "Rarity_Ranking": 156
 },
 {
   "NFT": 870,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 192.42,
   "Rarity_Ranking": 157
 },
 {
   "NFT": 151,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Mustache Brown",
   "Beard_Rarity": 121.95,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 191.33,
   "Rarity_Ranking": 158
 },
 {
   "NFT": 190,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 191.12,
   "Rarity_Ranking": 159
 },
 {
   "NFT": 515,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 190.83,
   "Rarity_Ranking": 160
 },
 {
   "NFT": 1075,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 190.26,
   "Rarity_Ranking": 161
 },
 {
   "NFT": 76,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 190.15,
   "Rarity_Ranking": 162
 },
 {
   "NFT": 375,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 189.92,
   "Rarity_Ranking": 163
 },
 {
   "NFT": 718,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 189.82,
   "Rarity_Ranking": 164
 },
 {
   "NFT": 153,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 189.54,
   "Rarity_Ranking": 165
 },
 {
   "NFT": 1416,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 189.41,
   "Rarity_Ranking": 166
 },
 {
   "NFT": 393,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 188.63,
   "Rarity_Ranking": 167
 },
 {
   "NFT": 209,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 187.34,
   "Rarity_Ranking": 168
 },
 {
   "NFT": 70,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Hollow",
   "Eyes_Rarity": 136.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 187.25,
   "Rarity_Ranking": 169
 },
 {
   "NFT": 642,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 187.09,
   "Rarity_Ranking": 170
 },
 {
   "NFT": 1130,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 186.98,
   "Rarity_Ranking": 171
 },
 {
   "NFT": 945,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 186.65,
   "Rarity_Ranking": 172
 },
 {
   "NFT": 869,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 186.27,
   "Rarity_Ranking": 173
 },
 {
   "NFT": 310,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 186,
   "Rarity_Ranking": 174
 },
 {
   "NFT": 1501,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 185.57,
   "Rarity_Ranking": 175
 },
 {
   "NFT": 1374,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 185.41,
   "Rarity_Ranking": 176
 },
 {
   "NFT": 124,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Mustache Brown",
   "Beard_Rarity": 121.95,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 185.15,
   "Rarity_Ranking": 177
 },
 {
   "NFT": 1409,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Visor Gold",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 184.37,
   "Rarity_Ranking": 178
 },
 {
   "NFT": 916,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 184.26,
   "Rarity_Ranking": 179
 },
 {
   "NFT": 139,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 183.96,
   "Rarity_Ranking": 180
 },
 {
   "NFT": 3,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 183.84,
   "Rarity_Ranking": 181
 },
 {
   "NFT": 738,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 182.88,
   "Rarity_Ranking": 182
 },
 {
   "NFT": 659,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 181.8,
   "Rarity_Ranking": 183
 },
 {
   "NFT": 184,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 181.79,
   "Rarity_Ranking": 184
 },
 {
   "NFT": 789,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 181.69,
   "Rarity_Ranking": 185
 },
 {
   "NFT": 723,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 181.14,
   "Rarity_Ranking": 186
 },
 {
   "NFT": 1217,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 180.99,
   "Rarity_Ranking": 187
 },
 {
   "NFT": 675,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 180.66,
   "Rarity_Ranking": 188
 },
 {
   "NFT": 475,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Mechanical",
   "Eyes_Rarity": 125,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 179.5,
   "Rarity_Ranking": 189
 },
 {
   "NFT": 44,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 178.67,
   "Rarity_Ranking": 190
 },
 {
   "NFT": 439,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 178.51,
   "Rarity_Ranking": 191
 },
 {
   "NFT": 234,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 178.3,
   "Rarity_Ranking": 192
 },
 {
   "NFT": 229,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 178.19,
   "Rarity_Ranking": 193
 },
 {
   "NFT": 1,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 177.92,
   "Rarity_Ranking": 194
 },
 {
   "NFT": 1551,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 177.92,
   "Rarity_Ranking": 195
 },
 {
   "NFT": 904,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 177.62,
   "Rarity_Ranking": 196
 },
 {
   "NFT": 227,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 177.48,
   "Rarity_Ranking": 197
 },
 {
   "NFT": 1166,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Brown",
   "Beard_Rarity": 121.95,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 176.98,
   "Rarity_Ranking": 198
 },
 {
   "NFT": 1554,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 176.81,
   "Rarity_Ranking": 199
 },
 {
   "NFT": 451,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 176.38,
   "Rarity_Ranking": 200
 },
 {
   "NFT": 713,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 175.59,
   "Rarity_Ranking": 201
 },
 {
   "NFT": 684,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 175.53,
   "Rarity_Ranking": 202
 },
 {
   "NFT": 37,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 175.44,
   "Rarity_Ranking": 203
 },
 {
   "NFT": 523,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 175.33,
   "Rarity_Ranking": 204
 },
 {
   "NFT": 1157,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 174.99,
   "Rarity_Ranking": 205
 },
 {
   "NFT": 1386,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 174.94,
   "Rarity_Ranking": 206
 },
 {
   "NFT": 722,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 174.79,
   "Rarity_Ranking": 207
 },
 {
   "NFT": 1546,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 174.73,
   "Rarity_Ranking": 208
 },
 {
   "NFT": 378,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 174.51,
   "Rarity_Ranking": 209
 },
 {
   "NFT": 660,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 174.49,
   "Rarity_Ranking": 210
 },
 {
   "NFT": 1505,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 174.07,
   "Rarity_Ranking": 211
 },
 {
   "NFT": 872,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 174.03,
   "Rarity_Ranking": 212
 },
 {
   "NFT": 648,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 173.82,
   "Rarity_Ranking": 213
 },
 {
   "NFT": 326,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 173.13,
   "Rarity_Ranking": 214
 },
 {
   "NFT": 1458,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 173.08,
   "Rarity_Ranking": 215
 },
 {
   "NFT": 1123,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 173.06,
   "Rarity_Ranking": 216
 },
 {
   "NFT": 386,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 172.83,
   "Rarity_Ranking": 217
 },
 {
   "NFT": 884,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 172.31,
   "Rarity_Ranking": 218
 },
 {
   "NFT": 1072,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 172.04,
   "Rarity_Ranking": 219
 },
 {
   "NFT": 1426,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 171.86,
   "Rarity_Ranking": 220
 },
 {
   "NFT": 1379,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 171.74,
   "Rarity_Ranking": 221
 },
 {
   "NFT": 727,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 171.35,
   "Rarity_Ranking": 222
 },
 {
   "NFT": 888,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 170.8,
   "Rarity_Ranking": 223
 },
 {
   "NFT": 17,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 170.74,
   "Rarity_Ranking": 224
 },
 {
   "NFT": 667,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 170.59,
   "Rarity_Ranking": 225
 },
 {
   "NFT": 402,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 170.58,
   "Rarity_Ranking": 226
 },
 {
   "NFT": 927,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 170.42,
   "Rarity_Ranking": 227
 },
 {
   "NFT": 407,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 170.3,
   "Rarity_Ranking": 228
 },
 {
   "NFT": 84,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Bowie",
   "Eyes_Rarity": 128.21,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 169.97,
   "Rarity_Ranking": 229
 },
 {
   "NFT": 993,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 169.87,
   "Rarity_Ranking": 230
 },
 {
   "NFT": 749,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 169.18,
   "Rarity_Ranking": 231
 },
 {
   "NFT": 952,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 169.16,
   "Rarity_Ranking": 232
 },
 {
   "NFT": 289,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 168.98,
   "Rarity_Ranking": 233
 },
 {
   "NFT": 889,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 168.83,
   "Rarity_Ranking": 234
 },
 {
   "NFT": 1518,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 168.62,
   "Rarity_Ranking": 235
 },
 {
   "NFT": 1255,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor Gold",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 168.29,
   "Rarity_Ranking": 236
 },
 {
   "NFT": 1380,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 167.98,
   "Rarity_Ranking": 237
 },
 {
   "NFT": 1383,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 167.91,
   "Rarity_Ranking": 238
 },
 {
   "NFT": 64,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 167.84,
   "Rarity_Ranking": 239
 },
 {
   "NFT": 1044,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 167.07,
   "Rarity_Ranking": 240
 },
 {
   "NFT": 246,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 166.9,
   "Rarity_Ranking": 241
 },
 {
   "NFT": 1563,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 166.83,
   "Rarity_Ranking": 242
 },
 {
   "NFT": 1319,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 166.82,
   "Rarity_Ranking": 243
 },
 {
   "NFT": 1342,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 166.72,
   "Rarity_Ranking": 244
 },
 {
   "NFT": 910,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 166.5,
   "Rarity_Ranking": 245
 },
 {
   "NFT": 805,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 166.46,
   "Rarity_Ranking": 246
 },
 {
   "NFT": 146,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Mustache Green",
   "Beard_Rarity": 104.17,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 166.12,
   "Rarity_Ranking": 247
 },
 {
   "NFT": 826,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 165.91,
   "Rarity_Ranking": 248
 },
 {
   "NFT": 863,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 165.88,
   "Rarity_Ranking": 249
 },
 {
   "NFT": 1305,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 165.86,
   "Rarity_Ranking": 250
 },
 {
   "NFT": 709,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 165.62,
   "Rarity_Ranking": 251
 },
 {
   "NFT": 405,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 165.07,
   "Rarity_Ranking": 252
 },
 {
   "NFT": 51,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 164.99,
   "Rarity_Ranking": 253
 },
 {
   "NFT": 176,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 164.79,
   "Rarity_Ranking": 254
 },
 {
   "NFT": 763,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 164.59,
   "Rarity_Ranking": 255
 },
 {
   "NFT": 452,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Blue",
   "Beard_Rarity": 129.87,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 164.24,
   "Rarity_Ranking": 256
 },
 {
   "NFT": 168,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 164.02,
   "Rarity_Ranking": 257
 },
 {
   "NFT": 157,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 163.46,
   "Rarity_Ranking": 258
 },
 {
   "NFT": 81,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 162.47,
   "Rarity_Ranking": 259
 },
 {
   "NFT": 1014,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 162.11,
   "Rarity_Ranking": 260
 },
 {
   "NFT": 271,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 161.96,
   "Rarity_Ranking": 261
 },
 {
   "NFT": 797,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 161.9,
   "Rarity_Ranking": 262
 },
 {
   "NFT": 943,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Single",
   "Eyes_Rarity": 116.28,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 161.82,
   "Rarity_Ranking": 263
 },
 {
   "NFT": 467,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 161.56,
   "Rarity_Ranking": 264
 },
 {
   "NFT": 345,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 161.52,
   "Rarity_Ranking": 265
 },
 {
   "NFT": 891,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 161.05,
   "Rarity_Ranking": 266
 },
 {
   "NFT": 956,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 160.96,
   "Rarity_Ranking": 267
 },
 {
   "NFT": 1428,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 160.93,
   "Rarity_Ranking": 268
 },
 {
   "NFT": 1268,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 160.79,
   "Rarity_Ranking": 269
 },
 {
   "NFT": 275,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 160.42,
   "Rarity_Ranking": 270
 },
 {
   "NFT": 1318,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 160.1,
   "Rarity_Ranking": 271
 },
 {
   "NFT": 500,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 160.05,
   "Rarity_Ranking": 272
 },
 {
   "NFT": 1538,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 160,
   "Rarity_Ranking": 273
 },
 {
   "NFT": 1541,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 159.32,
   "Rarity_Ranking": 274
 },
 {
   "NFT": 550,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 159.23,
   "Rarity_Ranking": 275
 },
 {
   "NFT": 319,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 159.07,
   "Rarity_Ranking": 276
 },
 {
   "NFT": 685,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 159.05,
   "Rarity_Ranking": 277
 },
 {
   "NFT": 446,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 158.98,
   "Rarity_Ranking": 278
 },
 {
   "NFT": 485,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 158.9,
   "Rarity_Ranking": 279
 },
 {
   "NFT": 1334,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 158.8,
   "Rarity_Ranking": 280
 },
 {
   "NFT": 770,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 158.6,
   "Rarity_Ranking": 281
 },
 {
   "NFT": 323,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 158.21,
   "Rarity_Ranking": 282
 },
 {
   "NFT": 1326,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Brown",
   "Beard_Rarity": 121.95,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 157.95,
   "Rarity_Ranking": 283
 },
 {
   "NFT": 1358,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 157.6,
   "Rarity_Ranking": 284
 },
 {
   "NFT": 705,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 157.37,
   "Rarity_Ranking": 285
 },
 {
   "NFT": 1522,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 156.86,
   "Rarity_Ranking": 286
 },
 {
   "NFT": 58,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 156.54,
   "Rarity_Ranking": 287
 },
 {
   "NFT": 505,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 156.45,
   "Rarity_Ranking": 288
 },
 {
   "NFT": 182,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 156.4,
   "Rarity_Ranking": 289
 },
 {
   "NFT": 1447,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 156.31,
   "Rarity_Ranking": 290
 },
 {
   "NFT": 756,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 156.17,
   "Rarity_Ranking": 291
 },
 {
   "NFT": 1346,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 156.16,
   "Rarity_Ranking": 292
 },
 {
   "NFT": 824,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 156.11,
   "Rarity_Ranking": 293
 },
 {
   "NFT": 563,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 156,
   "Rarity_Ranking": 294
 },
 {
   "NFT": 116,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 155.9,
   "Rarity_Ranking": 295
 },
 {
   "NFT": 736,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 155.67,
   "Rarity_Ranking": 296
 },
 {
   "NFT": 336,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 155.49,
   "Rarity_Ranking": 297
 },
 {
   "NFT": 1332,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 155.36,
   "Rarity_Ranking": 298
 },
 {
   "NFT": 239,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 155.2,
   "Rarity_Ranking": 299
 },
 {
   "NFT": 85,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 154.41,
   "Rarity_Ranking": 300
 },
 {
   "NFT": 733,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 154.37,
   "Rarity_Ranking": 301
 },
 {
   "NFT": 1098,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 154.24,
   "Rarity_Ranking": 302
 },
 {
   "NFT": 264,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 153.82,
   "Rarity_Ranking": 303
 },
 {
   "NFT": 706,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 153.77,
   "Rarity_Ranking": 304
 },
 {
   "NFT": 966,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 153.5,
   "Rarity_Ranking": 305
 },
 {
   "NFT": 762,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 153.38,
   "Rarity_Ranking": 306
 },
 {
   "NFT": 928,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 152.93,
   "Rarity_Ranking": 307
 },
 {
   "NFT": 1192,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 152.66,
   "Rarity_Ranking": 308
 },
 {
   "NFT": 348,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 152.61,
   "Rarity_Ranking": 309
 },
 {
   "NFT": 504,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 152.34,
   "Rarity_Ranking": 310
 },
 {
   "NFT": 750,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 152.24,
   "Rarity_Ranking": 311
 },
 {
   "NFT": 759,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 152.15,
   "Rarity_Ranking": 312
 },
 {
   "NFT": 210,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 152.12,
   "Rarity_Ranking": 313
 },
 {
   "NFT": 882,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 151.24,
   "Rarity_Ranking": 314
 },
 {
   "NFT": 632,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 151.16,
   "Rarity_Ranking": 315
 },
 {
   "NFT": 1163,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 151.04,
   "Rarity_Ranking": 316
 },
 {
   "NFT": 7,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 150.56,
   "Rarity_Ranking": 317
 },
 {
   "NFT": 1322,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 150.53,
   "Rarity_Ranking": 318
 },
 {
   "NFT": 1499,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 150.48,
   "Rarity_Ranking": 319
 },
 {
   "NFT": 501,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 150.45,
   "Rarity_Ranking": 320
 },
 {
   "NFT": 1454,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor Gold",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 150.45,
   "Rarity_Ranking": 321
 },
 {
   "NFT": 252,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 150.37,
   "Rarity_Ranking": 322
 },
 {
   "NFT": 1252,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 150.22,
   "Rarity_Ranking": 323
 },
 {
   "NFT": 119,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Brown",
   "Beard_Rarity": 121.95,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 149.92,
   "Rarity_Ranking": 324
 },
 {
   "NFT": 91,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 149.87,
   "Rarity_Ranking": 325
 },
 {
   "NFT": 1457,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 149.66,
   "Rarity_Ranking": 326
 },
 {
   "NFT": 1494,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 149.36,
   "Rarity_Ranking": 327
 },
 {
   "NFT": 1202,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 149.16,
   "Rarity_Ranking": 328
 },
 {
   "NFT": 1337,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 149.1,
   "Rarity_Ranking": 329
 },
 {
   "NFT": 491,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 149.05,
   "Rarity_Ranking": 330
 },
 {
   "NFT": 89,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 149.03,
   "Rarity_Ranking": 331
 },
 {
   "NFT": 374,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 148.69,
   "Rarity_Ranking": 332
 },
 {
   "NFT": 841,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 148.62,
   "Rarity_Ranking": 333
 },
 {
   "NFT": 734,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 148.44,
   "Rarity_Ranking": 334
 },
 {
   "NFT": 255,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 148.28,
   "Rarity_Ranking": 335
 },
 {
   "NFT": 746,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 148.18,
   "Rarity_Ranking": 336
 },
 {
   "NFT": 676,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 148.14,
   "Rarity_Ranking": 337
 },
 {
   "NFT": 1074,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 148.14,
   "Rarity_Ranking": 338
 },
 {
   "NFT": 1441,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 148.05,
   "Rarity_Ranking": 339
 },
 {
   "NFT": 219,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 148.02,
   "Rarity_Ranking": 340
 },
 {
   "NFT": 766,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 148.02,
   "Rarity_Ranking": 341
 },
 {
   "NFT": 1263,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 147.71,
   "Rarity_Ranking": 342
 },
 {
   "NFT": 1027,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 147.45,
   "Rarity_Ranking": 343
 },
 {
   "NFT": 981,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 147.33,
   "Rarity_Ranking": 344
 },
 {
   "NFT": 1550,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 147.14,
   "Rarity_Ranking": 345
 },
 {
   "NFT": 881,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 146.97,
   "Rarity_Ranking": 346
 },
 {
   "NFT": 354,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 146.94,
   "Rarity_Ranking": 347
 },
 {
   "NFT": 649,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 146.88,
   "Rarity_Ranking": 348
 },
 {
   "NFT": 99,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 146.75,
   "Rarity_Ranking": 349
 },
 {
   "NFT": 897,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 146.62,
   "Rarity_Ranking": 350
 },
 {
   "NFT": 631,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 146.56,
   "Rarity_Ranking": 351
 },
 {
   "NFT": 616,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 146.23,
   "Rarity_Ranking": 352
 },
 {
   "NFT": 829,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 146.17,
   "Rarity_Ranking": 353
 },
 {
   "NFT": 582,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 146.16,
   "Rarity_Ranking": 354
 },
 {
   "NFT": 1269,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 145.78,
   "Rarity_Ranking": 355
 },
 {
   "NFT": 1216,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 145.68,
   "Rarity_Ranking": 356
 },
 {
   "NFT": 925,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Vampire Mouth",
   "Mouth_Rarity": 114.94,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 145.6,
   "Rarity_Ranking": 357
 },
 {
   "NFT": 141,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 145.37,
   "Rarity_Ranking": 358
 },
 {
   "NFT": 1485,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 145.12,
   "Rarity_Ranking": 359
 },
 {
   "NFT": 707,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 144.98,
   "Rarity_Ranking": 360
 },
 {
   "NFT": 456,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 144.67,
   "Rarity_Ranking": 361
 },
 {
   "NFT": 820,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 144.64,
   "Rarity_Ranking": 362
 },
 {
   "NFT": 57,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 144.58,
   "Rarity_Ranking": 363
 },
 {
   "NFT": 793,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "General",
   "Rank_Rarity": 93.46,
   "Rarity_Score": 144.5,
   "Rarity_Ranking": 364
 },
 {
   "NFT": 36,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 144.37,
   "Rarity_Ranking": 365
 },
 {
   "NFT": 628,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 144.17,
   "Rarity_Ranking": 366
 },
 {
   "NFT": 1431,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 144.14,
   "Rarity_Ranking": 367
 },
 {
   "NFT": 572,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 143.98,
   "Rarity_Ranking": 368
 },
 {
   "NFT": 16,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 143.92,
   "Rarity_Ranking": 369
 },
 {
   "NFT": 204,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 143.78,
   "Rarity_Ranking": 370
 },
 {
   "NFT": 926,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 143.71,
   "Rarity_Ranking": 371
 },
 {
   "NFT": 1498,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 143.71,
   "Rarity_Ranking": 372
 },
 {
   "NFT": 1238,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 143.68,
   "Rarity_Ranking": 373
 },
 {
   "NFT": 731,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Red",
   "Beard_Rarity": 119.05,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 143.43,
   "Rarity_Ranking": 374
 },
 {
   "NFT": 674,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 143.25,
   "Rarity_Ranking": 375
 },
 {
   "NFT": 1057,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 142.25,
   "Rarity_Ranking": 376
 },
 {
   "NFT": 978,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 141.94,
   "Rarity_Ranking": 377
 },
 {
   "NFT": 300,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 141.68,
   "Rarity_Ranking": 378
 },
 {
   "NFT": 531,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 141.61,
   "Rarity_Ranking": 379
 },
 {
   "NFT": 296,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Mustache Grey",
   "Beard_Rarity": 113.64,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 141.49,
   "Rarity_Ranking": 380
 },
 {
   "NFT": 627,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 141.44,
   "Rarity_Ranking": 381
 },
 {
   "NFT": 335,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 141.26,
   "Rarity_Ranking": 382
 },
 {
   "NFT": 60,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 140.92,
   "Rarity_Ranking": 383
 },
 {
   "NFT": 694,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "VP Tattoo",
   "Marks_Rarity": 106.38,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 140.73,
   "Rarity_Ranking": 384
 },
 {
   "NFT": 507,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 140.62,
   "Rarity_Ranking": 385
 },
 {
   "NFT": 774,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 140.35,
   "Rarity_Ranking": 386
 },
 {
   "NFT": 1533,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 140.11,
   "Rarity_Ranking": 387
 },
 {
   "NFT": 321,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 140.09,
   "Rarity_Ranking": 388
 },
 {
   "NFT": 1393,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 140,
   "Rarity_Ranking": 389
 },
 {
   "NFT": 1317,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 139.82,
   "Rarity_Ranking": 390
 },
 {
   "NFT": 566,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 139.69,
   "Rarity_Ranking": 391
 },
 {
   "NFT": 330,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 139.42,
   "Rarity_Ranking": 392
 },
 {
   "NFT": 470,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 139.33,
   "Rarity_Ranking": 393
 },
 {
   "NFT": 1552,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 139.28,
   "Rarity_Ranking": 394
 },
 {
   "NFT": 1325,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 138.84,
   "Rarity_Ranking": 395
 },
 {
   "NFT": 1484,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 138.84,
   "Rarity_Ranking": 396
 },
 {
   "NFT": 595,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 138.3,
   "Rarity_Ranking": 397
 },
 {
   "NFT": 257,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 138.01,
   "Rarity_Ranking": 398
 },
 {
   "NFT": 900,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 137.88,
   "Rarity_Ranking": 399
 },
 {
   "NFT": 867,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 137.86,
   "Rarity_Ranking": 400
 },
 {
   "NFT": 1561,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 137.67,
   "Rarity_Ranking": 401
 },
 {
   "NFT": 1566,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 137.64,
   "Rarity_Ranking": 402
 },
 {
   "NFT": 760,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 137.48,
   "Rarity_Ranking": 403
 },
 {
   "NFT": 162,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 137.46,
   "Rarity_Ranking": 404
 },
 {
   "NFT": 678,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 137.42,
   "Rarity_Ranking": 405
 },
 {
   "NFT": 1201,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 137.42,
   "Rarity_Ranking": 406
 },
 {
   "NFT": 1282,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 137.37,
   "Rarity_Ranking": 407
 },
 {
   "NFT": 695,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 137.36,
   "Rarity_Ranking": 408
 },
 {
   "NFT": 859,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 137.05,
   "Rarity_Ranking": 409
 },
 {
   "NFT": 355,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 136.92,
   "Rarity_Ranking": 410
 },
 {
   "NFT": 83,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 136.8,
   "Rarity_Ranking": 411
 },
 {
   "NFT": 1433,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 136.77,
   "Rarity_Ranking": 412
 },
 {
   "NFT": 908,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 136.73,
   "Rarity_Ranking": 413
 },
 {
   "NFT": 606,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 136.57,
   "Rarity_Ranking": 414
 },
 {
   "NFT": 306,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 136.2,
   "Rarity_Ranking": 415
 },
 {
   "NFT": 388,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.89,
   "Rarity_Ranking": 416
 },
 {
   "NFT": 635,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.72,
   "Rarity_Ranking": 417
 },
 {
   "NFT": 792,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.66,
   "Rarity_Ranking": 418
 },
 {
   "NFT": 1451,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.64,
   "Rarity_Ranking": 419
 },
 {
   "NFT": 1491,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.62,
   "Rarity_Ranking": 420
 },
 {
   "NFT": 380,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.59,
   "Rarity_Ranking": 421
 },
 {
   "NFT": 68,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 135.57,
   "Rarity_Ranking": 422
 },
 {
   "NFT": 860,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 135.51,
   "Rarity_Ranking": 423
 },
 {
   "NFT": 1116,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.51,
   "Rarity_Ranking": 424
 },
 {
   "NFT": 895,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 135.33,
   "Rarity_Ranking": 425
 },
 {
   "NFT": 1446,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.24,
   "Rarity_Ranking": 426
 },
 {
   "NFT": 906,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.23,
   "Rarity_Ranking": 427
 },
 {
   "NFT": 392,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.19,
   "Rarity_Ranking": 428
 },
 {
   "NFT": 1023,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.16,
   "Rarity_Ranking": 429
 },
 {
   "NFT": 970,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 135.08,
   "Rarity_Ranking": 430
 },
 {
   "NFT": 1408,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 134.91,
   "Rarity_Ranking": 431
 },
 {
   "NFT": 806,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 134.86,
   "Rarity_Ranking": 432
 },
 {
   "NFT": 650,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 134.54,
   "Rarity_Ranking": 433
 },
 {
   "NFT": 394,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 134.32,
   "Rarity_Ranking": 434
 },
 {
   "NFT": 899,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 134.11,
   "Rarity_Ranking": 435
 },
 {
   "NFT": 198,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 134.09,
   "Rarity_Ranking": 436
 },
 {
   "NFT": 1073,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 133.98,
   "Rarity_Ranking": 437
 },
 {
   "NFT": 692,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.96,
   "Rarity_Ranking": 438
 },
 {
   "NFT": 1222,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.87,
   "Rarity_Ranking": 439
 },
 {
   "NFT": 1495,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.82,
   "Rarity_Ranking": 440
 },
 {
   "NFT": 1479,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.74,
   "Rarity_Ranking": 441
 },
 {
   "NFT": 1391,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor Gold",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.68,
   "Rarity_Ranking": 442
 },
 {
   "NFT": 1265,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.61,
   "Rarity_Ranking": 443
 },
 {
   "NFT": 1101,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.57,
   "Rarity_Ranking": 444
 },
 {
   "NFT": 958,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.52,
   "Rarity_Ranking": 445
 },
 {
   "NFT": 346,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.3,
   "Rarity_Ranking": 446
 },
 {
   "NFT": 1020,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.26,
   "Rarity_Ranking": 447
 },
 {
   "NFT": 1164,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 133.08,
   "Rarity_Ranking": 448
 },
 {
   "NFT": 263,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 132.96,
   "Rarity_Ranking": 449
 },
 {
   "NFT": 1121,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 132.75,
   "Rarity_Ranking": 450
 },
 {
   "NFT": 596,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 132.49,
   "Rarity_Ranking": 451
 },
 {
   "NFT": 735,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 132.49,
   "Rarity_Ranking": 452
 },
 {
   "NFT": 520,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 132.32,
   "Rarity_Ranking": 453
 },
 {
   "NFT": 561,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 132.16,
   "Rarity_Ranking": 454
 },
 {
   "NFT": 577,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 131.92,
   "Rarity_Ranking": 455
 },
 {
   "NFT": 308,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 131.82,
   "Rarity_Ranking": 456
 },
 {
   "NFT": 1473,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 131.49,
   "Rarity_Ranking": 457
 },
 {
   "NFT": 158,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 131.4,
   "Rarity_Ranking": 458
 },
 {
   "NFT": 591,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 131.36,
   "Rarity_Ranking": 459
 },
 {
   "NFT": 276,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 131.29,
   "Rarity_Ranking": 460
 },
 {
   "NFT": 615,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 131.29,
   "Rarity_Ranking": 461
 },
 {
   "NFT": 558,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 131.14,
   "Rarity_Ranking": 462
 },
 {
   "NFT": 1453,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 131.08,
   "Rarity_Ranking": 463
 },
 {
   "NFT": 1560,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 131.04,
   "Rarity_Ranking": 464
 },
 {
   "NFT": 751,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 130.82,
   "Rarity_Ranking": 465
 },
 {
   "NFT": 717,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 130.74,
   "Rarity_Ranking": 466
 },
 {
   "NFT": 299,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 130.63,
   "Rarity_Ranking": 467
 },
 {
   "NFT": 625,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 130.39,
   "Rarity_Ranking": 468
 },
 {
   "NFT": 109,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 130.15,
   "Rarity_Ranking": 469
 },
 {
   "NFT": 1392,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 130.05,
   "Rarity_Ranking": 470
 },
 {
   "NFT": 359,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 129.99,
   "Rarity_Ranking": 471
 },
 {
   "NFT": 534,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 129.88,
   "Rarity_Ranking": 472
 },
 {
   "NFT": 194,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 129.64,
   "Rarity_Ranking": 473
 },
 {
   "NFT": 1348,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 129.61,
   "Rarity_Ranking": 474
 },
 {
   "NFT": 1161,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 129.41,
   "Rarity_Ranking": 475
 },
 {
   "NFT": 1227,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 129.35,
   "Rarity_Ranking": 476
 },
 {
   "NFT": 1182,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 129.28,
   "Rarity_Ranking": 477
 },
 {
   "NFT": 796,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 129.2,
   "Rarity_Ranking": 478
 },
 {
   "NFT": 1162,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.94,
   "Rarity_Ranking": 479
 },
 {
   "NFT": 1327,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.92,
   "Rarity_Ranking": 480
 },
 {
   "NFT": 31,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 128.9,
   "Rarity_Ranking": 481
 },
 {
   "NFT": 1132,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.85,
   "Rarity_Ranking": 482
 },
 {
   "NFT": 519,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.84,
   "Rarity_Ranking": 483
 },
 {
   "NFT": 732,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.79,
   "Rarity_Ranking": 484
 },
 {
   "NFT": 137,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.62,
   "Rarity_Ranking": 485
 },
 {
   "NFT": 324,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.59,
   "Rarity_Ranking": 486
 },
 {
   "NFT": 861,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 128.59,
   "Rarity_Ranking": 487
 },
 {
   "NFT": 46,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.4,
   "Rarity_Ranking": 488
 },
 {
   "NFT": 745,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.33,
   "Rarity_Ranking": 489
 },
 {
   "NFT": 913,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 128.27,
   "Rarity_Ranking": 490
 },
 {
   "NFT": 12,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 128.16,
   "Rarity_Ranking": 491
 },
 {
   "NFT": 218,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.09,
   "Rarity_Ranking": 492
 },
 {
   "NFT": 1191,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 128.06,
   "Rarity_Ranking": 493
 },
 {
   "NFT": 516,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 127.96,
   "Rarity_Ranking": 494
 },
 {
   "NFT": 103,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 127.76,
   "Rarity_Ranking": 495
 },
 {
   "NFT": 711,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 127.58,
   "Rarity_Ranking": 496
 },
 {
   "NFT": 281,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 127.57,
   "Rarity_Ranking": 497
 },
 {
   "NFT": 1424,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 127.48,
   "Rarity_Ranking": 498
 },
 {
   "NFT": 643,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 127.43,
   "Rarity_Ranking": 499
 },
 {
   "NFT": 1414,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 127.42,
   "Rarity_Ranking": 500
 },
 {
   "NFT": 571,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 127.4,
   "Rarity_Ranking": 501
 },
 {
   "NFT": 179,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 126.64,
   "Rarity_Ranking": 502
 },
 {
   "NFT": 830,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 126.46,
   "Rarity_Ranking": 503
 },
 {
   "NFT": 1405,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 126.46,
   "Rarity_Ranking": 504
 },
 {
   "NFT": 1149,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 126.22,
   "Rarity_Ranking": 505
 },
 {
   "NFT": 293,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 126.1,
   "Rarity_Ranking": 506
 },
 {
   "NFT": 1090,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 126.03,
   "Rarity_Ranking": 507
 },
 {
   "NFT": 126,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.99,
   "Rarity_Ranking": 508
 },
 {
   "NFT": 852,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.84,
   "Rarity_Ranking": 509
 },
 {
   "NFT": 105,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 125.71,
   "Rarity_Ranking": 510
 },
 {
   "NFT": 581,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.69,
   "Rarity_Ranking": 511
 },
 {
   "NFT": 431,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.65,
   "Rarity_Ranking": 512
 },
 {
   "NFT": 1010,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.65,
   "Rarity_Ranking": 513
 },
 {
   "NFT": 1402,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.56,
   "Rarity_Ranking": 514
 },
 {
   "NFT": 163,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.55,
   "Rarity_Ranking": 515
 },
 {
   "NFT": 620,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.46,
   "Rarity_Ranking": 516
 },
 {
   "NFT": 1329,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.46,
   "Rarity_Ranking": 517
 },
 {
   "NFT": 1033,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 125.43,
   "Rarity_Ranking": 518
 },
 {
   "NFT": 29,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.37,
   "Rarity_Ranking": 519
 },
 {
   "NFT": 1022,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.33,
   "Rarity_Ranking": 520
 },
 {
   "NFT": 1547,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.28,
   "Rarity_Ranking": 521
 },
 {
   "NFT": 1340,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.2,
   "Rarity_Ranking": 522
 },
 {
   "NFT": 317,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.16,
   "Rarity_Ranking": 523
 },
 {
   "NFT": 1145,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 125.13,
   "Rarity_Ranking": 524
 },
 {
   "NFT": 1174,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 125.13,
   "Rarity_Ranking": 525
 },
 {
   "NFT": 1515,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 125.07,
   "Rarity_Ranking": 526
 },
 {
   "NFT": 112,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.95,
   "Rarity_Ranking": 527
 },
 {
   "NFT": 1423,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.89,
   "Rarity_Ranking": 528
 },
 {
   "NFT": 819,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 124.74,
   "Rarity_Ranking": 529
 },
 {
   "NFT": 1120,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.68,
   "Rarity_Ranking": 530
 },
 {
   "NFT": 356,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.66,
   "Rarity_Ranking": 531
 },
 {
   "NFT": 322,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.6,
   "Rarity_Ranking": 532
 },
 {
   "NFT": 459,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.46,
   "Rarity_Ranking": 533
 },
 {
   "NFT": 1039,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Badass",
   "Beard_Rarity": 89.29,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.46,
   "Rarity_Ranking": 534
 },
 {
   "NFT": 901,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.44,
   "Rarity_Ranking": 535
 },
 {
   "NFT": 1115,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 124.39,
   "Rarity_Ranking": 536
 },
 {
   "NFT": 295,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.37,
   "Rarity_Ranking": 537
 },
 {
   "NFT": 1516,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.35,
   "Rarity_Ranking": 538
 },
 {
   "NFT": 765,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.26,
   "Rarity_Ranking": 539
 },
 {
   "NFT": 1213,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.18,
   "Rarity_Ranking": 540
 },
 {
   "NFT": 1035,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.05,
   "Rarity_Ranking": 541
 },
 {
   "NFT": 1240,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 124.04,
   "Rarity_Ranking": 542
 },
 {
   "NFT": 245,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 123.96,
   "Rarity_Ranking": 543
 },
 {
   "NFT": 1193,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 123.89,
   "Rarity_Ranking": 544
 },
 {
   "NFT": 120,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 123.84,
   "Rarity_Ranking": 545
 },
 {
   "NFT": 1396,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 123.63,
   "Rarity_Ranking": 546
 },
 {
   "NFT": 960,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 123.36,
   "Rarity_Ranking": 547
 },
 {
   "NFT": 1095,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 123.32,
   "Rarity_Ranking": 548
 },
 {
   "NFT": 1096,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 123.28,
   "Rarity_Ranking": 549
 },
 {
   "NFT": 1257,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 123.26,
   "Rarity_Ranking": 550
 },
 {
   "NFT": 1214,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 123.24,
   "Rarity_Ranking": 551
 },
 {
   "NFT": 1526,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 123.22,
   "Rarity_Ranking": 552
 },
 {
   "NFT": 911,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 123.09,
   "Rarity_Ranking": 553
 },
 {
   "NFT": 1432,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 122.98,
   "Rarity_Ranking": 554
 },
 {
   "NFT": 1167,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 122.89,
   "Rarity_Ranking": 555
 },
 {
   "NFT": 656,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 122.84,
   "Rarity_Ranking": 556
 },
 {
   "NFT": 1427,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 122.81,
   "Rarity_Ranking": 557
 },
 {
   "NFT": 583,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 122.77,
   "Rarity_Ranking": 558
 },
 {
   "NFT": 192,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 122.75,
   "Rarity_Ranking": 559
 },
 {
   "NFT": 416,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 122.69,
   "Rarity_Ranking": 560
 },
 {
   "NFT": 1113,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 122.68,
   "Rarity_Ranking": 561
 },
 {
   "NFT": 1542,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor Gold",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 122.42,
   "Rarity_Ranking": 562
 },
 {
   "NFT": 879,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 122.33,
   "Rarity_Ranking": 563
 },
 {
   "NFT": 607,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 122.28,
   "Rarity_Ranking": 564
 },
 {
   "NFT": 1077,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 122.22,
   "Rarity_Ranking": 565
 },
 {
   "NFT": 1189,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.94,
   "Rarity_Ranking": 566
 },
 {
   "NFT": 996,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.93,
   "Rarity_Ranking": 567
 },
 {
   "NFT": 936,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.72,
   "Rarity_Ranking": 568
 },
 {
   "NFT": 528,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.6,
   "Rarity_Ranking": 569
 },
 {
   "NFT": 1503,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.6,
   "Rarity_Ranking": 570
 },
 {
   "NFT": 668,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.59,
   "Rarity_Ranking": 571
 },
 {
   "NFT": 562,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.58,
   "Rarity_Ranking": 572
 },
 {
   "NFT": 568,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.51,
   "Rarity_Ranking": 573
 },
 {
   "NFT": 1173,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.51,
   "Rarity_Ranking": 574
 },
 {
   "NFT": 1502,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 121.49,
   "Rarity_Ranking": 575
 },
 {
   "NFT": 1128,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.37,
   "Rarity_Ranking": 576
 },
 {
   "NFT": 1141,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.37,
   "Rarity_Ranking": 577
 },
 {
   "NFT": 207,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 121.35,
   "Rarity_Ranking": 578
 },
 {
   "NFT": 1372,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.32,
   "Rarity_Ranking": 579
 },
 {
   "NFT": 552,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.31,
   "Rarity_Ranking": 580
 },
 {
   "NFT": 1142,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.2,
   "Rarity_Ranking": 581
 },
 {
   "NFT": 965,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 121.05,
   "Rarity_Ranking": 582
 },
 {
   "NFT": 973,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 120.96,
   "Rarity_Ranking": 583
 },
 {
   "NFT": 639,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 120.8,
   "Rarity_Ranking": 584
 },
 {
   "NFT": 1289,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 120.66,
   "Rarity_Ranking": 585
 },
 {
   "NFT": 1226,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 120.65,
   "Rarity_Ranking": 586
 },
 {
   "NFT": 1258,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 120.61,
   "Rarity_Ranking": 587
 },
 {
   "NFT": 1274,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 120.56,
   "Rarity_Ranking": 588
 },
 {
   "NFT": 840,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Lennon Glasses",
   "Gadgets_Rarity": 84.03,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 120.52,
   "Rarity_Ranking": 589
 },
 {
   "NFT": 1154,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 120.52,
   "Rarity_Ranking": 590
 },
 {
   "NFT": 216,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 120.42,
   "Rarity_Ranking": 591
 },
 {
   "NFT": 1376,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 120.38,
   "Rarity_Ranking": 592
 },
 {
   "NFT": 942,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 120.36,
   "Rarity_Ranking": 593
 },
 {
   "NFT": 1114,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 120.26,
   "Rarity_Ranking": 594
 },
 {
   "NFT": 428,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 120.25,
   "Rarity_Ranking": 595
 },
 {
   "NFT": 27,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 120.23,
   "Rarity_Ranking": 596
 },
 {
   "NFT": 1430,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 120.09,
   "Rarity_Ranking": 597
 },
 {
   "NFT": 320,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 120.06,
   "Rarity_Ranking": 598
 },
 {
   "NFT": 1280,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 120.06,
   "Rarity_Ranking": 599
 },
 {
   "NFT": 1068,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 119.96,
   "Rarity_Ranking": 600
 },
 {
   "NFT": 1199,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 119.54,
   "Rarity_Ranking": 601
 },
 {
   "NFT": 167,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 119.41,
   "Rarity_Ranking": 602
 },
 {
   "NFT": 898,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 119.27,
   "Rarity_Ranking": 603
 },
 {
   "NFT": 1160,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 119.25,
   "Rarity_Ranking": 604
 },
 {
   "NFT": 957,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 118.68,
   "Rarity_Ranking": 605
 },
 {
   "NFT": 203,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 118.56,
   "Rarity_Ranking": 606
 },
 {
   "NFT": 1436,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 118.56,
   "Rarity_Ranking": 607
 },
 {
   "NFT": 138,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 118.26,
   "Rarity_Ranking": 608
 },
 {
   "NFT": 1272,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 118.22,
   "Rarity_Ranking": 609
 },
 {
   "NFT": 1183,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 118.21,
   "Rarity_Ranking": 610
 },
 {
   "NFT": 1536,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 118.17,
   "Rarity_Ranking": 611
 },
 {
   "NFT": 173,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 118.07,
   "Rarity_Ranking": 612
 },
 {
   "NFT": 347,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 118.01,
   "Rarity_Ranking": 613
 },
 {
   "NFT": 339,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 118,
   "Rarity_Ranking": 614
 },
 {
   "NFT": 832,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 118,
   "Rarity_Ranking": 615
 },
 {
   "NFT": 1330,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 117.99,
   "Rarity_Ranking": 616
 },
 {
   "NFT": 838,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 117.97,
   "Rarity_Ranking": 617
 },
 {
   "NFT": 876,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 117.93,
   "Rarity_Ranking": 618
 },
 {
   "NFT": 1203,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor Gold",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 117.82,
   "Rarity_Ranking": 619
 },
 {
   "NFT": 369,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 117.77,
   "Rarity_Ranking": 620
 },
 {
   "NFT": 1460,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 117.38,
   "Rarity_Ranking": 621
 },
 {
   "NFT": 169,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 117.24,
   "Rarity_Ranking": 622
 },
 {
   "NFT": 274,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 117.15,
   "Rarity_Ranking": 623
 },
 {
   "NFT": 253,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor gold l",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 117.13,
   "Rarity_Ranking": 624
 },
 {
   "NFT": 1455,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 117.04,
   "Rarity_Ranking": 625
 },
 {
   "NFT": 1047,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 116.95,
   "Rarity_Ranking": 626
 },
 {
   "NFT": 1110,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 116.94,
   "Rarity_Ranking": 627
 },
 {
   "NFT": 671,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 116.93,
   "Rarity_Ranking": 628
 },
 {
   "NFT": 171,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 116.73,
   "Rarity_Ranking": 629
 },
 {
   "NFT": 510,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 116.55,
   "Rarity_Ranking": 630
 },
 {
   "NFT": 327,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 116.33,
   "Rarity_Ranking": 631
 },
 {
   "NFT": 349,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 116.18,
   "Rarity_Ranking": 632
 },
 {
   "NFT": 213,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 116.16,
   "Rarity_Ranking": 633
 },
 {
   "NFT": 1168,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 116.16,
   "Rarity_Ranking": 634
 },
 {
   "NFT": 1232,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 115.77,
   "Rarity_Ranking": 635
 },
 {
   "NFT": 1496,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 115.75,
   "Rarity_Ranking": 636
 },
 {
   "NFT": 432,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 115.73,
   "Rarity_Ranking": 637
 },
 {
   "NFT": 980,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 115.65,
   "Rarity_Ranking": 638
 },
 {
   "NFT": 280,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 115.53,
   "Rarity_Ranking": 639
 },
 {
   "NFT": 1509,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 115.35,
   "Rarity_Ranking": 640
 },
 {
   "NFT": 1076,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 115.33,
   "Rarity_Ranking": 641
 },
 {
   "NFT": 86,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 115.25,
   "Rarity_Ranking": 642
 },
 {
   "NFT": 391,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 115.17,
   "Rarity_Ranking": 643
 },
 {
   "NFT": 1024,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 114.98,
   "Rarity_Ranking": 644
 },
 {
   "NFT": 1198,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 114.9,
   "Rarity_Ranking": 645
 },
 {
   "NFT": 1442,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor Gold",
   "Gadgets_Rarity": 72.46,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 114.87,
   "Rarity_Ranking": 646
 },
 {
   "NFT": 815,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 114.68,
   "Rarity_Ranking": 647
 },
 {
   "NFT": 360,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 114.58,
   "Rarity_Ranking": 648
 },
 {
   "NFT": 509,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 114.55,
   "Rarity_Ranking": 649
 },
 {
   "NFT": 918,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 114.48,
   "Rarity_Ranking": 650
 },
 {
   "NFT": 316,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 114.43,
   "Rarity_Ranking": 651
 },
 {
   "NFT": 557,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 114.38,
   "Rarity_Ranking": 652
 },
 {
   "NFT": 67,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 114.01,
   "Rarity_Ranking": 653
 },
 {
   "NFT": 1448,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.98,
   "Rarity_Ranking": 654
 },
 {
   "NFT": 232,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.93,
   "Rarity_Ranking": 655
 },
 {
   "NFT": 97,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.77,
   "Rarity_Ranking": 656
 },
 {
   "NFT": 1019,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.77,
   "Rarity_Ranking": 657
 },
 {
   "NFT": 605,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.63,
   "Rarity_Ranking": 658
 },
 {
   "NFT": 122,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.57,
   "Rarity_Ranking": 659
 },
 {
   "NFT": 1297,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.56,
   "Rarity_Ranking": 660
 },
 {
   "NFT": 1287,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.53,
   "Rarity_Ranking": 661
 },
 {
   "NFT": 147,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.46,
   "Rarity_Ranking": 662
 },
 {
   "NFT": 817,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.44,
   "Rarity_Ranking": 663
 },
 {
   "NFT": 781,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.4,
   "Rarity_Ranking": 664
 },
 {
   "NFT": 813,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.39,
   "Rarity_Ranking": 665
 },
 {
   "NFT": 1262,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.19,
   "Rarity_Ranking": 666
 },
 {
   "NFT": 1002,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.14,
   "Rarity_Ranking": 667
 },
 {
   "NFT": 266,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 113.1,
   "Rarity_Ranking": 668
 },
 {
   "NFT": 800,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.05,
   "Rarity_Ranking": 669
 },
 {
   "NFT": 30,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 113.03,
   "Rarity_Ranking": 670
 },
 {
   "NFT": 80,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 112.89,
   "Rarity_Ranking": 671
 },
 {
   "NFT": 102,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 112.82,
   "Rarity_Ranking": 672
 },
 {
   "NFT": 1368,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 112.74,
   "Rarity_Ranking": 673
 },
 {
   "NFT": 1200,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 112.59,
   "Rarity_Ranking": 674
 },
 {
   "NFT": 1399,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 112.06,
   "Rarity_Ranking": 675
 },
 {
   "NFT": 808,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 111.98,
   "Rarity_Ranking": 676
 },
 {
   "NFT": 50,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 111.91,
   "Rarity_Ranking": 677
 },
 {
   "NFT": 890,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 111.87,
   "Rarity_Ranking": 678
 },
 {
   "NFT": 21,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 111.82,
   "Rarity_Ranking": 679
 },
 {
   "NFT": 61,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 111.82,
   "Rarity_Ranking": 680
 },
 {
   "NFT": 73,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 111.77,
   "Rarity_Ranking": 681
 },
 {
   "NFT": 612,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 111.7,
   "Rarity_Ranking": 682
 },
 {
   "NFT": 366,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 111.61,
   "Rarity_Ranking": 683
 },
 {
   "NFT": 101,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 111.54,
   "Rarity_Ranking": 684
 },
 {
   "NFT": 1350,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 111.44,
   "Rarity_Ranking": 685
 },
 {
   "NFT": 254,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 111.4,
   "Rarity_Ranking": 686
 },
 {
   "NFT": 1126,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 111.36,
   "Rarity_Ranking": 687
 },
 {
   "NFT": 150,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 111.3,
   "Rarity_Ranking": 688
 },
 {
   "NFT": 1256,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.85,
   "Rarity_Ranking": 689
 },
 {
   "NFT": 409,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.84,
   "Rarity_Ranking": 690
 },
 {
   "NFT": 538,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.82,
   "Rarity_Ranking": 691
 },
 {
   "NFT": 521,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.8,
   "Rarity_Ranking": 692
 },
 {
   "NFT": 624,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.79,
   "Rarity_Ranking": 693
 },
 {
   "NFT": 1144,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 110.6,
   "Rarity_Ranking": 694
 },
 {
   "NFT": 790,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.52,
   "Rarity_Ranking": 695
 },
 {
   "NFT": 464,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.42,
   "Rarity_Ranking": 696
 },
 {
   "NFT": 502,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.42,
   "Rarity_Ranking": 697
 },
 {
   "NFT": 844,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.42,
   "Rarity_Ranking": 698
 },
 {
   "NFT": 1136,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.42,
   "Rarity_Ranking": 699
 },
 {
   "NFT": 478,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.29,
   "Rarity_Ranking": 700
 },
 {
   "NFT": 1206,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 110.29,
   "Rarity_Ranking": 701
 },
 {
   "NFT": 811,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.22,
   "Rarity_Ranking": 702
 },
 {
   "NFT": 555,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.2,
   "Rarity_Ranking": 703
 },
 {
   "NFT": 835,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.12,
   "Rarity_Ranking": 704
 },
 {
   "NFT": 131,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 110.02,
   "Rarity_Ranking": 705
 },
 {
   "NFT": 390,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 109.9,
   "Rarity_Ranking": 706
 },
 {
   "NFT": 1295,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 109.89,
   "Rarity_Ranking": 707
 },
 {
   "NFT": 1367,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 109.87,
   "Rarity_Ranking": 708
 },
 {
   "NFT": 886,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 109.83,
   "Rarity_Ranking": 709
 },
 {
   "NFT": 1298,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 109.76,
   "Rarity_Ranking": 710
 },
 {
   "NFT": 1242,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 109.71,
   "Rarity_Ranking": 711
 },
 {
   "NFT": 1296,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 109.65,
   "Rarity_Ranking": 712
 },
 {
   "NFT": 652,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 109.54,
   "Rarity_Ranking": 713
 },
 {
   "NFT": 894,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 109.53,
   "Rarity_Ranking": 714
 },
 {
   "NFT": 1530,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 109.14,
   "Rarity_Ranking": 715
 },
 {
   "NFT": 1490,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 108.81,
   "Rarity_Ranking": 716
 },
 {
   "NFT": 1553,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.75,
   "Rarity_Ranking": 717
 },
 {
   "NFT": 779,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.65,
   "Rarity_Ranking": 718
 },
 {
   "NFT": 436,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 108.62,
   "Rarity_Ranking": 719
 },
 {
   "NFT": 700,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.62,
   "Rarity_Ranking": 720
 },
 {
   "NFT": 304,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 108.5,
   "Rarity_Ranking": 721
 },
 {
   "NFT": 801,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "McDonalds",
   "Helmet_Rarity": 83.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.4,
   "Rarity_Ranking": 722
 },
 {
   "NFT": 1041,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.33,
   "Rarity_Ranking": 723
 },
 {
   "NFT": 382,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.28,
   "Rarity_Ranking": 724
 },
 {
   "NFT": 971,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.22,
   "Rarity_Ranking": 725
 },
 {
   "NFT": 406,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.21,
   "Rarity_Ranking": 726
 },
 {
   "NFT": 784,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.15,
   "Rarity_Ranking": 727
 },
 {
   "NFT": 272,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.14,
   "Rarity_Ranking": 728
 },
 {
   "NFT": 1218,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 108.13,
   "Rarity_Ranking": 729
 },
 {
   "NFT": 1556,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.12,
   "Rarity_Ranking": 730
 },
 {
   "NFT": 903,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.11,
   "Rarity_Ranking": 731
 },
 {
   "NFT": 1366,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.06,
   "Rarity_Ranking": 732
 },
 {
   "NFT": 1091,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 108.04,
   "Rarity_Ranking": 733
 },
 {
   "NFT": 2,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 107.92,
   "Rarity_Ranking": 734
 },
 {
   "NFT": 371,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 107.91,
   "Rarity_Ranking": 735
 },
 {
   "NFT": 683,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 107.84,
   "Rarity_Ranking": 736
 },
 {
   "NFT": 129,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 107.83,
   "Rarity_Ranking": 737
 },
 {
   "NFT": 1225,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 107.74,
   "Rarity_Ranking": 738
 },
 {
   "NFT": 1093,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 107.67,
   "Rarity_Ranking": 739
 },
 {
   "NFT": 201,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 107.61,
   "Rarity_Ranking": 740
 },
 {
   "NFT": 28,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 107.53,
   "Rarity_Ranking": 741
 },
 {
   "NFT": 383,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 107.5,
   "Rarity_Ranking": 742
 },
 {
   "NFT": 608,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 107.5,
   "Rarity_Ranking": 743
 },
 {
   "NFT": 247,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 107.46,
   "Rarity_Ranking": 744
 },
 {
   "NFT": 548,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 107.05,
   "Rarity_Ranking": 745
 },
 {
   "NFT": 545,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 107.02,
   "Rarity_Ranking": 746
 },
 {
   "NFT": 787,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 106.96,
   "Rarity_Ranking": 747
 },
 {
   "NFT": 543,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 106.94,
   "Rarity_Ranking": 748
 },
 {
   "NFT": 368,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 106.5,
   "Rarity_Ranking": 749
 },
 {
   "NFT": 361,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 106.49,
   "Rarity_Ranking": 750
 },
 {
   "NFT": 294,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 106.43,
   "Rarity_Ranking": 751
 },
 {
   "NFT": 603,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 106.43,
   "Rarity_Ranking": 752
 },
 {
   "NFT": 818,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 106.32,
   "Rarity_Ranking": 753
 },
 {
   "NFT": 761,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 106.24,
   "Rarity_Ranking": 754
 },
 {
   "NFT": 303,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 106.01,
   "Rarity_Ranking": 755
 },
 {
   "NFT": 977,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.91,
   "Rarity_Ranking": 756
 },
 {
   "NFT": 1034,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.9,
   "Rarity_Ranking": 757
 },
 {
   "NFT": 1544,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.9,
   "Rarity_Ranking": 758
 },
 {
   "NFT": 438,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.74,
   "Rarity_Ranking": 759
 },
 {
   "NFT": 1385,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.72,
   "Rarity_Ranking": 760
 },
 {
   "NFT": 937,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.62,
   "Rarity_Ranking": 761
 },
 {
   "NFT": 370,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Bold",
   "Hair_Rarity": 63.29,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.61,
   "Rarity_Ranking": 762
 },
 {
   "NFT": 536,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.56,
   "Rarity_Ranking": 763
 },
 {
   "NFT": 155,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 105.53,
   "Rarity_Ranking": 764
 },
 {
   "NFT": 554,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.47,
   "Rarity_Ranking": 765
 },
 {
   "NFT": 1069,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.18,
   "Rarity_Ranking": 766
 },
 {
   "NFT": 573,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 105.13,
   "Rarity_Ranking": 767
 },
 {
   "NFT": 357,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 105.12,
   "Rarity_Ranking": 768
 },
 {
   "NFT": 54,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.97,
   "Rarity_Ranking": 769
 },
 {
   "NFT": 758,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.94,
   "Rarity_Ranking": 770
 },
 {
   "NFT": 127,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.92,
   "Rarity_Ranking": 771
 },
 {
   "NFT": 1028,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.85,
   "Rarity_Ranking": 772
 },
 {
   "NFT": 767,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.84,
   "Rarity_Ranking": 773
 },
 {
   "NFT": 1534,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 104.77,
   "Rarity_Ranking": 774
 },
 {
   "NFT": 1355,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.76,
   "Rarity_Ranking": 775
 },
 {
   "NFT": 963,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.74,
   "Rarity_Ranking": 776
 },
 {
   "NFT": 610,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.69,
   "Rarity_Ranking": 777
 },
 {
   "NFT": 56,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.61,
   "Rarity_Ranking": 778
 },
 {
   "NFT": 592,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.59,
   "Rarity_Ranking": 779
 },
 {
   "NFT": 400,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.45,
   "Rarity_Ranking": 780
 },
 {
   "NFT": 389,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.42,
   "Rarity_Ranking": 781
 },
 {
   "NFT": 1474,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.42,
   "Rarity_Ranking": 782
 },
 {
   "NFT": 140,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 104.04,
   "Rarity_Ranking": 783
 },
 {
   "NFT": 994,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.97,
   "Rarity_Ranking": 784
 },
 {
   "NFT": 415,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.84,
   "Rarity_Ranking": 785
 },
 {
   "NFT": 661,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 103.81,
   "Rarity_Ranking": 786
 },
 {
   "NFT": 353,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.77,
   "Rarity_Ranking": 787
 },
 {
   "NFT": 1283,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.71,
   "Rarity_Ranking": 788
 },
 {
   "NFT": 95,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 103.63,
   "Rarity_Ranking": 789
 },
 {
   "NFT": 836,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.61,
   "Rarity_Ranking": 790
 },
 {
   "NFT": 1081,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 103.59,
   "Rarity_Ranking": 791
 },
 {
   "NFT": 814,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.55,
   "Rarity_Ranking": 792
 },
 {
   "NFT": 511,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.51,
   "Rarity_Ranking": 793
 },
 {
   "NFT": 32,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.49,
   "Rarity_Ranking": 794
 },
 {
   "NFT": 546,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.49,
   "Rarity_Ranking": 795
 },
 {
   "NFT": 1406,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.46,
   "Rarity_Ranking": 796
 },
 {
   "NFT": 1413,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 103.46,
   "Rarity_Ranking": 797
 },
 {
   "NFT": 1169,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 103.44,
   "Rarity_Ranking": 798
 },
 {
   "NFT": 223,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.38,
   "Rarity_Ranking": 799
 },
 {
   "NFT": 159,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.31,
   "Rarity_Ranking": 800
 },
 {
   "NFT": 1103,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 103.24,
   "Rarity_Ranking": 801
 },
 {
   "NFT": 135,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 103.01,
   "Rarity_Ranking": 802
 },
 {
   "NFT": 1349,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.97,
   "Rarity_Ranking": 803
 },
 {
   "NFT": 1307,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.88,
   "Rarity_Ranking": 804
 },
 {
   "NFT": 933,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.85,
   "Rarity_Ranking": 805
 },
 {
   "NFT": 465,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.83,
   "Rarity_Ranking": 806
 },
 {
   "NFT": 858,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.79,
   "Rarity_Ranking": 807
 },
 {
   "NFT": 854,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blue",
   "Hair_Rarity": 57.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 102.73,
   "Rarity_Ranking": 808
 },
 {
   "NFT": 1456,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.7,
   "Rarity_Ranking": 809
 },
 {
   "NFT": 282,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.55,
   "Rarity_Ranking": 810
 },
 {
   "NFT": 48,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 102.54,
   "Rarity_Ranking": 811
 },
 {
   "NFT": 426,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.49,
   "Rarity_Ranking": 812
 },
 {
   "NFT": 837,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.47,
   "Rarity_Ranking": 813
 },
 {
   "NFT": 125,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 102.46,
   "Rarity_Ranking": 814
 },
 {
   "NFT": 1407,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.44,
   "Rarity_Ranking": 815
 },
 {
   "NFT": 434,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 102.41,
   "Rarity_Ranking": 816
 },
 {
   "NFT": 989,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.29,
   "Rarity_Ranking": 817
 },
 {
   "NFT": 1266,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.29,
   "Rarity_Ranking": 818
 },
 {
   "NFT": 444,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.22,
   "Rarity_Ranking": 819
 },
 {
   "NFT": 739,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 102.2,
   "Rarity_Ranking": 820
 },
 {
   "NFT": 489,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 102,
   "Rarity_Ranking": 821
 },
 {
   "NFT": 517,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 101.83,
   "Rarity_Ranking": 822
 },
 {
   "NFT": 26,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 101.82,
   "Rarity_Ranking": 823
 },
 {
   "NFT": 422,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 101.73,
   "Rarity_Ranking": 824
 },
 {
   "NFT": 278,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 101.72,
   "Rarity_Ranking": 825
 },
 {
   "NFT": 944,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 101.52,
   "Rarity_Ranking": 826
 },
 {
   "NFT": 197,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 101.51,
   "Rarity_Ranking": 827
 },
 {
   "NFT": 1179,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 101.5,
   "Rarity_Ranking": 828
 },
 {
   "NFT": 1102,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 101.29,
   "Rarity_Ranking": 829
 },
 {
   "NFT": 1354,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 101.11,
   "Rarity_Ranking": 830
 },
 {
   "NFT": 1055,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 100.94,
   "Rarity_Ranking": 831
 },
 {
   "NFT": 279,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.81,
   "Rarity_Ranking": 832
 },
 {
   "NFT": 1212,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.75,
   "Rarity_Ranking": 833
 },
 {
   "NFT": 118,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.64,
   "Rarity_Ranking": 834
 },
 {
   "NFT": 352,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.59,
   "Rarity_Ranking": 835
 },
 {
   "NFT": 1361,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.47,
   "Rarity_Ranking": 836
 },
 {
   "NFT": 823,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.46,
   "Rarity_Ranking": 837
 },
 {
   "NFT": 584,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.33,
   "Rarity_Ranking": 838
 },
 {
   "NFT": 92,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 100.32,
   "Rarity_Ranking": 839
 },
 {
   "NFT": 1045,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.24,
   "Rarity_Ranking": 840
 },
 {
   "NFT": 1228,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.1,
   "Rarity_Ranking": 841
 },
 {
   "NFT": 350,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.06,
   "Rarity_Ranking": 842
 },
 {
   "NFT": 1462,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.06,
   "Rarity_Ranking": 843
 },
 {
   "NFT": 1558,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.06,
   "Rarity_Ranking": 844
 },
 {
   "NFT": 693,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 100.01,
   "Rarity_Ranking": 845
 },
 {
   "NFT": 654,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.94,
   "Rarity_Ranking": 846
 },
 {
   "NFT": 1025,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 99.89,
   "Rarity_Ranking": 847
 },
 {
   "NFT": 313,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.87,
   "Rarity_Ranking": 848
 },
 {
   "NFT": 513,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.82,
   "Rarity_Ranking": 849
 },
 {
   "NFT": 672,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.79,
   "Rarity_Ranking": 850
 },
 {
   "NFT": 260,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.69,
   "Rarity_Ranking": 851
 },
 {
   "NFT": 962,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.68,
   "Rarity_Ranking": 852
 },
 {
   "NFT": 314,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.56,
   "Rarity_Ranking": 853
 },
 {
   "NFT": 593,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 99.5,
   "Rarity_Ranking": 854
 },
 {
   "NFT": 873,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.46,
   "Rarity_Ranking": 855
 },
 {
   "NFT": 442,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.42,
   "Rarity_Ranking": 856
 },
 {
   "NFT": 318,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.3,
   "Rarity_Ranking": 857
 },
 {
   "NFT": 776,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.26,
   "Rarity_Ranking": 858
 },
 {
   "NFT": 594,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 99.16,
   "Rarity_Ranking": 859
 },
 {
   "NFT": 1487,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 98.82,
   "Rarity_Ranking": 860
 },
 {
   "NFT": 283,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 98.74,
   "Rarity_Ranking": 861
 },
 {
   "NFT": 617,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 98.67,
   "Rarity_Ranking": 862
 },
 {
   "NFT": 1443,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 98.57,
   "Rarity_Ranking": 863
 },
 {
   "NFT": 1333,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 98.41,
   "Rarity_Ranking": 864
 },
 {
   "NFT": 1043,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 98.37,
   "Rarity_Ranking": 865
 },
 {
   "NFT": 262,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 98.36,
   "Rarity_Ranking": 866
 },
 {
   "NFT": 189,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 98.28,
   "Rarity_Ranking": 867
 },
 {
   "NFT": 191,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 98.28,
   "Rarity_Ranking": 868
 },
 {
   "NFT": 979,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 98.12,
   "Rarity_Ranking": 869
 },
 {
   "NFT": 1210,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 98.1,
   "Rarity_Ranking": 870
 },
 {
   "NFT": 1175,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 98.07,
   "Rarity_Ranking": 871
 },
 {
   "NFT": 315,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 97.99,
   "Rarity_Ranking": 872
 },
 {
   "NFT": 1070,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 97.96,
   "Rarity_Ranking": 873
 },
 {
   "NFT": 948,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 97.95,
   "Rarity_Ranking": 874
 },
 {
   "NFT": 90,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 97.78,
   "Rarity_Ranking": 875
 },
 {
   "NFT": 570,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 97.68,
   "Rarity_Ranking": 876
 },
 {
   "NFT": 1067,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 97.66,
   "Rarity_Ranking": 877
 },
 {
   "NFT": 1335,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 97.59,
   "Rarity_Ranking": 878
 },
 {
   "NFT": 880,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 97.48,
   "Rarity_Ranking": 879
 },
 {
   "NFT": 547,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 97.38,
   "Rarity_Ranking": 880
 },
 {
   "NFT": 1468,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 97.37,
   "Rarity_Ranking": 881
 },
 {
   "NFT": 364,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 97.18,
   "Rarity_Ranking": 882
 },
 {
   "NFT": 850,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 97.18,
   "Rarity_Ranking": 883
 },
 {
   "NFT": 1311,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 97.08,
   "Rarity_Ranking": 884
 },
 {
   "NFT": 214,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 97.03,
   "Rarity_Ranking": 885
 },
 {
   "NFT": 950,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.87,
   "Rarity_Ranking": 886
 },
 {
   "NFT": 1356,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.8,
   "Rarity_Ranking": 887
 },
 {
   "NFT": 579,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.77,
   "Rarity_Ranking": 888
 },
 {
   "NFT": 1278,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.65,
   "Rarity_Ranking": 889
 },
 {
   "NFT": 1239,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.62,
   "Rarity_Ranking": 890
 },
 {
   "NFT": 802,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 96.43,
   "Rarity_Ranking": 891
 },
 {
   "NFT": 1565,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.4,
   "Rarity_Ranking": 892
 },
 {
   "NFT": 334,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.39,
   "Rarity_Ranking": 893
 },
 {
   "NFT": 1063,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.31,
   "Rarity_Ranking": 894
 },
 {
   "NFT": 1564,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.27,
   "Rarity_Ranking": 895
 },
 {
   "NFT": 94,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.2,
   "Rarity_Ranking": 896
 },
 {
   "NFT": 228,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.18,
   "Rarity_Ranking": 897
 },
 {
   "NFT": 1251,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.1,
   "Rarity_Ranking": 898
 },
 {
   "NFT": 1387,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 96.02,
   "Rarity_Ranking": 899
 },
 {
   "NFT": 1211,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 95.94,
   "Rarity_Ranking": 900
 },
 {
   "NFT": 1517,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.74,
   "Rarity_Ranking": 901
 },
 {
   "NFT": 786,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.71,
   "Rarity_Ranking": 902
 },
 {
   "NFT": 1469,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 95.68,
   "Rarity_Ranking": 903
 },
 {
   "NFT": 670,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 95.58,
   "Rarity_Ranking": 904
 },
 {
   "NFT": 1021,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.58,
   "Rarity_Ranking": 905
 },
 {
   "NFT": 1365,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.5,
   "Rarity_Ranking": 906
 },
 {
   "NFT": 1304,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.42,
   "Rarity_Ranking": 907
 },
 {
   "NFT": 686,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.4,
   "Rarity_Ranking": 908
 },
 {
   "NFT": 1514,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.4,
   "Rarity_Ranking": 909
 },
 {
   "NFT": 1204,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.37,
   "Rarity_Ranking": 910
 },
 {
   "NFT": 1281,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 95.37,
   "Rarity_Ranking": 911
 },
 {
   "NFT": 1016,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.36,
   "Rarity_Ranking": 912
 },
 {
   "NFT": 703,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.25,
   "Rarity_Ranking": 913
 },
 {
   "NFT": 704,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 95.24,
   "Rarity_Ranking": 914
 },
 {
   "NFT": 284,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.84,
   "Rarity_Ranking": 915
 },
 {
   "NFT": 665,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.82,
   "Rarity_Ranking": 916
 },
 {
   "NFT": 49,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.75,
   "Rarity_Ranking": 917
 },
 {
   "NFT": 984,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.75,
   "Rarity_Ranking": 918
 },
 {
   "NFT": 307,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.71,
   "Rarity_Ranking": 919
 },
 {
   "NFT": 865,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.63,
   "Rarity_Ranking": 920
 },
 {
   "NFT": 65,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.59,
   "Rarity_Ranking": 921
 },
 {
   "NFT": 1512,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.57,
   "Rarity_Ranking": 922
 },
 {
   "NFT": 1049,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.45,
   "Rarity_Ranking": 923
 },
 {
   "NFT": 128,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.42,
   "Rarity_Ranking": 924
 },
 {
   "NFT": 174,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.41,
   "Rarity_Ranking": 925
 },
 {
   "NFT": 117,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 94.37,
   "Rarity_Ranking": 926
 },
 {
   "NFT": 78,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 94.32,
   "Rarity_Ranking": 927
 },
 {
   "NFT": 1435,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.24,
   "Rarity_Ranking": 928
 },
 {
   "NFT": 25,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.2,
   "Rarity_Ranking": 929
 },
 {
   "NFT": 249,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.18,
   "Rarity_Ranking": 930
 },
 {
   "NFT": 363,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 94.14,
   "Rarity_Ranking": 931
 },
 {
   "NFT": 1429,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 93.83,
   "Rarity_Ranking": 932
 },
 {
   "NFT": 871,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.76,
   "Rarity_Ranking": 933
 },
 {
   "NFT": 487,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.74,
   "Rarity_Ranking": 934
 },
 {
   "NFT": 998,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.72,
   "Rarity_Ranking": 935
 },
 {
   "NFT": 188,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 93.65,
   "Rarity_Ranking": 936
 },
 {
   "NFT": 20,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Zombie Head",
   "Head_Ratity": 46.73,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.62,
   "Rarity_Ranking": 937
 },
 {
   "NFT": 13,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.38,
   "Rarity_Ranking": 938
 },
 {
   "NFT": 1106,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.3,
   "Rarity_Ranking": 939
 },
 {
   "NFT": 1235,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.28,
   "Rarity_Ranking": 940
 },
 {
   "NFT": 267,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.25,
   "Rarity_Ranking": 941
 },
 {
   "NFT": 697,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.2,
   "Rarity_Ranking": 942
 },
 {
   "NFT": 1233,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.13,
   "Rarity_Ranking": 943
 },
 {
   "NFT": 66,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 93.12,
   "Rarity_Ranking": 944
 },
 {
   "NFT": 1231,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.08,
   "Rarity_Ranking": 945
 },
 {
   "NFT": 1089,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 93.02,
   "Rarity_Ranking": 946
 },
 {
   "NFT": 1118,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.79,
   "Rarity_Ranking": 947
 },
 {
   "NFT": 618,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.75,
   "Rarity_Ranking": 948
 },
 {
   "NFT": 250,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.72,
   "Rarity_Ranking": 949
 },
 {
   "NFT": 298,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.53,
   "Rarity_Ranking": 950
 },
 {
   "NFT": 22,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.5,
   "Rarity_Ranking": 951
 },
 {
   "NFT": 1243,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.49,
   "Rarity_Ranking": 952
 },
 {
   "NFT": 259,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.44,
   "Rarity_Ranking": 953
 },
 {
   "NFT": 8,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 92.27,
   "Rarity_Ranking": 954
 },
 {
   "NFT": 788,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.27,
   "Rarity_Ranking": 955
 },
 {
   "NFT": 1286,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.22,
   "Rarity_Ranking": 956
 },
 {
   "NFT": 630,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.15,
   "Rarity_Ranking": 957
 },
 {
   "NFT": 1038,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask Golden",
   "Gadgets_Rarity": 53.48,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.14,
   "Rarity_Ranking": 958
 },
 {
   "NFT": 186,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 92.13,
   "Rarity_Ranking": 959
 },
 {
   "NFT": 1178,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 92.13,
   "Rarity_Ranking": 960
 },
 {
   "NFT": 1215,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.97,
   "Rarity_Ranking": 961
 },
 {
   "NFT": 196,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.88,
   "Rarity_Ranking": 962
 },
 {
   "NFT": 947,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.78,
   "Rarity_Ranking": 963
 },
 {
   "NFT": 1261,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.6,
   "Rarity_Ranking": 964
 },
 {
   "NFT": 1488,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 91.57,
   "Rarity_Ranking": 965
 },
 {
   "NFT": 237,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.53,
   "Rarity_Ranking": 966
 },
 {
   "NFT": 143,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 91.48,
   "Rarity_Ranking": 967
 },
 {
   "NFT": 604,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.46,
   "Rarity_Ranking": 968
 },
 {
   "NFT": 1060,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.28,
   "Rarity_Ranking": 969
 },
 {
   "NFT": 1150,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.17,
   "Rarity_Ranking": 970
 },
 {
   "NFT": 134,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.15,
   "Rarity_Ranking": 971
 },
 {
   "NFT": 508,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.09,
   "Rarity_Ranking": 972
 },
 {
   "NFT": 469,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.08,
   "Rarity_Ranking": 973
 },
 {
   "NFT": 1088,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 91.04,
   "Rarity_Ranking": 974
 },
 {
   "NFT": 1031,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.96,
   "Rarity_Ranking": 975
 },
 {
   "NFT": 914,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.94,
   "Rarity_Ranking": 976
 },
 {
   "NFT": 1417,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.87,
   "Rarity_Ranking": 977
 },
 {
   "NFT": 460,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 90.85,
   "Rarity_Ranking": 978
 },
 {
   "NFT": 238,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.84,
   "Rarity_Ranking": 979
 },
 {
   "NFT": 1004,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.82,
   "Rarity_Ranking": 980
 },
 {
   "NFT": 1082,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.81,
   "Rarity_Ranking": 981
 },
 {
   "NFT": 1220,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.78,
   "Rarity_Ranking": 982
 },
 {
   "NFT": 1476,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.75,
   "Rarity_Ranking": 983
 },
 {
   "NFT": 1299,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.58,
   "Rarity_Ranking": 984
 },
 {
   "NFT": 1190,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.51,
   "Rarity_Ranking": 985
 },
 {
   "NFT": 205,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.41,
   "Rarity_Ranking": 986
 },
 {
   "NFT": 887,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 90.39,
   "Rarity_Ranking": 987
 },
 {
   "NFT": 331,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.35,
   "Rarity_Ranking": 988
 },
 {
   "NFT": 748,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.22,
   "Rarity_Ranking": 989
 },
 {
   "NFT": 430,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.21,
   "Rarity_Ranking": 990
 },
 {
   "NFT": 1549,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.17,
   "Rarity_Ranking": 991
 },
 {
   "NFT": 113,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.15,
   "Rarity_Ranking": 992
 },
 {
   "NFT": 1264,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.13,
   "Rarity_Ranking": 993
 },
 {
   "NFT": 1176,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.03,
   "Rarity_Ranking": 994
 },
 {
   "NFT": 1300,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 90.03,
   "Rarity_Ranking": 995
 },
 {
   "NFT": 720,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.99,
   "Rarity_Ranking": 996
 },
 {
   "NFT": 1481,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.99,
   "Rarity_Ranking": 997
 },
 {
   "NFT": 384,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.9,
   "Rarity_Ranking": 998
 },
 {
   "NFT": 866,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.81,
   "Rarity_Ranking": 999
 },
 {
   "NFT": 343,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.71,
   "Rarity_Ranking": 1000
 },
 {
   "NFT": 111,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.64,
   "Rarity_Ranking": 1001
 },
 {
   "NFT": 1253,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 89.6,
   "Rarity_Ranking": 1002
 },
 {
   "NFT": 621,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.56,
   "Rarity_Ranking": 1003
 },
 {
   "NFT": 1080,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long White",
   "Helmet_Rarity": 48.78,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.52,
   "Rarity_Ranking": 1004
 },
 {
   "NFT": 373,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.51,
   "Rarity_Ranking": 1005
 },
 {
   "NFT": 565,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.39,
   "Rarity_Ranking": 1006
 },
 {
   "NFT": 1064,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 89.37,
   "Rarity_Ranking": 1007
 },
 {
   "NFT": 529,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 88.8,
   "Rarity_Ranking": 1008
 },
 {
   "NFT": 940,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 88.65,
   "Rarity_Ranking": 1009
 },
 {
   "NFT": 1410,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 88.56,
   "Rarity_Ranking": 1010
 },
 {
   "NFT": 600,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 88.47,
   "Rarity_Ranking": 1011
 },
 {
   "NFT": 414,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 88.38,
   "Rarity_Ranking": 1012
 },
 {
   "NFT": 297,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 88.31,
   "Rarity_Ranking": 1013
 },
 {
   "NFT": 55,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 88.3,
   "Rarity_Ranking": 1014
 },
 {
   "NFT": 1404,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 88.29,
   "Rarity_Ranking": 1015
 },
 {
   "NFT": 1465,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 88.12,
   "Rarity_Ranking": 1016
 },
 {
   "NFT": 288,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 88.11,
   "Rarity_Ranking": 1017
 },
 {
   "NFT": 1506,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 88.05,
   "Rarity_Ranking": 1018
 },
 {
   "NFT": 1420,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.98,
   "Rarity_Ranking": 1019
 },
 {
   "NFT": 429,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 87.97,
   "Rarity_Ranking": 1020
 },
 {
   "NFT": 924,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.97,
   "Rarity_Ranking": 1021
 },
 {
   "NFT": 1207,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 87.96,
   "Rarity_Ranking": 1022
 },
 {
   "NFT": 161,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.92,
   "Rarity_Ranking": 1023
 },
 {
   "NFT": 1477,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 87.85,
   "Rarity_Ranking": 1024
 },
 {
   "NFT": 553,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.78,
   "Rarity_Ranking": 1025
 },
 {
   "NFT": 599,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.74,
   "Rarity_Ranking": 1026
 },
 {
   "NFT": 742,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.66,
   "Rarity_Ranking": 1027
 },
 {
   "NFT": 1353,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.64,
   "Rarity_Ranking": 1028
 },
 {
   "NFT": 160,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Pink",
   "Beard_Rarity": 57.47,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.63,
   "Rarity_Ranking": 1029
 },
 {
   "NFT": 425,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.5,
   "Rarity_Ranking": 1030
 },
 {
   "NFT": 540,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.4,
   "Rarity_Ranking": 1031
 },
 {
   "NFT": 449,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 87.32,
   "Rarity_Ranking": 1032
 },
 {
   "NFT": 476,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.32,
   "Rarity_Ranking": 1033
 },
 {
   "NFT": 1370,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.32,
   "Rarity_Ranking": 1034
 },
 {
   "NFT": 825,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.22,
   "Rarity_Ranking": 1035
 },
 {
   "NFT": 1403,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 87.18,
   "Rarity_Ranking": 1036
 },
 {
   "NFT": 1400,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.16,
   "Rarity_Ranking": 1037
 },
 {
   "NFT": 381,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.1,
   "Rarity_Ranking": 1038
 },
 {
   "NFT": 1180,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.08,
   "Rarity_Ranking": 1039
 },
 {
   "NFT": 230,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 87.04,
   "Rarity_Ranking": 1040
 },
 {
   "NFT": 291,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.97,
   "Rarity_Ranking": 1041
 },
 {
   "NFT": 1290,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.83,
   "Rarity_Ranking": 1042
 },
 {
   "NFT": 1444,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.77,
   "Rarity_Ranking": 1043
 },
 {
   "NFT": 1312,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.61,
   "Rarity_Ranking": 1044
 },
 {
   "NFT": 1395,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.52,
   "Rarity_Ranking": 1045
 },
 {
   "NFT": 328,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.51,
   "Rarity_Ranking": 1046
 },
 {
   "NFT": 541,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.48,
   "Rarity_Ranking": 1047
 },
 {
   "NFT": 1062,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.45,
   "Rarity_Ranking": 1048
 },
 {
   "NFT": 712,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.39,
   "Rarity_Ranking": 1049
 },
 {
   "NFT": 1291,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.3,
   "Rarity_Ranking": 1050
 },
 {
   "NFT": 1331,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.26,
   "Rarity_Ranking": 1051
 },
 {
   "NFT": 1241,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 86.19,
   "Rarity_Ranking": 1052
 },
 {
   "NFT": 52,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.16,
   "Rarity_Ranking": 1053
 },
 {
   "NFT": 602,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.12,
   "Rarity_Ranking": 1054
 },
 {
   "NFT": 1221,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.09,
   "Rarity_Ranking": 1055
 },
 {
   "NFT": 1419,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 86.09,
   "Rarity_Ranking": 1056
 },
 {
   "NFT": 1493,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 86.01,
   "Rarity_Ranking": 1057
 },
 {
   "NFT": 495,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 85.97,
   "Rarity_Ranking": 1058
 },
 {
   "NFT": 1388,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 85.96,
   "Rarity_Ranking": 1059
 },
 {
   "NFT": 527,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 85.81,
   "Rarity_Ranking": 1060
 },
 {
   "NFT": 423,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 85.65,
   "Rarity_Ranking": 1061
 },
 {
   "NFT": 518,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 85.57,
   "Rarity_Ranking": 1062
 },
 {
   "NFT": 1188,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 85.51,
   "Rarity_Ranking": 1063
 },
 {
   "NFT": 646,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 85.44,
   "Rarity_Ranking": 1064
 },
 {
   "NFT": 655,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 85.35,
   "Rarity_Ranking": 1065
 },
 {
   "NFT": 178,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.95,
   "Rarity_Ranking": 1066
 },
 {
   "NFT": 525,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.74,
   "Rarity_Ranking": 1067
 },
 {
   "NFT": 1246,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 84.67,
   "Rarity_Ranking": 1068
 },
 {
   "NFT": 1260,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.66,
   "Rarity_Ranking": 1069
 },
 {
   "NFT": 248,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.54,
   "Rarity_Ranking": 1070
 },
 {
   "NFT": 658,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 84.53,
   "Rarity_Ranking": 1071
 },
 {
   "NFT": 730,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.46,
   "Rarity_Ranking": 1072
 },
 {
   "NFT": 877,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.46,
   "Rarity_Ranking": 1073
 },
 {
   "NFT": 1540,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 84.44,
   "Rarity_Ranking": 1074
 },
 {
   "NFT": 1306,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.43,
   "Rarity_Ranking": 1075
 },
 {
   "NFT": 794,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.42,
   "Rarity_Ranking": 1076
 },
 {
   "NFT": 1087,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.42,
   "Rarity_Ranking": 1077
 },
 {
   "NFT": 964,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.35,
   "Rarity_Ranking": 1078
 },
 {
   "NFT": 1058,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 84.07,
   "Rarity_Ranking": 1079
 },
 {
   "NFT": 404,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.97,
   "Rarity_Ranking": 1080
 },
 {
   "NFT": 795,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.92,
   "Rarity_Ranking": 1081
 },
 {
   "NFT": 251,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blue",
   "Hair_Rarity": 44.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.9,
   "Rarity_Ranking": 1082
 },
 {
   "NFT": 883,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.88,
   "Rarity_Ranking": 1083
 },
 {
   "NFT": 587,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 83.86,
   "Rarity_Ranking": 1084
 },
 {
   "NFT": 757,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 83.82,
   "Rarity_Ranking": 1085
 },
 {
   "NFT": 576,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.8,
   "Rarity_Ranking": 1086
 },
 {
   "NFT": 1048,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Green",
   "Hair_Rarity": 47.39,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.79,
   "Rarity_Ranking": 1087
 },
 {
   "NFT": 1521,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Cigar",
   "Mouth_Rarity": 39.53,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.62,
   "Rarity_Ranking": 1088
 },
 {
   "NFT": 403,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.59,
   "Rarity_Ranking": 1089
 },
 {
   "NFT": 597,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.55,
   "Rarity_Ranking": 1090
 },
 {
   "NFT": 1397,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.54,
   "Rarity_Ranking": 1091
 },
 {
   "NFT": 569,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 83.49,
   "Rarity_Ranking": 1092
 },
 {
   "NFT": 701,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.49,
   "Rarity_Ranking": 1093
 },
 {
   "NFT": 1005,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Pink Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.48,
   "Rarity_Ranking": 1094
 },
 {
   "NFT": 1475,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.46,
   "Rarity_Ranking": 1095
 },
 {
   "NFT": 490,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.42,
   "Rarity_Ranking": 1096
 },
 {
   "NFT": 657,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 83.42,
   "Rarity_Ranking": 1097
 },
 {
   "NFT": 187,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.37,
   "Rarity_Ranking": 1098
 },
 {
   "NFT": 69,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.36,
   "Rarity_Ranking": 1099
 },
 {
   "NFT": 716,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 83.25,
   "Rarity_Ranking": 1100
 },
 {
   "NFT": 379,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.23,
   "Rarity_Ranking": 1101
 },
 {
   "NFT": 532,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.21,
   "Rarity_Ranking": 1102
 },
 {
   "NFT": 385,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.11,
   "Rarity_Ranking": 1103
 },
 {
   "NFT": 892,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Cool",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.09,
   "Rarity_Ranking": 1104
 },
 {
   "NFT": 479,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 83.03,
   "Rarity_Ranking": 1105
 },
 {
   "NFT": 737,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 82.99,
   "Rarity_Ranking": 1106
 },
 {
   "NFT": 1303,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 82.98,
   "Rarity_Ranking": 1107
 },
 {
   "NFT": 337,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 82.9,
   "Rarity_Ranking": 1108
 },
 {
   "NFT": 647,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 82.86,
   "Rarity_Ranking": 1109
 },
 {
   "NFT": 133,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 82.82,
   "Rarity_Ranking": 1110
 },
 {
   "NFT": 301,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 82.82,
   "Rarity_Ranking": 1111
 },
 {
   "NFT": 575,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 82.74,
   "Rarity_Ranking": 1112
 },
 {
   "NFT": 778,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 82.65,
   "Rarity_Ranking": 1113
 },
 {
   "NFT": 752,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 82.43,
   "Rarity_Ranking": 1114
 },
 {
   "NFT": 688,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 82.36,
   "Rarity_Ranking": 1115
 },
 {
   "NFT": 833,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 82.36,
   "Rarity_Ranking": 1116
 },
 {
   "NFT": 905,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 82.34,
   "Rarity_Ranking": 1117
 },
 {
   "NFT": 564,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 82.24,
   "Rarity_Ranking": 1118
 },
 {
   "NFT": 455,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.95,
   "Rarity_Ranking": 1119
 },
 {
   "NFT": 88,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.87,
   "Rarity_Ranking": 1120
 },
 {
   "NFT": 387,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.86,
   "Rarity_Ranking": 1121
 },
 {
   "NFT": 1012,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.82,
   "Rarity_Ranking": 1122
 },
 {
   "NFT": 499,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.81,
   "Rarity_Ranking": 1123
 },
 {
   "NFT": 1046,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.78,
   "Rarity_Ranking": 1124
 },
 {
   "NFT": 440,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.71,
   "Rarity_Ranking": 1125
 },
 {
   "NFT": 342,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.65,
   "Rarity_Ranking": 1126
 },
 {
   "NFT": 951,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.64,
   "Rarity_Ranking": 1127
 },
 {
   "NFT": 53,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.62,
   "Rarity_Ranking": 1128
 },
 {
   "NFT": 845,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.61,
   "Rarity_Ranking": 1129
 },
 {
   "NFT": 166,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.57,
   "Rarity_Ranking": 1130
 },
 {
   "NFT": 180,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.51,
   "Rarity_Ranking": 1131
 },
 {
   "NFT": 1194,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Green",
   "Hair_Rarity": 43.1,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.48,
   "Rarity_Ranking": 1132
 },
 {
   "NFT": 929,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.47,
   "Rarity_Ranking": 1133
 },
 {
   "NFT": 473,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.34,
   "Rarity_Ranking": 1134
 },
 {
   "NFT": 1539,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.31,
   "Rarity_Ranking": 1135
 },
 {
   "NFT": 580,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.29,
   "Rarity_Ranking": 1136
 },
 {
   "NFT": 1525,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.24,
   "Rarity_Ranking": 1137
 },
 {
   "NFT": 976,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.22,
   "Rarity_Ranking": 1138
 },
 {
   "NFT": 199,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.16,
   "Rarity_Ranking": 1139
 },
 {
   "NFT": 154,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 81.02,
   "Rarity_Ranking": 1140
 },
 {
   "NFT": 226,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Blue Bubble",
   "Mouth_Rarity": 39.84,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 80.79,
   "Rarity_Ranking": 1141
 },
 {
   "NFT": 1026,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 80.79,
   "Rarity_Ranking": 1142
 },
 {
   "NFT": 142,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 80.71,
   "Rarity_Ranking": 1143
 },
 {
   "NFT": 651,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 80.52,
   "Rarity_Ranking": 1144
 },
 {
   "NFT": 71,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 80.49,
   "Rarity_Ranking": 1145
 },
 {
   "NFT": 1543,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 80.48,
   "Rarity_Ranking": 1146
 },
 {
   "NFT": 681,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 80.36,
   "Rarity_Ranking": 1147
 },
 {
   "NFT": 480,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 80.21,
   "Rarity_Ranking": 1148
 },
 {
   "NFT": 34,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 80.08,
   "Rarity_Ranking": 1149
 },
 {
   "NFT": 1389,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 80.01,
   "Rarity_Ranking": 1150
 },
 {
   "NFT": 785,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.9,
   "Rarity_Ranking": 1151
 },
 {
   "NFT": 982,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.85,
   "Rarity_Ranking": 1152
 },
 {
   "NFT": 1119,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.8,
   "Rarity_Ranking": 1153
 },
 {
   "NFT": 764,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.79,
   "Rarity_Ranking": 1154
 },
 {
   "NFT": 923,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.78,
   "Rarity_Ranking": 1155
 },
 {
   "NFT": 1339,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Bubble Pipe ",
   "Mouth_Rarity": 47.39,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.71,
   "Rarity_Ranking": 1156
 },
 {
   "NFT": 679,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.69,
   "Rarity_Ranking": 1157
 },
 {
   "NFT": 1148,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.67,
   "Rarity_Ranking": 1158
 },
 {
   "NFT": 995,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.51,
   "Rarity_Ranking": 1159
 },
 {
   "NFT": 47,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.47,
   "Rarity_Ranking": 1160
 },
 {
   "NFT": 1275,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.32,
   "Rarity_Ranking": 1161
 },
 {
   "NFT": 585,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 79.27,
   "Rarity_Ranking": 1162
 },
 {
   "NFT": 907,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.15,
   "Rarity_Ranking": 1163
 },
 {
   "NFT": 1273,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79.09,
   "Rarity_Ranking": 1164
 },
 {
   "NFT": 885,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 79.03,
   "Rarity_Ranking": 1165
 },
 {
   "NFT": 1042,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 79,
   "Rarity_Ranking": 1166
 },
 {
   "NFT": 477,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.97,
   "Rarity_Ranking": 1167
 },
 {
   "NFT": 240,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 78.9,
   "Rarity_Ranking": 1168
 },
 {
   "NFT": 839,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 78.89,
   "Rarity_Ranking": 1169
 },
 {
   "NFT": 277,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.88,
   "Rarity_Ranking": 1170
 },
 {
   "NFT": 1155,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.81,
   "Rarity_Ranking": 1171
 },
 {
   "NFT": 1344,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.76,
   "Rarity_Ranking": 1172
 },
 {
   "NFT": 754,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.68,
   "Rarity_Ranking": 1173
 },
 {
   "NFT": 290,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.58,
   "Rarity_Ranking": 1174
 },
 {
   "NFT": 1461,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.54,
   "Rarity_Ranking": 1175
 },
 {
   "NFT": 799,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.53,
   "Rarity_Ranking": 1176
 },
 {
   "NFT": 59,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 78.41,
   "Rarity_Ranking": 1177
 },
 {
   "NFT": 399,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 78.39,
   "Rarity_Ranking": 1178
 },
 {
   "NFT": 967,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.2,
   "Rarity_Ranking": 1179
 },
 {
   "NFT": 512,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.19,
   "Rarity_Ranking": 1180
 },
 {
   "NFT": 1185,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.17,
   "Rarity_Ranking": 1181
 },
 {
   "NFT": 1134,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.15,
   "Rarity_Ranking": 1182
 },
 {
   "NFT": 1029,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 78.12,
   "Rarity_Ranking": 1183
 },
 {
   "NFT": 287,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.7,
   "Rarity_Ranking": 1184
 },
 {
   "NFT": 698,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Splitted Brown ",
   "Beard_Rarity": 49.75,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.55,
   "Rarity_Ranking": 1185
 },
 {
   "NFT": 574,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.53,
   "Rarity_Ranking": 1186
 },
 {
   "NFT": 740,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 77.46,
   "Rarity_Ranking": 1187
 },
 {
   "NFT": 177,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.43,
   "Rarity_Ranking": 1188
 },
 {
   "NFT": 1209,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Splitted Pink",
   "Beard_Rarity": 46.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.4,
   "Rarity_Ranking": 1189
 },
 {
   "NFT": 333,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.32,
   "Rarity_Ranking": 1190
 },
 {
   "NFT": 689,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.31,
   "Rarity_Ranking": 1191
 },
 {
   "NFT": 941,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.22,
   "Rarity_Ranking": 1192
 },
 {
   "NFT": 988,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.18,
   "Rarity_Ranking": 1193
 },
 {
   "NFT": 1259,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.12,
   "Rarity_Ranking": 1194
 },
 {
   "NFT": 955,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 77.01,
   "Rarity_Ranking": 1195
 },
 {
   "NFT": 241,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.98,
   "Rarity_Ranking": 1196
 },
 {
   "NFT": 560,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 76.97,
   "Rarity_Ranking": 1197
 },
 {
   "NFT": 1245,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.85,
   "Rarity_Ranking": 1198
 },
 {
   "NFT": 586,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.84,
   "Rarity_Ranking": 1199
 },
 {
   "NFT": 1470,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.84,
   "Rarity_Ranking": 1200
 },
 {
   "NFT": 919,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Green",
   "Helmet_Rarity": 41.15,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.82,
   "Rarity_Ranking": 1201
 },
 {
   "NFT": 912,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.75,
   "Rarity_Ranking": 1202
 },
 {
   "NFT": 454,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.68,
   "Rarity_Ranking": 1203
 },
 {
   "NFT": 847,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.67,
   "Rarity_Ranking": 1204
 },
 {
   "NFT": 1394,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.65,
   "Rarity_Ranking": 1205
 },
 {
   "NFT": 687,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.5,
   "Rarity_Ranking": 1206
 },
 {
   "NFT": 136,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.47,
   "Rarity_Ranking": 1207
 },
 {
   "NFT": 496,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.46,
   "Rarity_Ranking": 1208
 },
 {
   "NFT": 1418,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 76.45,
   "Rarity_Ranking": 1209
 },
 {
   "NFT": 1531,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.39,
   "Rarity_Ranking": 1210
 },
 {
   "NFT": 35,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.13,
   "Rarity_Ranking": 1211
 },
 {
   "NFT": 82,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small Blue Bubble",
   "Mouth_Rarity": 40,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 76.1,
   "Rarity_Ranking": 1212
 },
 {
   "NFT": 983,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.97,
   "Rarity_Ranking": 1213
 },
 {
   "NFT": 329,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.95,
   "Rarity_Ranking": 1214
 },
 {
   "NFT": 1277,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.94,
   "Rarity_Ranking": 1215
 },
 {
   "NFT": 902,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 75.93,
   "Rarity_Ranking": 1216
 },
 {
   "NFT": 351,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.9,
   "Rarity_Ranking": 1217
 },
 {
   "NFT": 1109,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.9,
   "Rarity_Ranking": 1218
 },
 {
   "NFT": 413,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.79,
   "Rarity_Ranking": 1219
 },
 {
   "NFT": 62,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.76,
   "Rarity_Ranking": 1220
 },
 {
   "NFT": 842,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.49,
   "Rarity_Ranking": 1221
 },
 {
   "NFT": 1562,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.47,
   "Rarity_Ranking": 1222
 },
 {
   "NFT": 75,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.44,
   "Rarity_Ranking": 1223
 },
 {
   "NFT": 1036,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "double buger",
   "Marks_Rarity": 42.74,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.39,
   "Rarity_Ranking": 1224
 },
 {
   "NFT": 828,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.34,
   "Rarity_Ranking": 1225
 },
 {
   "NFT": 115,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.28,
   "Rarity_Ranking": 1226
 },
 {
   "NFT": 1338,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75.22,
   "Rarity_Ranking": 1227
 },
 {
   "NFT": 862,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 75,
   "Rarity_Ranking": 1228
 },
 {
   "NFT": 1153,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.8,
   "Rarity_Ranking": 1229
 },
 {
   "NFT": 87,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.71,
   "Rarity_Ranking": 1230
 },
 {
   "NFT": 641,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.64,
   "Rarity_Ranking": 1231
 },
 {
   "NFT": 367,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.6,
   "Rarity_Ranking": 1232
 },
 {
   "NFT": 1186,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.57,
   "Rarity_Ranking": 1233
 },
 {
   "NFT": 450,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.54,
   "Rarity_Ranking": 1234
 },
 {
   "NFT": 483,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.51,
   "Rarity_Ranking": 1235
 },
 {
   "NFT": 1139,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 74.43,
   "Rarity_Ranking": 1236
 },
 {
   "NFT": 1285,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.27,
   "Rarity_Ranking": 1237
 },
 {
   "NFT": 418,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.18,
   "Rarity_Ranking": 1238
 },
 {
   "NFT": 1467,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 74.18,
   "Rarity_Ranking": 1239
 },
 {
   "NFT": 1152,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Green Bubble",
   "Mouth_Rarity": 43.67,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.15,
   "Rarity_Ranking": 1240
 },
 {
   "NFT": 1248,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.11,
   "Rarity_Ranking": 1241
 },
 {
   "NFT": 1437,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.1,
   "Rarity_Ranking": 1242
 },
 {
   "NFT": 484,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.05,
   "Rarity_Ranking": 1243
 },
 {
   "NFT": 1271,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 74.03,
   "Rarity_Ranking": 1244
 },
 {
   "NFT": 1001,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Big Green Bubble",
   "Mouth_Rarity": 43.48,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.89,
   "Rarity_Ranking": 1245
 },
 {
   "NFT": 202,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 73.83,
   "Rarity_Ranking": 1246
 },
 {
   "NFT": 1320,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Grey",
   "Beard_Rarity": 45.87,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.75,
   "Rarity_Ranking": 1247
 },
 {
   "NFT": 492,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 73.58,
   "Rarity_Ranking": 1248
 },
 {
   "NFT": 1084,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.52,
   "Rarity_Ranking": 1249
 },
 {
   "NFT": 598,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.51,
   "Rarity_Ranking": 1250
 },
 {
   "NFT": 1223,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.45,
   "Rarity_Ranking": 1251
 },
 {
   "NFT": 474,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.37,
   "Rarity_Ranking": 1252
 },
 {
   "NFT": 974,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.36,
   "Rarity_Ranking": 1253
 },
 {
   "NFT": 725,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.34,
   "Rarity_Ranking": 1254
 },
 {
   "NFT": 1438,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.34,
   "Rarity_Ranking": 1255
 },
 {
   "NFT": 130,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 73.18,
   "Rarity_Ranking": 1256
 },
 {
   "NFT": 690,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.15,
   "Rarity_Ranking": 1257
 },
 {
   "NFT": 1288,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.14,
   "Rarity_Ranking": 1258
 },
 {
   "NFT": 893,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Green ",
   "Beard_Rarity": 45.25,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 73.1,
   "Rarity_Ranking": 1259
 },
 {
   "NFT": 1254,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 72.95,
   "Rarity_Ranking": 1260
 },
 {
   "NFT": 526,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 72.92,
   "Rarity_Ranking": 1261
 },
 {
   "NFT": 1452,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Big Pink Bubble",
   "Mouth_Rarity": 37.45,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 72.75,
   "Rarity_Ranking": 1262
 },
 {
   "NFT": 864,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 72.73,
   "Rarity_Ranking": 1263
 },
 {
   "NFT": 1061,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 72.69,
   "Rarity_Ranking": 1264
 },
 {
   "NFT": 673,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 72.59,
   "Rarity_Ranking": 1265
 },
 {
   "NFT": 1523,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 72.52,
   "Rarity_Ranking": 1266
 },
 {
   "NFT": 1464,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 72.43,
   "Rarity_Ranking": 1267
 },
 {
   "NFT": 1351,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 72.41,
   "Rarity_Ranking": 1268
 },
 {
   "NFT": 1422,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 72.35,
   "Rarity_Ranking": 1269
 },
 {
   "NFT": 1480,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 72.3,
   "Rarity_Ranking": 1270
 },
 {
   "NFT": 1184,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.94,
   "Rarity_Ranking": 1271
 },
 {
   "NFT": 1557,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.94,
   "Rarity_Ranking": 1272
 },
 {
   "NFT": 106,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.91,
   "Rarity_Ranking": 1273
 },
 {
   "NFT": 362,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.91,
   "Rarity_Ranking": 1274
 },
 {
   "NFT": 1363,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.87,
   "Rarity_Ranking": 1275
 },
 {
   "NFT": 954,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.85,
   "Rarity_Ranking": 1276
 },
 {
   "NFT": 123,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.77,
   "Rarity_Ranking": 1277
 },
 {
   "NFT": 619,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.62,
   "Rarity_Ranking": 1278
 },
 {
   "NFT": 1111,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.61,
   "Rarity_Ranking": 1279
 },
 {
   "NFT": 533,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.54,
   "Rarity_Ranking": 1280
 },
 {
   "NFT": 77,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small Pink Bubble",
   "Mouth_Rarity": 40.16,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.53,
   "Rarity_Ranking": 1281
 },
 {
   "NFT": 486,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.38,
   "Rarity_Ranking": 1282
 },
 {
   "NFT": 747,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 71.18,
   "Rarity_Ranking": 1283
 },
 {
   "NFT": 567,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 71.07,
   "Rarity_Ranking": 1284
 },
 {
   "NFT": 588,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70.98,
   "Rarity_Ranking": 1285
 },
 {
   "NFT": 724,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Broken Glasses",
   "Gadgets_Rarity": 27.62,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70.95,
   "Rarity_Ranking": 1286
 },
 {
   "NFT": 831,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70.93,
   "Rarity_Ranking": 1287
 },
 {
   "NFT": 1244,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70.84,
   "Rarity_Ranking": 1288
 },
 {
   "NFT": 344,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70.72,
   "Rarity_Ranking": 1289
 },
 {
   "NFT": 834,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70.67,
   "Rarity_Ranking": 1290
 },
 {
   "NFT": 1135,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Pink",
   "Hair_Rarity": 33.44,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70.63,
   "Rarity_Ranking": 1291
 },
 {
   "NFT": 999,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 70.41,
   "Rarity_Ranking": 1292
 },
 {
   "NFT": 622,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70.22,
   "Rarity_Ranking": 1293
 },
 {
   "NFT": 309,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70.15,
   "Rarity_Ranking": 1294
 },
 {
   "NFT": 215,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70.14,
   "Rarity_Ranking": 1295
 },
 {
   "NFT": 726,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 70,
   "Rarity_Ranking": 1296
 },
 {
   "NFT": 325,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.89,
   "Rarity_Ranking": 1297
 },
 {
   "NFT": 1003,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.81,
   "Rarity_Ranking": 1298
 },
 {
   "NFT": 803,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.74,
   "Rarity_Ranking": 1299
 },
 {
   "NFT": 1112,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.67,
   "Rarity_Ranking": 1300
 },
 {
   "NFT": 775,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.57,
   "Rarity_Ranking": 1301
 },
 {
   "NFT": 1100,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "3D Glasses",
   "Gadgets_Rarity": 42.55,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.47,
   "Rarity_Ranking": 1302
 },
 {
   "NFT": 148,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 69.43,
   "Rarity_Ranking": 1303
 },
 {
   "NFT": 1511,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.42,
   "Rarity_Ranking": 1304
 },
 {
   "NFT": 1156,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.35,
   "Rarity_Ranking": 1305
 },
 {
   "NFT": 1056,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Steel Head",
   "Head_Ratity": 36.63,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.31,
   "Rarity_Ranking": 1306
 },
 {
   "NFT": 165,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.26,
   "Rarity_Ranking": 1307
 },
 {
   "NFT": 755,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 69.25,
   "Rarity_Ranking": 1308
 },
 {
   "NFT": 235,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.98,
   "Rarity_Ranking": 1309
 },
 {
   "NFT": 1279,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.94,
   "Rarity_Ranking": 1310
 },
 {
   "NFT": 443,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.84,
   "Rarity_Ranking": 1311
 },
 {
   "NFT": 11,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.76,
   "Rarity_Ranking": 1312
 },
 {
   "NFT": 589,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.6,
   "Rarity_Ranking": 1313
 },
 {
   "NFT": 170,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.59,
   "Rarity_Ranking": 1314
 },
 {
   "NFT": 506,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.47,
   "Rarity_Ranking": 1315
 },
 {
   "NFT": 664,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.46,
   "Rarity_Ranking": 1316
 },
 {
   "NFT": 1011,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.35,
   "Rarity_Ranking": 1317
 },
 {
   "NFT": 1309,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 68.29,
   "Rarity_Ranking": 1318
 },
 {
   "NFT": 1323,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.23,
   "Rarity_Ranking": 1319
 },
 {
   "NFT": 846,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.22,
   "Rarity_Ranking": 1320
 },
 {
   "NFT": 638,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Cigarette",
   "Mouth_Rarity": 33.78,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.18,
   "Rarity_Ranking": 1321
 },
 {
   "NFT": 990,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.16,
   "Rarity_Ranking": 1322
 },
 {
   "NFT": 677,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.13,
   "Rarity_Ranking": 1323
 },
 {
   "NFT": 1497,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 68.05,
   "Rarity_Ranking": 1324
 },
 {
   "NFT": 152,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 67.93,
   "Rarity_Ranking": 1325
 },
 {
   "NFT": 265,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Visor pink",
   "Gadgets_Rarity": 26.18,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.93,
   "Rarity_Ranking": 1326
 },
 {
   "NFT": 1117,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.87,
   "Rarity_Ranking": 1327
 },
 {
   "NFT": 934,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.86,
   "Rarity_Ranking": 1328
 },
 {
   "NFT": 1382,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.86,
   "Rarity_Ranking": 1329
 },
 {
   "NFT": 634,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 67.76,
   "Rarity_Ranking": 1330
 },
 {
   "NFT": 358,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.64,
   "Rarity_Ranking": 1331
 },
 {
   "NFT": 1159,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.61,
   "Rarity_Ranking": 1332
 },
 {
   "NFT": 986,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Pink",
   "Hair_Rarity": 33.22,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.59,
   "Rarity_Ranking": 1333
 },
 {
   "NFT": 708,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.56,
   "Rarity_Ranking": 1334
 },
 {
   "NFT": 975,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.49,
   "Rarity_Ranking": 1335
 },
 {
   "NFT": 1378,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.48,
   "Rarity_Ranking": 1336
 },
 {
   "NFT": 1352,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.33,
   "Rarity_Ranking": 1337
 },
 {
   "NFT": 855,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.14,
   "Rarity_Ranking": 1338
 },
 {
   "NFT": 1151,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Tabacco Pipe",
   "Mouth_Rarity": 28.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.03,
   "Rarity_Ranking": 1339
 },
 {
   "NFT": 1548,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Pink",
   "Helmet_Rarity": 33.33,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 67.01,
   "Rarity_Ranking": 1340
 },
 {
   "NFT": 878,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 66.99,
   "Rarity_Ranking": 1341
 },
 {
   "NFT": 1483,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 66.98,
   "Rarity_Ranking": 1342
 },
 {
   "NFT": 1459,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 66.97,
   "Rarity_Ranking": 1343
 },
 {
   "NFT": 777,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 66.6,
   "Rarity_Ranking": 1344
 },
 {
   "NFT": 261,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 66.58,
   "Rarity_Ranking": 1345
 },
 {
   "NFT": 1492,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 66.57,
   "Rarity_Ranking": 1346
 },
 {
   "NFT": 1195,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 66.53,
   "Rarity_Ranking": 1347
 },
 {
   "NFT": 1510,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 66.06,
   "Rarity_Ranking": 1348
 },
 {
   "NFT": 1360,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 65.93,
   "Rarity_Ranking": 1349
 },
 {
   "NFT": 172,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 65.91,
   "Rarity_Ranking": 1350
 },
 {
   "NFT": 972,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 65.83,
   "Rarity_Ranking": 1351
 },
 {
   "NFT": 1229,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 65.57,
   "Rarity_Ranking": 1352
 },
 {
   "NFT": 42,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 65.51,
   "Rarity_Ranking": 1353
 },
 {
   "NFT": 448,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 65.42,
   "Rarity_Ranking": 1354
 },
 {
   "NFT": 4,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 65.29,
   "Rarity_Ranking": 1355
 },
 {
   "NFT": 1236,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 65.26,
   "Rarity_Ranking": 1356
 },
 {
   "NFT": 669,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 65.19,
   "Rarity_Ranking": 1357
 },
 {
   "NFT": 938,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 64.87,
   "Rarity_Ranking": 1358
 },
 {
   "NFT": 14,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 64.86,
   "Rarity_Ranking": 1359
 },
 {
   "NFT": 1482,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 64.85,
   "Rarity_Ranking": 1360
 },
 {
   "NFT": 1037,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 64.74,
   "Rarity_Ranking": 1361
 },
 {
   "NFT": 417,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 64.57,
   "Rarity_Ranking": 1362
 },
 {
   "NFT": 1237,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 64.57,
   "Rarity_Ranking": 1363
 },
 {
   "NFT": 821,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 64.56,
   "Rarity_Ranking": 1364
 },
 {
   "NFT": 447,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blind",
   "Eyes_Rarity": 30.12,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 64.55,
   "Rarity_Ranking": 1365
 },
 {
   "NFT": 699,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 64.18,
   "Rarity_Ranking": 1366
 },
 {
   "NFT": 1009,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 64.16,
   "Rarity_Ranking": 1367
 },
 {
   "NFT": 1528,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 64.04,
   "Rarity_Ranking": 1368
 },
 {
   "NFT": 922,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 63.91,
   "Rarity_Ranking": 1369
 },
 {
   "NFT": 1293,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 63.83,
   "Rarity_Ranking": 1370
 },
 {
   "NFT": 849,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 63.75,
   "Rarity_Ranking": 1371
 },
 {
   "NFT": 410,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 63.71,
   "Rarity_Ranking": 1372
 },
 {
   "NFT": 256,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 63.61,
   "Rarity_Ranking": 1373
 },
 {
   "NFT": 1466,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 63.49,
   "Rarity_Ranking": 1374
 },
 {
   "NFT": 556,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 63.41,
   "Rarity_Ranking": 1375
 },
 {
   "NFT": 332,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 63.36,
   "Rarity_Ranking": 1376
 },
 {
   "NFT": 98,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 63.26,
   "Rarity_Ranking": 1377
 },
 {
   "NFT": 1032,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Splitted Blue ",
   "Beard_Rarity": 33.22,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 62.9,
   "Rarity_Ranking": 1378
 },
 {
   "NFT": 1140,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 62.79,
   "Rarity_Ranking": 1379
 },
 {
   "NFT": 1292,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 62.39,
   "Rarity_Ranking": 1380
 },
 {
   "NFT": 1137,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Green Bushy Beard",
   "Beard_Rarity": 34.13,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 62.36,
   "Rarity_Ranking": 1381
 },
 {
   "NFT": 1489,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 62.3,
   "Rarity_Ranking": 1382
 },
 {
   "NFT": 961,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 62.19,
   "Rarity_Ranking": 1383
 },
 {
   "NFT": 920,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 61.78,
   "Rarity_Ranking": 1384
 },
 {
   "NFT": 396,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 61.63,
   "Rarity_Ranking": 1385
 },
 {
   "NFT": 848,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 61.57,
   "Rarity_Ranking": 1386
 },
 {
   "NFT": 1336,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 61.5,
   "Rarity_Ranking": 1387
 },
 {
   "NFT": 1250,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 60.96,
   "Rarity_Ranking": 1388
 },
 {
   "NFT": 645,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 60.95,
   "Rarity_Ranking": 1389
 },
 {
   "NFT": 613,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Pink Bushy ",
   "Beard_Rarity": 32.79,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 60.76,
   "Rarity_Ranking": 1390
 },
 {
   "NFT": 471,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 60.69,
   "Rarity_Ranking": 1391
 },
 {
   "NFT": 1051,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 60.52,
   "Rarity_Ranking": 1392
 },
 {
   "NFT": 1347,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 60.22,
   "Rarity_Ranking": 1393
 },
 {
   "NFT": 1129,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 60.17,
   "Rarity_Ranking": 1394
 },
 {
   "NFT": 494,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 60.08,
   "Rarity_Ranking": 1395
 },
 {
   "NFT": 482,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 60.07,
   "Rarity_Ranking": 1396
 },
 {
   "NFT": 549,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Green",
   "Helmet_Rarity": 21.28,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 59.98,
   "Rarity_Ranking": 1397
 },
 {
   "NFT": 1053,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.97,
   "Rarity_Ranking": 1398
 },
 {
   "NFT": 1146,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.97,
   "Rarity_Ranking": 1399
 },
 {
   "NFT": 530,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.93,
   "Rarity_Ranking": 1400
 },
 {
   "NFT": 93,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Visor green ",
   "Gadgets_Rarity": 21.32,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.79,
   "Rarity_Ranking": 1401
 },
 {
   "NFT": 741,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.79,
   "Rarity_Ranking": 1402
 },
 {
   "NFT": 662,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.78,
   "Rarity_Ranking": 1403
 },
 {
   "NFT": 827,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.6,
   "Rarity_Ranking": 1404
 },
 {
   "NFT": 1071,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.5,
   "Rarity_Ranking": 1405
 },
 {
   "NFT": 1425,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.37,
   "Rarity_Ranking": 1406
 },
 {
   "NFT": 614,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.36,
   "Rarity_Ranking": 1407
 },
 {
   "NFT": 397,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 59.13,
   "Rarity_Ranking": 1408
 },
 {
   "NFT": 269,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.94,
   "Rarity_Ranking": 1409
 },
 {
   "NFT": 340,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 58.92,
   "Rarity_Ranking": 1410
 },
 {
   "NFT": 772,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 58.92,
   "Rarity_Ranking": 1411
 },
 {
   "NFT": 822,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Mask White",
   "Gadgets_Rarity": 31.65,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.86,
   "Rarity_Ranking": 1412
 },
 {
   "NFT": 1364,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.75,
   "Rarity_Ranking": 1413
 },
 {
   "NFT": 896,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.7,
   "Rarity_Ranking": 1414
 },
 {
   "NFT": 144,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.58,
   "Rarity_Ranking": 1415
 },
 {
   "NFT": 1421,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.5,
   "Rarity_Ranking": 1416
 },
 {
   "NFT": 987,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.46,
   "Rarity_Ranking": 1417
 },
 {
   "NFT": 1535,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.46,
   "Rarity_Ranking": 1418
 },
 {
   "NFT": 959,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.42,
   "Rarity_Ranking": 1419
 },
 {
   "NFT": 1099,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.42,
   "Rarity_Ranking": 1420
 },
 {
   "NFT": 458,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 58.04,
   "Rarity_Ranking": 1421
 },
 {
   "NFT": 463,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.94,
   "Rarity_Ranking": 1422
 },
 {
   "NFT": 1177,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.82,
   "Rarity_Ranking": 1423
 },
 {
   "NFT": 997,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.81,
   "Rarity_Ranking": 1424
 },
 {
   "NFT": 744,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.77,
   "Rarity_Ranking": 1425
 },
 {
   "NFT": 1008,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.74,
   "Rarity_Ranking": 1426
 },
 {
   "NFT": 285,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.73,
   "Rarity_Ranking": 1427
 },
 {
   "NFT": 149,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.7,
   "Rarity_Ranking": 1428
 },
 {
   "NFT": 1478,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.62,
   "Rarity_Ranking": 1429
 },
 {
   "NFT": 1054,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.57,
   "Rarity_Ranking": 1430
 },
 {
   "NFT": 1463,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.49,
   "Rarity_Ranking": 1431
 },
 {
   "NFT": 1381,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.48,
   "Rarity_Ranking": 1432
 },
 {
   "NFT": 1065,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.42,
   "Rarity_Ranking": 1433
 },
 {
   "NFT": 193,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.33,
   "Rarity_Ranking": 1434
 },
 {
   "NFT": 537,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.32,
   "Rarity_Ranking": 1435
 },
 {
   "NFT": 1127,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.31,
   "Rarity_Ranking": 1436
 },
 {
   "NFT": 242,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Missing Teeth",
   "Mouth_Rarity": 21.01,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.28,
   "Rarity_Ranking": 1437
 },
 {
   "NFT": 1308,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Long Bronce",
   "Helmet_Rarity": 32.57,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.28,
   "Rarity_Ranking": 1438
 },
 {
   "NFT": 1147,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.22,
   "Rarity_Ranking": 1439
 },
 {
   "NFT": 985,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.18,
   "Rarity_Ranking": 1440
 },
 {
   "NFT": 721,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.13,
   "Rarity_Ranking": 1441
 },
 {
   "NFT": 1371,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.04,
   "Rarity_Ranking": 1442
 },
 {
   "NFT": 1294,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 57.01,
   "Rarity_Ranking": 1443
 },
 {
   "NFT": 1384,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 56.99,
   "Rarity_Ranking": 1444
 },
 {
   "NFT": 411,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 56.96,
   "Rarity_Ranking": 1445
 },
 {
   "NFT": 653,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 56.95,
   "Rarity_Ranking": 1446
 },
 {
   "NFT": 637,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 56.92,
   "Rarity_Ranking": 1447
 },
 {
   "NFT": 917,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 56.72,
   "Rarity_Ranking": 1448
 },
 {
   "NFT": 992,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 56.64,
   "Rarity_Ranking": 1449
 },
 {
   "NFT": 875,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 56.42,
   "Rarity_Ranking": 1450
 },
 {
   "NFT": 1007,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 56.28,
   "Rarity_Ranking": 1451
 },
 {
   "NFT": 1500,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 56.28,
   "Rarity_Ranking": 1452
 },
 {
   "NFT": 427,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "nothing",
   "Beard_Rarity": 26.11,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 56.03,
   "Rarity_Ranking": 1453
 },
 {
   "NFT": 9,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Blonde",
   "Hair_Rarity": 19.16,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.92,
   "Rarity_Ranking": 1454
 },
 {
   "NFT": 753,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.86,
   "Rarity_Ranking": 1455
 },
 {
   "NFT": 663,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.83,
   "Rarity_Ranking": 1456
 },
 {
   "NFT": 175,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Colonel",
   "Rank_Rarity": 20.04,
   "Rarity_Score": 55.6,
   "Rarity_Ranking": 1457
 },
 {
   "NFT": 365,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.59,
   "Rarity_Ranking": 1458
 },
 {
   "NFT": 1181,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.54,
   "Rarity_Ranking": 1459
 },
 {
   "NFT": 1434,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.52,
   "Rarity_Ranking": 1460
 },
 {
   "NFT": 1315,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.5,
   "Rarity_Ranking": 1461
 },
 {
   "NFT": 949,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Purple",
   "Eyes_Rarity": 17.99,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.49,
   "Rarity_Ranking": 1462
 },
 {
   "NFT": 1125,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.47,
   "Rarity_Ranking": 1463
 },
 {
   "NFT": 682,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.18,
   "Rarity_Ranking": 1464
 },
 {
   "NFT": 874,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.16,
   "Rarity_Ranking": 1465
 },
 {
   "NFT": 107,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.12,
   "Rarity_Ranking": 1466
 },
 {
   "NFT": 1171,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.08,
   "Rarity_Ranking": 1467
 },
 {
   "NFT": 1440,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 55.02,
   "Rarity_Ranking": 1468
 },
 {
   "NFT": 853,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 54.97,
   "Rarity_Ranking": 1469
 },
 {
   "NFT": 935,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 54.69,
   "Rarity_Ranking": 1470
 },
 {
   "NFT": 1122,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 54.63,
   "Rarity_Ranking": 1471
 },
 {
   "NFT": 611,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 54.57,
   "Rarity_Ranking": 1472
 },
 {
   "NFT": 408,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 54.53,
   "Rarity_Ranking": 1473
 },
 {
   "NFT": 728,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 54.46,
   "Rarity_Ranking": 1474
 },
 {
   "NFT": 1341,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 54.42,
   "Rarity_Ranking": 1475
 },
 {
   "NFT": 1507,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 54.2,
   "Rarity_Ranking": 1476
 },
 {
   "NFT": 623,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 54.18,
   "Rarity_Ranking": 1477
 },
 {
   "NFT": 1006,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "Earrings",
   "Marks_Rarity": 18.25,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 54.18,
   "Rarity_Ranking": 1478
 },
 {
   "NFT": 292,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 54.12,
   "Rarity_Ranking": 1479
 },
 {
   "NFT": 401,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 54.06,
   "Rarity_Ranking": 1480
 },
 {
   "NFT": 421,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 54.03,
   "Rarity_Ranking": 1481
 },
 {
   "NFT": 991,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 53.61,
   "Rarity_Ranking": 1482
 },
 {
   "NFT": 1345,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 53.57,
   "Rarity_Ranking": 1483
 },
 {
   "NFT": 305,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 53.37,
   "Rarity_Ranking": 1484
 },
 {
   "NFT": 1050,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 53.36,
   "Rarity_Ranking": 1485
 },
 {
   "NFT": 1357,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 53.04,
   "Rarity_Ranking": 1486
 },
 {
   "NFT": 1015,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 52.81,
   "Rarity_Ranking": 1487
 },
 {
   "NFT": 5,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 52.68,
   "Rarity_Ranking": 1488
 },
 {
   "NFT": 145,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 52.49,
   "Rarity_Ranking": 1489
 },
 {
   "NFT": 1097,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 52.39,
   "Rarity_Ranking": 1490
 },
 {
   "NFT": 1520,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 52.32,
   "Rarity_Ranking": 1491
 },
 {
   "NFT": 1532,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 52.09,
   "Rarity_Ranking": 1492
 },
 {
   "NFT": 1545,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Green",
   "Beard_Rarity": 22.94,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 51.87,
   "Rarity_Ranking": 1493
 },
 {
   "NFT": 183,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Long Steel",
   "Helmet_Rarity": 18.48,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 51.8,
   "Rarity_Ranking": 1494
 },
 {
   "NFT": 437,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "Makeup",
   "Marks_Rarity": 18.69,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 51.58,
   "Rarity_Ranking": 1495
 },
 {
   "NFT": 338,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 51.25,
   "Rarity_Ranking": 1496
 },
 {
   "NFT": 1108,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 51.1,
   "Rarity_Ranking": 1497
 },
 {
   "NFT": 1486,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 50.98,
   "Rarity_Ranking": 1498
 },
 {
   "NFT": 551,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 50.95,
   "Rarity_Ranking": 1499
 },
 {
   "NFT": 783,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 50.76,
   "Rarity_Ranking": 1500
 },
 {
   "NFT": 857,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "buger",
   "Marks_Rarity": 12.87,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 50.48,
   "Rarity_Ranking": 1501
 },
 {
   "NFT": 156,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 50.39,
   "Rarity_Ranking": 1502
 },
 {
   "NFT": 931,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 50.39,
   "Rarity_Ranking": 1503
 },
 {
   "NFT": 921,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 50.35,
   "Rarity_Ranking": 1504
 },
 {
   "NFT": 559,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 49.99,
   "Rarity_Ranking": 1505
 },
 {
   "NFT": 1094,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 49.9,
   "Rarity_Ranking": 1506
 },
 {
   "NFT": 461,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 49.83,
   "Rarity_Ranking": 1507
 },
 {
   "NFT": 206,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "scar",
   "Marks_Rarity": 13.64,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 49.68,
   "Rarity_Ranking": 1508
 },
 {
   "NFT": 1359,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 49.29,
   "Rarity_Ranking": 1509
 },
 {
   "NFT": 856,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 48.99,
   "Rarity_Ranking": 1510
 },
 {
   "NFT": 132,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 48.98,
   "Rarity_Ranking": 1511
 },
 {
   "NFT": 1445,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 48.7,
   "Rarity_Ranking": 1512
 },
 {
   "NFT": 302,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Blue Cool",
   "Beard_Rarity": 19.61,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 48.54,
   "Rarity_Ranking": 1513
 },
 {
   "NFT": 466,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 48.54,
   "Rarity_Ranking": 1514
 },
 {
   "NFT": 601,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 48.54,
   "Rarity_Ranking": 1515
 },
 {
   "NFT": 1030,
   "Background": "Gold ",
   "Background_Rarity": 56.18,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 48.49,
   "Rarity_Ranking": 1516
 },
 {
   "NFT": 38,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 48.45,
   "Rarity_Ranking": 1517
 },
 {
   "NFT": 1086,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 48.24,
   "Rarity_Ranking": 1518
 },
 {
   "NFT": 121,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 48.14,
   "Rarity_Ranking": 1519
 },
 {
   "NFT": 217,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 48.14,
   "Rarity_Ranking": 1520
 },
 {
   "NFT": 969,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 48.05,
   "Rarity_Ranking": 1521
 },
 {
   "NFT": 1040,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 47.98,
   "Rarity_Ranking": 1522
 },
 {
   "NFT": 1316,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 47.44,
   "Rarity_Ranking": 1523
 },
 {
   "NFT": 63,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Blonde",
   "Hair_Rarity": 18.8,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 46.89,
   "Rarity_Ranking": 1524
 },
 {
   "NFT": 211,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "Captain",
   "Rank_Rarity": 9.97,
   "Rarity_Score": 46.83,
   "Rarity_Ranking": 1525
 },
 {
   "NFT": 915,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 46.68,
   "Rarity_Ranking": 1526
 },
 {
   "NFT": 702,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 46.51,
   "Rarity_Ranking": 1527
 },
 {
   "NFT": 222,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 46.4,
   "Rarity_Ranking": 1528
 },
 {
   "NFT": 696,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 46.09,
   "Rarity_Ranking": 1529
 },
 {
   "NFT": 1107,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 45.95,
   "Rarity_Ranking": 1530
 },
 {
   "NFT": 74,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Pirate patch",
   "Gadgets_Rarity": 13.05,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 45.92,
   "Rarity_Ranking": 1531
 },
 {
   "NFT": 39,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 45.81,
   "Rarity_Ranking": 1532
 },
 {
   "NFT": 435,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 45.63,
   "Rarity_Ranking": 1533
 },
 {
   "NFT": 791,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 45.29,
   "Rarity_Ranking": 1534
 },
 {
   "NFT": 771,
   "Background": "Multicolor ",
   "Background_Rarity": 19.23,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 44.96,
   "Rarity_Ranking": 1535
 },
 {
   "NFT": 420,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 44.77,
   "Rarity_Ranking": 1536
 },
 {
   "NFT": 1267,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 44.4,
   "Rarity_Ranking": 1537
 },
 {
   "NFT": 472,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 44.39,
   "Rarity_Ranking": 1538
 },
 {
   "NFT": 782,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "mole",
   "Marks_Rarity": 12.61,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 44.33,
   "Rarity_Ranking": 1539
 },
 {
   "NFT": 644,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Read Glass",
   "Gadgets_Rarity": 11.4,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 44.16,
   "Rarity_Ranking": 1540
 },
 {
   "NFT": 1165,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 43.53,
   "Rarity_Ranking": 1541
 },
 {
   "NFT": 10,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 42.92,
   "Rarity_Ranking": 1542
 },
 {
   "NFT": 1018,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 42.84,
   "Rarity_Ranking": 1543
 },
 {
   "NFT": 629,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 42.7,
   "Rarity_Ranking": 1544
 },
 {
   "NFT": 810,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 42.68,
   "Rarity_Ranking": 1545
 },
 {
   "NFT": 680,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Sun Glasses",
   "Gadgets_Rarity": 11.99,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 42.67,
   "Rarity_Ranking": 1546
 },
 {
   "NFT": 23,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 42.55,
   "Rarity_Ranking": 1547
 },
 {
   "NFT": 522,
   "Background": "Blue ",
   "Background_Rarity": 4.57,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Red",
   "Helmet_Rarity": 13,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 42.51,
   "Rarity_Ranking": 1548
 },
 {
   "NFT": 236,
   "Background": "Black ",
   "Background_Rarity": 11.56,
   "Head": "Vampire Head",
   "Head_Ratity": 4.98,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Pink",
   "Helmet_Rarity": 10.59,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 41.71,
   "Rarity_Ranking": 1549
 },
 {
   "NFT": 1158,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Bushy ",
   "Beard_Rarity": 10.3,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 41.44,
   "Rarity_Ranking": 1550
 },
 {
   "NFT": 1224,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 41.43,
   "Rarity_Ranking": 1551
 },
 {
   "NFT": 212,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Green",
   "Eyes_Rarity": 7.51,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Black",
   "Helmet_Rarity": 12.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 41.33,
   "Rarity_Ranking": 1552
 },
 {
   "NFT": 468,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Frozen Head",
   "Head_Ratity": 12.64,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 41.23,
   "Rarity_Ranking": 1553
 },
 {
   "NFT": 312,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 40.86,
   "Rarity_Ranking": 1554
 },
 {
   "NFT": 691,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Green",
   "Helmet_Rarity": 16.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 40.71,
   "Rarity_Ranking": 1555
 },
 {
   "NFT": 270,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 39.68,
   "Rarity_Ranking": 1556
 },
 {
   "NFT": 968,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Fashion Glasses",
   "Gadgets_Rarity": 11.76,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 39.67,
   "Rarity_Ranking": 1557
 },
 {
   "NFT": 1131,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Gold Teeth",
   "Mouth_Rarity": 12.55,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 38.84,
   "Rarity_Ranking": 1558
 },
 {
   "NFT": 1301,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nose ring",
   "Marks_Rarity": 6.23,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 38.81,
   "Rarity_Ranking": 1559
 },
 {
   "NFT": 181,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Blue",
   "Eyes_Rarity": 6.09,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Bronze",
   "Helmet_Rarity": 11.07,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 38.69,
   "Rarity_Ranking": 1560
 },
 {
   "NFT": 946,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Rounded Bronce",
   "Helmet_Rarity": 13.51,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 38.46,
   "Rarity_Ranking": 1561
 },
 {
   "NFT": 953,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Black",
   "Hair_Rarity": 5.38,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Wide Open",
   "Mouth_Rarity": 10.57,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 38.31,
   "Rarity_Ranking": 1562
 },
 {
   "NFT": 1519,
   "Background": "Silver ",
   "Background_Rarity": 7.38,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Brown Bushy",
   "Beard_Rarity": 7.21,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Funny",
   "Mouth_Rarity": 10.81,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 38.26,
   "Rarity_Ranking": 1563
 },
 {
   "NFT": 1230,
   "Background": "Green ",
   "Background_Rarity": 2.17,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Yellow",
   "Eyes_Rarity": 5.92,
   "Beard": "Grey Cool",
   "Beard_Rarity": 5.86,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Brown",
   "Hair_Rarity": 5.62,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 37.2,
   "Rarity_Ranking": 1564
 },
 {
   "NFT": 1079,
   "Background": "Radial",
   "Background_Rarity": 107.53,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Long Brown",
   "Hair_Rarity": 5.74,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Steel",
   "Helmet_Rarity": 8.76,
   "Mouth": "White Teeth",
   "Mouth_Rarity": 3.61,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 33.37,
   "Rarity_Ranking": 1565
 },
 {
   "NFT": 1092,
   "Background": "Space ",
   "Background_Rarity": 50.25,
   "Head": "Normal Head",
   "Head_Ratity": 1.77,
   "Eyes": "Brown",
   "Eyes_Rarity": 2.43,
   "Beard": "Partial Grey",
   "Beard_Rarity": 5.4,
   "Marks": "nothing",
   "Marks_Rarity": 2.14,
   "Hair": "Short Black",
   "Hair_Rarity": 5.09,
   "Gadgets": "Nothing",
   "Gadgets_Rarity": 2.33,
   "Helmet": "Small Black",
   "Helmet_Rarity": 5.29,
   "Mouth": "Small",
   "Mouth_Rarity": 7.11,
   "Rank": "none",
   "Rank_Rarity": 1.19,
   "Rarity_Score": 32.75,
   "Rarity_Ranking": 1566
 }
]

export function getRarityById(id) {
  return vikings.find((s) => s.NFT === id)
}

export function getData() {
  return vikings.sort((a, b) => (b.Rarity_Score - a.Rarity_Score));
}