import React from "react";
import { Works, Welcome, OpenSea } from "../../components";

function Home() {
  return (
    <div>
      <Welcome />
      <Works />
      <OpenSea />
    </div>
  );
}
export default Home;
