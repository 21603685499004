import React from "react";
import { SpacerLarge, SpacerSmall } from "../../Globalstyles";

import {
    WelcomeContainer,
    WelcomeContent,
    WelcomeImg,
    WelcomeContentText,
    WelcomeContentTitle,
    WelcomeText,
    HeroSubTitle,
    RedStrong,
    Img,
} from "./Lore.styles";

import VikingImg from "../../images/vikingLore.jpg";
import vampireVikings from "../../images/lore/vampireVikings.png";
import pinkVikings from "../../images/lore/pinkVikings.png";
import zombieVikings from "../../images/lore/zombieVikings.png";
import frozenVikings from "../../images/lore/frozenVikings.png";
import steelVikings from "../../images/lore/steelVikings.png";
import humanVikings from "../../images/lore/humanVikings.png";
import varietyVikings from "../../images/lore/varietyVikings.png";

const Lore = () => {
    return (
        <>
            <HeroSubTitle color="black">
                Welcome King - This is the Viking Punks Metaverse
            </HeroSubTitle>
            <WelcomeContainer>
                <WelcomeContent>
                    <WelcomeContentText>
                        <WelcomeContentTitle>Some History</WelcomeContentTitle>

                        <WelcomeText>
                        From around <strong>A.D. 800 to the 11th century</strong>, a vast number of Scandinavians left their homelands to seek their fortunes elsewhere. These <RedStrong>seafaring warriors–known collectively as Vikings</RedStrong> or 
                        Norsemen (“Northmen”)–began by raiding coastal sites, especially undefended monasteries, in the British Isles. {" "} 
                        <SpacerLarge></SpacerLarge>
                        
                        Over the next three centuries, they would leave their mark as pirates, raiders, traders and settlers on much of Britain and the European continent, as well as parts of modern-day Russia, Iceland, Greenland and Newfoundland.

                        Contrary to some popular conceptions of the Vikings, they were not a “race” linked by ties of common ancestry or patriotism, and could not be defined by any particular sense of “Viking-ness.” 
                        Most of the Vikings whose activities are best known come from the areas now known as Denmark, Norway and Sweden, though there are mentions in historical records of Finnish, Estonian and Saami Vikings as well. 
                        Their common ground–and what made them different from the European peoples they confronted–was that they came from a foreign land, they were not “civilized” in the local understanding of the word and–most importantly–they were 
                        badass!

                        <SpacerLarge></SpacerLarge>
                        The exact reasons for <RedStrong>Vikings</RedStrong> venturing out from their homeland are uncertain; some have suggested it was due to overpopulation of their homeland, but the earliest Vikings were looking for riches, not land. 
                        During centuries the world thought that the <RedStrong>Vikings</RedStrong> disappeared... that is far from the truth! These relentless warriors made their way to the <RedStrong>Blockchain</RedStrong> world! 
                        They are conquering the seven seas and the <strong>OpenSea</strong>. 

                        <SpacerSmall></SpacerSmall>

                        Hear their story.
                        </WelcomeText>
                    </WelcomeContentText>
                </WelcomeContent>
            </WelcomeContainer>

<WelcomeContainer>
<WelcomeContent>
    <WelcomeImg>
        <Img src={VikingImg} alt="The Viking Punks" />
    </WelcomeImg>
    <WelcomeContentText>
        <WelcomeContentTitle>The Vikings</WelcomeContentTitle>
        <WelcomeText>
            The <strong> Viking Punks </strong> are unstopabble.{" "}
            We will conquer <strong>Opensea</strong> and the Polygon blockchain. We are creating a VikingVerse for you to understand the soul of this collection.
            Imagine in the future... to be able to fight with your Vikings, get them weapons, compete against other hordes.
            The future is unimaginable, the possibilities endless. We are not just another collection, <strong>WE ARE VIKINGS</strong>, we are <strong> KINGS </strong>.
        </WelcomeText>
    </WelcomeContentText>
</WelcomeContent>
</WelcomeContainer>

<WelcomeContainer>
<WelcomeContent>

    <WelcomeContentText>
        <WelcomeContentTitle>The Human Vikings</WelcomeContentTitle>

        <WelcomeText>
        As you will see in the following paragraphs, our warriors faced multiple battles and episodes that made them change.
        However, some of them are just exactly as when they left their lands.
        They are humans and behave like humans - but of course they are Vikings so they pillage, drink, party and eat like crazy.
        </WelcomeText>
    </WelcomeContentText>
    <WelcomeImg>
        <Img src={humanVikings} alt="The Human Viking" />
    </WelcomeImg>
</WelcomeContent>
</WelcomeContainer>


<WelcomeContainer>
<WelcomeContent>

    <WelcomeContentText>
        <WelcomeContentTitle>The Vampire Vikings</WelcomeContentTitle>

        <WelcomeText>
                            In the late 11th century, the Vikings found themselves far from the coast,
                            in what is now Eastern Europe. Nobody knows exactly where, but there is some conjecture that they visited ancient lands to be called Transylvania later in history...Vlad the Impaler and the legend of Dracula was not to be known for another 400 years...but when this raiding party returned, many were unable to function during the day, could not stand garlic, and had a thirst for blood...were vampires roaming the land back then?
        </WelcomeText>
    </WelcomeContentText>
    <WelcomeImg>
        <Img src={vampireVikings} alt="The Vampire Viking" />
    </WelcomeImg>
</WelcomeContent>
</WelcomeContainer>


<WelcomeContainer>
<WelcomeContent>
    <WelcomeContentText>
        <WelcomeContentTitle>The Pink Vikings</WelcomeContentTitle>
        <WelcomeText>
            Everyone knows that Vikings like to party...in some cases,
                            it's possible that, along with honey wine and mead, Vikings consumed mushrooms
                            or stinking henbane to conjure visits with the Gods. One group found an interesting pink mushroom in the forests of Germania - which, when consumed, caused hallucinations and redness of the face and skin. Some vikings never recovered from this "trip", and their skin was forever stained...
        </WelcomeText>
    </WelcomeContentText>
    <WelcomeImg>
        <Img src={pinkVikings} alt="The Pink Viking" />
    </WelcomeImg>
</WelcomeContent>
</WelcomeContainer>

<WelcomeContainer>
<WelcomeContent>
    <WelcomeContentText>
        <WelcomeContentTitle>The Zombie Vikings</WelcomeContentTitle>
        <WelcomeText>
Vikings are fierce warriors, and the Berserkers were the fiercest of all. Feeling no pain, and possessing the strength of three men, these warriors were so skillful and ruthless that, even in death, they fought. They escaped the flames of cremation, and now wander the lands seeking pillage, plunder, and human flesh...the returned Berserkers have a green colour, and a very characteristic smell. Be careful. They are a small number but super dangerous.        </WelcomeText>
    </WelcomeContentText>
    <WelcomeImg>
        <Img src={zombieVikings} alt="The Zombie Viking" />
    </WelcomeImg>
</WelcomeContent>
</WelcomeContainer>

<WelcomeContainer>
<WelcomeContent>
    <WelcomeContentText>
        <WelcomeContentTitle>The Frozen Vikings</WelcomeContentTitle>
        <WelcomeText>
Some Vikings headed West across the North Atlantic toward the mythic lands of Vinland. Caught in a ferocious storm, they were cast ashore at Greenland, many miles from home. Many died in the cold and harsh weather, but some survived and prospered in the barren lands. Their skin is forever ashen from the cold and low light, their beards encrusted in ice...they attack by the glow of the Northern Lights...        </WelcomeText>
    </WelcomeContentText>
    <WelcomeImg>
        <Img src={frozenVikings} alt="The Frozen Viking" />
    </WelcomeImg>
</WelcomeContent>
</WelcomeContainer>


<WelcomeContainer>
<WelcomeContent>
    <WelcomeContentText>
        <WelcomeContentTitle>The Steel Vikings</WelcomeContentTitle>
        <WelcomeText>
        Vikings made world-class weapons, strong and capable, of forged steel. They also crafted fine helmets, with some craftsmen so capable that they were able to match facial features. 
        Replacing missing noses was the first crude use, but later fine steel masks became the rage of leaders and conquerors. 
        Enemies ran in fear, as they could see no vulnerable points to attack...
        </WelcomeText>
    </WelcomeContentText>
    <WelcomeImg>
        <Img src={steelVikings} alt="The Steel Viking" />
    </WelcomeImg>
</WelcomeContent>
</WelcomeContainer>

<WelcomeContainer>
<WelcomeContent>
    <WelcomeImg>
        <Img src={varietyVikings} alt="The Trasure of the Viking" />
    </WelcomeImg>
    <WelcomeContentText>
        <WelcomeContentTitle>The Vikings Treasure</WelcomeContentTitle>
        <WelcomeText>
        The <strong> Viking Punks </strong> are unstoppable  warriors.
        With each battle fought they increased their treasures, gold masks, ivory masks, technological visors, pipes, etc. For example, against the pirates they took every single pirate patch from them, and even with 2 good  eyes, they wear the patches to show that they were victorious.
        They love to wear glasses, even if they are broken. They love shiny things, and they basically adorn themselves in whatever they find. The more they have, the more interesting the Viking becomes.
        They also wear their scars proudly. Something to highlight here, they are warriors, so you will see them proudly showing their military rank through medals as well.
        We love the generals... however they are a small number.
        You will see some combinations, perhaps Zombies that have Vampire fangs!
        Cross breeding? Imagine the possibilities!

            <SpacerLarge></SpacerLarge>

            <strong>If you want to see how rare and unique they are go to <RedStrong>Ranks</RedStrong> and check your Viking.</strong>
        </WelcomeText>
    </WelcomeContentText>
</WelcomeContent>
</WelcomeContainer>


        </>
    );
};

export default Lore;
