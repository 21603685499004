import React from "react";


import {
  RecipeCard,
  RecipeCardContent,
  RecipeCardHeading,
  RecipeCardDetails,
  RecipeCardItems,
  RecipeCardTitle,
  RecipeCardItem,
  RecipeCardIcon,
  RecipeCardText,
  Img,
} from "./Recipes.styles";

class RarityCard extends React.Component {
  render() {
    const { rarity } = this.props;
    return (
      <RecipeCard>
        {/* <Img src={rarity.imagen} alt={rarity.titulo} /> */}
        <Img src={require('../../images/' + rarity.imagen)} alt={rarity.titulo} />
        
        <RecipeCardContent>
          <RecipeCardHeading>{rarity.titulo}</RecipeCardHeading>
          <RecipeCardDetails>
            <RecipeCardItems>
              <RecipeCardTitle>Amount</RecipeCardTitle>
              <RecipeCardItem>
                <RecipeCardIcon />
                <RecipeCardText>{rarity.amount}</RecipeCardText>
              </RecipeCardItem>
            </RecipeCardItems>
            <RecipeCardItems>
              <RecipeCardTitle>Expected</RecipeCardTitle>
              <RecipeCardText>{rarity.NFTS}</RecipeCardText>
            </RecipeCardItems>
            <RecipeCardItems>
              <RecipeCardTitle>Occurrence %</RecipeCardTitle>
              <RecipeCardText>{rarity.occurrence}</RecipeCardText>
            </RecipeCardItems>
          </RecipeCardDetails>
        </RecipeCardContent>
      </RecipeCard>
    );
  }
}
export default RarityCard;
