import React from "react";
import {
  WorksContainer,
  WorksContent,
  WorksTitle,
  WorksCardContent,
  WorksCard,
  WorksIconContainer,
  WorksIcon1,
  WorksIcon2,
  WorksIcon3,
  WorksIcon4,
  WorksCardTitle,
  WorksCardText,
} from "./works.styles";

const Works = () => {
  return (

      <WorksContent>
        <WorksContainer>
          <WorksTitle> How to become part of the Viking fam</WorksTitle>
          <WorksCardContent>        
            <WorksCard>
              <WorksIconContainer>
                <WorksIcon1 className="Icon" />
              </WorksIconContainer>
              <WorksCardTitle>Connect</WorksCardTitle>
              <WorksCardText>
                First you need to connect your Metamask wallet to the{" "}
                <strong> Polygon Network. </strong> You can learn how to do it
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://medium.com/stakingbits/setting-up-metamask-for-polygon-matic-network-838058f6d844"
                  style={{
                    color: "red",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  here 
                </a>
              </WorksCardText>
            </WorksCard>
          
            <WorksCard>
              <WorksIconContainer>
                <WorksIcon2 />
              </WorksIconContainer>
              <WorksCardTitle>Mint</WorksCardTitle>
              <WorksCardText>
                To mint a <strong> Viking Punk </strong> you just need{" "}
                <strong> Matic </strong> on your wallet. Minting a Viking is just 1 Matic.
              </WorksCardText>
            </WorksCard>
     
            <WorksCard>
              <WorksIconContainer>
                <WorksIcon3 />
              </WorksIconContainer>
              <WorksCardTitle>Opensea</WorksCardTitle>
              <WorksCardText>
                To see your minted <strong>NFT</strong> you can check it on{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://opensea.io/collection/the-viking-punks-v2"
                  style={{
                    color: "red",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  OpenSea
                </a>{" "}
                where you can do whatever you want.
              </WorksCardText>
            </WorksCard>

            <WorksCard>
              <WorksIconContainer>
                <WorksIcon4/>
              </WorksIconContainer>
              <WorksCardTitle>Smart Contract</WorksCardTitle>
              <WorksCardText>
                Check our Verified <strong>Smart Contract</strong> on{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://polygonscan.com/address/0x881d006d70e4ff6f7cc3323f38a01ef90f6d84ea"
                  style={{
                    color: "red",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  PolygonScan
                </a>{" "}
                . We are open and transparent.
              </WorksCardText>
            </WorksCard>
          </WorksCardContent>
        </WorksContainer>
      </WorksContent>
  );
};

export default Works;
