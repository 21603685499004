import React from "react";

import { getRarityByCategory } from "../../data/data.js";
import RarityCard from "./../RarityCard/RarityCard";
import Rarities from "./../Rarities/Rarities";

import {
  RecipeCardWrapper,
  RecipeSmallCards,
} from "./Recipes.styles";


class Rarity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      datos: [],
      isLoaded: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let datos = await getRarityByCategory(this.props.match.params.id);

    this.setState(
      {
        datos: datos,
        isLoaded: datos === undefined ? false : true,
      },
      () => console.log("Done")
    );
  }
  render() {
    const { datos } = this.state;
    console.log(datos);
    return (
      <>
        <Rarities/>
        <RecipeCardWrapper>
        
            <RecipeSmallCards>
              {datos.map((s) => (
                <RarityCard {...this.props} key={s._id} rarity={s}></RarityCard>
              ))}
            </RecipeSmallCards>
        </RecipeCardWrapper>
      </>
    );
  }
}

export default Rarity;
