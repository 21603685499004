import React from "react";
import {
    WelcomeContainer,
    WelcomeContent,
    WelcomeImg,
    WelcomeContentText,
    WelcomeContentTitle,
    WelcomeText,
    HeroSubTitle,
    Img,
    ImgCard
} from "./Roadmap.styles";

import VikingImg from "../../images/vikingLore.jpg";
import CardExample  from "../../images/card1.png";
import { RedStrong, GreenStrong, OrangeStrong, BlueStrong } from "../Lore/Lore.styles";
import { SpacerSmall } from './../../Globalstyles';

const Roadmap = () => {
    return (
        <>
            <HeroSubTitle color="black">
                Hey Viking are you ready for what is coming?
            </HeroSubTitle>
            <HeroSubTitle color="black">
                Let's do it!
            </HeroSubTitle>

            <WelcomeContainer>

                <WelcomeContent>
   
                    <WelcomeContentText>
                        <WelcomeImg>
                            <Img src={VikingImg} alt=" The Viking Punks" />
                        </WelcomeImg>

                        <WelcomeContentTitle>Roadmap</WelcomeContentTitle>

                        <WelcomeText>
                            <ol>
                                <li>Create 10k Vikings <GreenStrong> - Done</GreenStrong></li>
                                <li>Deploy and Validate Smart Contract on Polygon <GreenStrong> - Done</GreenStrong></li>
                                <li>Create Opensea collection and profile <GreenStrong> - Done</GreenStrong></li>
                                <li>Create and deploy Dapp on Amazon Firebase <GreenStrong> - Done</GreenStrong></li>
                                <li>Publish and launch minting party on Twitter <GreenStrong> - Done</GreenStrong></li>
                                <li>Mint 100% of Vikings <OrangeStrong> - In Progress </OrangeStrong></li>
                            </ol>
                            <SpacerSmall></SpacerSmall>

                            <BlueStrong> What we will do:</BlueStrong>

                            <SpacerSmall></SpacerSmall>

                            <ul>
                                <li>Create a game where you make your <strong>Vikings</strong> fight other <strong>Vikings</strong> in a card game. Imagine like <strong>Magic the Gathering</strong>.</li>

                                <ImgCard height={"300px"} src={CardExample} alt=" Card " />

                                <li>Another collection will be created and will be <GreenStrong>airdropped to Viking Holders</GreenStrong> to arm their Vikings and prepare them for the fight (weapons, armours). <OrangeStrong> - In Progress </OrangeStrong></li>
                                <li>Weapon stats <strong>(Damage, Speed, etc)</strong> and Armour stats <strong>(Defense, etc)</strong> will be multiplied by the rarity of your <strong>Viking</strong>, race will also be taken into consideration, for example a Steel Vikings has a plus in Armour.</li>
                                <li>You will present your Viking to battle and your opponent will do the same, winner takes either the <strong>Viking</strong> (deathmatch) or the loot (weapons, armours) defined at the start.</li>
                                <li>Leaderboard of <strong>Vikings</strong> will be created at <BlueStrong>VikingPunks.space</BlueStrong></li>
                                <li>You will be able to sell your <strong>Vikings</strong> champion at <BlueStrong>VikingPunks.space</BlueStrong></li>
                                <li>Vikings might take a longboat and try to conquer other blockchains such as Solana. Holders will be airdropped the same # of Vikings held at Polygon.</li>
                            </ul>

                            <SpacerSmall></SpacerSmall>

                            <BlueStrong>Once we finish #6</BlueStrong> we will invest on development resources, we will do the following to deliver the vision:
                            
                            <SpacerSmall></SpacerSmall>

                            <ol>
                                <li>Create the Smart Contract for the game.</li>
                                <li>Test game on <strong>Polygon</strong> Testnet with some Vikings holders.</li>
                                <li>Release on <strong>Polygon</strong> Mainnet.</li>
                            </ol>

                            <SpacerSmall></SpacerSmall>

                            <strong>I also been thinking.... apart from the game, if we party like Vikings did? What if we make this work and we have the funds to party in a different city of the world every 3 or 6 months?
                            </strong> Let's do this, let's mint everything make the ball rolling and while developing let's plan location and plan for the #VikingPunks party!!!!
                            
                            Thank you so much fam! Remember: <strong>Once Viking, forever King.</strong>
                        </WelcomeText>
                    </WelcomeContentText>
                </WelcomeContent>


            </WelcomeContainer>
        </>
    );
};

export default Roadmap;
