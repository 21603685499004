import { HiThumbUp } from "react-icons/hi";
import styled from "styled-components";

export const WelcomeContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 7rem;
`;

export const WelcomeContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: #fff;
  padding: 1rem;

  @media only screen and (max-width: 1000px) {
    flex-direction: row;
  }
`;

export const HeroTitle = styled.h1`
  font-size: clamp(1rem, 10vw, 5rem);
  font-weight: 900;
  letter-spacing: 0.5rem;
  line-height: 1.3;
`;

export const HeroSubTitle = styled.h2`
  font-size: clamp(2rem, 3vw, 4rem);
  font-weight: 300;
  text-align: center;
  padding-top: 1rem;
  color: ${({ color }) => (color ? color : "#ffffff")};
  background: ${({ bgcolor }) => (bgcolor ? bgcolor : "none")};
`;

export const WelcomeImg = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ThumbsCardIcon = styled(HiThumbUp)`
  color: #e38b06;
  font-size: 3rem;
  margin-right: 1.3rem;
  margin-left: 1.3rem;
  @media only screen and (max-width: 700px) {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
`;

export const Img = styled.img`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  border-radius: 4px;
  height: ${({ height }) => (height ? height : "300px")};

  @media only screen and (min-width: 300px) {
    width: 300px;
  }
  @media only screen and (min-width: 400px) {
    width: 400px;
  }
  @media only screen and (min-width: 600px) {
    width: 500px;
  }
  @media only screen and (min-width: 800px) {
    width: 800px;
  }
  @media only screen and (min-width: 1000px) {
    width: 500px;
    height: 400px;
  }
  @media only screen and (min-width: 1200px) {
    width: 600px;
  }
  @media only screen and (min-width: 1500px) {
    width: 750px;
  }
  @media only screen and (min-width: 1800px) {
    width: 900px;
  }
`;

export const ImgCard = styled.img`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;
  border-radius: 4px;
  height: ${({ height }) => (height ? height : "300px")};

  @media only screen and (min-width: 300px) {
    width: 300px;
  }
  @media only screen and (min-width: 400px) {
    width: 400px;
  }
  @media only screen and (min-width: 600px) {
    width: 500px;
  }
  @media only screen and (min-width: 800px) {
    width: 800px;
  }
  @media only screen and (min-width: 1000px) {
    width: 500px;
    height: 400px;
  }
  @media only screen and (min-width: 1200px) {
    width: 600px;
  }
  @media only screen and (min-width: 1500px) {
    width: 750px;
  }
  @media only screen and (min-width: 1800px) {
    width: 900px;
  }
`;

export const WelcomeContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  @media only screen and (min-width: 1200px) {
    padding: 3rem 6rem;
  }
  @media only screen and (min-width: 1500px) {
    padding: 3rem 9rem;
  }
`;

export const WelcomeContentTitle = styled.h2`
  font-size: 3.6rem;
  font-weight: bold;
`;

export const WelcomeText = styled.p`
  font-size: 1.8rem;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
