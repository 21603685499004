import React from "react";
import {
  WelcomeContainer,
  WelcomeContent,
  WelcomeImg,
  WelcomeContentText,
  WelcomeContentTitle,
  WelcomeText,
  HeroSubTitle,
  Img,
} from "./Welcome.styles";

import VikingImg from "../../images/VikingImg.png";
import { SpacerLarge, SpacerSmall } from "../../Globalstyles";

const Welcome = () => {
  return (
    <>
    <SpacerLarge></SpacerLarge>
      <HeroSubTitle color="black">
                <strong>Only 10.000 NFTS</strong> will ever be Minted. 
      </HeroSubTitle>
      <HeroSubTitle color="black">
        Images and Metadata are on <strong>IPFS (Pinata)</strong> - You Own What you Mint
      </HeroSubTitle>
      <WelcomeContainer>

        <WelcomeContent>
          <WelcomeImg>
            <Img src={VikingImg} alt=" The Viking Punks" />
          </WelcomeImg>

          <WelcomeContentText>
            <WelcomeContentTitle>Welcome to Viking Punks</WelcomeContentTitle>
<SpacerSmall></SpacerSmall>
            <WelcomeText>
              The <strong> Viking Punks </strong> were born on the{" "}
              <strong>Harmony</strong> blockchain with what is going to be 100
              unique handcrafted <strong>Vikings</strong> with a detailed pixel
              art style published on{" "}
              <strong>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://t.co/JE4XFHoYJ3?amp=1"
                  style={{ color: "red", textDecoration: "none" }}
                >
                  {" "}
                  Davinci
                </a>
              </strong>
              .But you know them...they fight, drink and party hard! They
              conquer new lands, its on their DNA.
            </WelcomeText>

            <WelcomeText>
              This is why they took their long boat and with an army of 10k
              decided to conquer{" "}
              <strong>
                {" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://opensea.io/collection/the-viking-punks-v2"
                  style={{
                    color: "red",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  Opensea
                </a>
              </strong>{" "}
              .Now you need to discover them. Do not miss any single one! Join us
              in this trip to complete the collection and remember....once
              Viking, forever King! Welcome to the Viking fam!
            </WelcomeText>
          </WelcomeContentText>
        </WelcomeContent>
      </WelcomeContainer>
    </>
  );
};

export default Welcome;
