import React from "react";
import Navbar from "../Navbar/Navbar";
import { Button } from "../../Globalstyles";
import {
  HeroContainer,
  HeroContent,
  HeroContentText,
  HeroTitle,
  HeroTitleText,
  HeroSubTitle,
  HeroText,
  HeroBtn,
} from "./Hero.styles";

const Hero = () => {
  return (
    <div>
      <HeroContainer>
        <Navbar />
        <HeroContent>
          <HeroContentText>
            <HeroTitle>
              <HeroTitleText
                color={"yellow"}
                background={"#2222290"}
              >
                The Viking Punks
              </HeroTitleText>
              <HeroTitleText color={"yellow"}>Metaverse</HeroTitleText>
            </HeroTitle>   
            <HeroSubTitle color="White">
                We are a 10k pixel art style procedural collection that lives on
              the Polygon Network.
            </HeroSubTitle>
         {    <HeroBtn to="/mint">
              <Button bigRadius big bigFont >
                Mint Vikings! (Just 1 Matic)
              </Button>
            </HeroBtn> }
          </HeroContentText>
        </HeroContent>
      </HeroContainer>
    </div>
  );
};

export default Hero;
