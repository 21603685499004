import React from "react";


import {
  RecipeCard,
  RecipeCardContent,
  RecipeCardHeading,
  RecipeCardDetails,
  RecipeCardItems,
  RecipeCardTitle,
  RecipeCardItem,
  RecipeCardIcon,
  RecipeCardText,
  Img,
} from "./Ranks.styles";

class RarityRank extends React.Component {
  render() {
    const { viking } = this.props;
    return (
      
      <RecipeCard>
        <Img src={require('../../images/minted/' + viking.NFT + '.png')} alt="Minted Viking" />
        <RecipeCardContent>
          <RecipeCardHeading>Viking Punk #{viking.NFT}</RecipeCardHeading>
          <RecipeCardDetails>
            <RecipeCardItems>
              <RecipeCardTitle>Rarity</RecipeCardTitle>
              <RecipeCardItem>
                <RecipeCardIcon />
                <RecipeCardText>{viking.Rarity_Score}</RecipeCardText>
              </RecipeCardItem>
            </RecipeCardItems>
            <RecipeCardItems>
              <RecipeCardTitle>Ranking</RecipeCardTitle>
              <RecipeCardText>{viking.Rarity_Ranking}</RecipeCardText>
            </RecipeCardItems>
            <RecipeCardItems>
              <RecipeCardTitle>See it on OpenSea</RecipeCardTitle>
              <RecipeCardText>  
                <a
                target="_blank"
                rel="noreferrer"
                href={`https://opensea.io/assets/matic/0x881d006d70e4ff6f7cc3323f38a01ef90f6d84ea/${viking.NFT}`}
                style={{
                  color: "red",
                  textDecoration: "none",
                }}
              >
                {" "}
                  Here</a>
              </RecipeCardText>
            </RecipeCardItems>
          </RecipeCardDetails>
        </RecipeCardContent>
      </RecipeCard>
    );
  }
}
   
export default RarityRank;