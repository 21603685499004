import React from "react";
import { Link } from 'react-router-dom';
import {Button} from '../../Globalstyles';
import VikingImage from '../../images/VImage.png';
import {
    MealsContainer,
    MealsWrapper,
    MealsImage,
    Img,
    MealsContent,
    MealsContentTitle,
    MealsContentText,
    MealsContentItems,
    MealsContentList,
    MealsContentDetails,
    MealsContentIcon,
    MealsContentCategory,
    MealsContentBtn
} from './OpenSea.styles';


function Meals() {
    
    return (
        <div>
            <MealsContainer>
                <MealsWrapper>
                    <MealsImage>
                        <Img src={VikingImage} alt= "Personalized Chicken meal"/> 
                    </MealsImage>
                
                    <MealsContent>
                        <MealsContentTitle>OpenSea Collection</MealsContentTitle>
                        <MealsContentText>
                           Once you Mint, go to   <a
                  target="_blank"
                                rel="noopener noreferrer"
                  href="https://opensea.io/collection/the-viking-punks-v2"
                  style={{
                    color: "red",
                    textDecoration: "none",
                  }}
                >
                  {" "}
                  OpenSea
                </a>{" "} to see your NFT. Sell the guy, make some money! Remember that once Viking forever King. Lets grow the collection together.
                        </MealsContentText>
                        <MealsContentItems>
                            <MealsContentList>
                                <MealsContentDetails>
                                    <MealsContentIcon/>
                                    <MealsContentCategory> Super Fun</MealsContentCategory>
                                </MealsContentDetails>
                                <MealsContentDetails>
                                    <MealsContentIcon/>
                                    <MealsContentCategory> Different</MealsContentCategory>
                                </MealsContentDetails>
                                <MealsContentDetails>
                                    <MealsContentIcon/>
                                    <MealsContentCategory> Super Rare</MealsContentCategory>
                                </MealsContentDetails>
                                <MealsContentDetails>
                                    <MealsContentIcon/>
                                    <MealsContentCategory> Valuable</MealsContentCategory>
                                </MealsContentDetails>
            
                            </MealsContentList>
                            <MealsContentList>
                                <MealsContentDetails>
                                    <MealsContentIcon/>
                                    <MealsContentCategory> Reselling Power</MealsContentCategory>
                                </MealsContentDetails>
                                <MealsContentDetails>
                                    <MealsContentIcon/>
                                    <MealsContentCategory> Pixel Art</MealsContentCategory>
                                </MealsContentDetails>
                                <MealsContentDetails>
                                    <MealsContentIcon/>
                                    <MealsContentCategory> Use Case (soon)</MealsContentCategory>
                                </MealsContentDetails>
                                <MealsContentDetails>
                                    <MealsContentIcon/>
                                    <MealsContentCategory> Why Not?</MealsContentCategory>
                                </MealsContentDetails>
            
                            </MealsContentList>
                        </MealsContentItems>
                    </MealsContent>
                </MealsWrapper>
            </MealsContainer>
            
        </div>
    )
}

export default Meals;
