const raritydata = [
  {
    _id: "1",
    titulo: "Black Background",
    imagen: "backgrounds/Black.png",
    amount: "Few",
    NFTS: "865",
    occurrence: "8.65%",
    category: "backgrounds",
  },
  {
    _id: "2",
    titulo: "Blue Background",
    imagen: "backgrounds/Blue.png",
    amount: "Many",
    NFTS: "2186",
    occurrence: "21.86%",
    category: "backgrounds",
  },
  {
    _id: "3",
    titulo: "Green Background",
    imagen: "backgrounds/Green.png",
    amount: "Lots",
    NFTS: "4604",
    occurrence: "46.04%",
    category: "backgrounds",
  },
  {
    _id: "4",
    titulo: "Gold Background",
    imagen: "backgrounds/Gold.png",
    amount: "Little",
    NFTS: "178",
    occurrence: "1.78%",
    category: "backgrounds",
  },
  {
    _id: "5",
    titulo: "Multicolor Background",
    imagen: "backgrounds/Multicolor.png",
    amount: "Little",
    NFTS: "520",
    occurrence: "5.20%",
    category: "backgrounds",
  },
  {
    _id: "6",
    titulo: "Silver Background",
    imagen: "backgrounds/Silver.png",
    amount: "Little",
    NFTS: "1355",
    occurrence: "13.55%",
    category: "backgrounds",
  },
  {
    _id: "7",
    titulo: "Space Background",
    imagen: "backgrounds/Space.png",
    amount: "Little",
    NFTS: "199",
    occurrence: "1.99%",
    category: "backgrounds",
  },
  {
    _id: "8",
    titulo: "Radial Background",
    imagen: "backgrounds/Radial.png",
    amount: "Zero",
    NFTS: "98",
    occurrence: "0.98%",
    category: "backgrounds",
  },

    {
    _id: "9",
    titulo: "Normal Head",
    imagen: "heads/Normal.png",
    amount: "Lots",
    NFTS: "5655",
    occurrence: "56.55%",
    category: "heads",
  },
    
        {
    _id: "10",
    titulo: "Vampire Head",
    imagen: "heads/Vampire.png",
    amount: "Many",
    NFTS: "2007",
    occurrence: "20.07%",
    category: "heads",
  },
  {
    _id: "11",
    titulo: "Steel Head",
    imagen: "heads/Steel.png",
    amount: "Few",
    NFTS: "1060",
    occurrence: "10.60%",
    category: "heads",
  },

   {
    _id: "12",
    titulo: "Frozen Head",
    imagen: "heads/Frozen.png",
    amount: "Few",
    NFTS: "791",
    occurrence: "7.91%",
    category: "heads",
  },
   
      {
    _id: "13",
    titulo: "Pink Head",
    imagen: "heads/Pink.png",
    amount: "Little",
    NFTS: "273",
    occurrence: "2.73%",
    category: "heads",
  },
   {
    _id: "14",
    titulo: "Zombie Head",
    imagen: "heads/Zombie.png",
    amount: "Little",
    NFTS: "214",
    occurrence: "2.14%",
    category: "heads",
  },
   
      {
    _id: "15",
    titulo: "Brown Eyes",
    imagen: "eyes/Brown.png",
    amount: "Lots",
    NFTS: "4123",
    occurrence: "41.23%",
    category: "eyes",
  },
        {
    _id: "16",
    titulo: "Yellow Eyes",
    imagen: "eyes/Yellow.png",
    amount: "Many",
    NFTS: "1689",
    occurrence: "16.89%",
    category: "eyes",
  },
             {
    _id: "17",
    titulo: "Blue Eyes",
    imagen: "eyes/Blue.png",
    amount: "Many",
    NFTS: "1643",
    occurrence: "16.43%",
    category: "eyes",
  },
  {
    _id: "18",
    titulo: "Green Eyes",
    imagen: "eyes/Green.png",
    amount: "Few",
    NFTS: "1331",
    occurrence: "13.31%",
    category: "eyes",
  },
   {
    _id: "19",
    titulo: "Purple Eyes",
    imagen: "eyes/Purple.png",
    amount: "Little",
    NFTS: "556",
    occurrence: "5.56%",
    category: "eyes",
  },
  {
    _id: "20",
    titulo: "Blind Eyes",
    imagen: "eyes/Blind.png",
    amount: "Little",
    NFTS: "332",
    occurrence: "3.32%",
    category: "eyes",
  },
   {
    _id: "21",
    titulo: "Single Eye",
    imagen: "eyes/Single.png",
    amount: "Zero",
    NFTS: "86",
    occurrence: "0.86%",
    category: "eyes",
  },
    {
    _id: "22",
    titulo: "Mechanical Eyes",
    imagen: "eyes/Mechanical.png",
    amount: "Zero",
    NFTS: "80",
    occurrence: "0.80%",
    category: "eyes",
  },
  {
    _id: "22",
    titulo: "Bowie Eyes",
    imagen: "eyes/Bowie.png",
    amount: "Zero",
    NFTS: "78",
    occurrence: "0.78%",
    category: "eyes",
  },
 {
    _id: "36",
    titulo: "Nothing",
    imagen: "beards/nothing.png",
    amount: "Few",
    NFTS: "383",
    occurrence: "3.83%",
    category: "beards",
  },
  {
    _id: "23",
    titulo: "BadAss Beard",
    imagen: "beards/Badass.png",
    amount: "Few",
    NFTS: "112",
    occurrence: "1.12%",
    category: "beards",
  },
  {
    _id: "24",
    titulo: "Blue Bushy Beard",
    imagen: "beards/Blue Bushy.png",
    amount: "Zero",
    NFTS: "71",
    occurrence: "0.71%",
    category: "beards",
  },
{
    _id: "25",
    titulo: "Blue Cool Beard",
    imagen: "beards/Blue Cool.png",
    amount: "Few",
    NFTS: "510",
    occurrence: "5.10%",
    category: "beards",
  },
  {
    _id: "26",
    titulo: "Brown Bushy Beard",
    imagen: "beards/Brown Bushy.png",
    amount: "Few",
    NFTS: "1387",
    occurrence: "13.87%",
    category: "beards",
  },
  {
    _id: "27",
    titulo: "Green Bushy Beard",
    imagen: "beards/Green Bushy.png",
    amount: "Few",
    NFTS: "293",
    occurrence: "2.93%",
    category: "beards",
  },
  {
    _id: "28",
    titulo: "Green Cool Beard",
    imagen: "beards/Green Cool.png",
    amount: "Few",
    NFTS: "216",
    occurrence: "2.16%",
    category: "beards",
  },
  {
    _id: "29",
    titulo: "Grey Bushy Beard",
    imagen: "beards/Grey Bushy.png",
    amount: "Few",
    NFTS: "971",
    occurrence: "9.71%",
    category: "beards",
  },
  {
    _id: "30",
    titulo: "Grey Cool Beard",
    imagen: "beards/Grey Cool.png",
    amount: "Few",
    NFTS: "1707",
    occurrence: "17.07%",
    category: "beards",
  },
  {
    _id: "31",
    titulo: "Blue Mustache",
    imagen: "beards/Mustache Blue.png",
    amount: "Zero",
    NFTS: "77",
    occurrence: "0.77%",
    category: "beards",
  },
  {
    _id: "32",
    titulo: "Brown Mustache",
    imagen: "beards/Mustache Brown.png",
    amount: "Zero",
    NFTS: "77",
    occurrence: "0.77%",
    category: "beards",
  },
  {
    _id: "33",
    titulo: "Green Mustache",
    imagen: "beards/Mustache Green.png",
    amount: "Zero",
    NFTS: "96",
    occurrence: "0.96%",
    category: "beards",
  },
  {
    _id: "34",
    titulo: "Grey Mustache",
    imagen: "beards/Mustache Grey.png",
    amount: "Zero",
    NFTS: "88",
    occurrence: "0.88%",
    category: "beards",
  },
  {
    _id: "35",
    titulo: "Red Mustache",
    imagen: "beards/Mustache Red.png",
    amount: "Zero",
    NFTS: "84",
    occurrence: "0.84%",
    category: "beards",
  },
  {
    _id: "37",
    titulo: "Partial Green Beard",
    imagen: "beards/Partial Green.png",
    amount: "Few",
    NFTS: "436",
    occurrence: "4.36%",
    category: "beards",
  },
  {
    _id: "38",
    titulo: "Partial Grey Beard",
    imagen: "beards/Partial Grey.png",
    amount: "Many",
    NFTS: "1851",
    occurrence: "18.51%",
    category: "beards",
  },
  {
    _id: "39",
    titulo: "Partial Pink Beard",
    imagen: "beards/Partial Pink.png",
    amount: "Few",
    NFTS: "174",
    occurrence: "1.74%",
    category: "beards",
  },
  {
    _id: "40",
    titulo: "Pink Bushy Beard",
    imagen: "beards/Pink Bushy.png",
    amount: "Few",
    NFTS: "305",
    occurrence: "1.74%",
    category: "beards",
  },
   {
    _id: "40",
    titulo: "Splitted Blue Beard",
    imagen: "beards/Splitted Blue.png",
    amount: "Few",
    NFTS: "301",
    occurrence: "3.01%",
    category: "beards",
  },
 {
    _id: "41",
    titulo: "Splitted Brown Beard",
    imagen: "beards/Splitted Brown.png",
    amount: "Few",
    NFTS: "201",
    occurrence: "2.01%",
    category: "beards",
  },
 {
    _id: "42",
    titulo: "Splitted Green Beard",
    imagen: "beards/Splitted Green.png",
    amount: "Few",
    NFTS: "221",
    occurrence: "2.21%",
    category: "beards",
  },
 {
    _id: "43",
    titulo: "Splitted Grey Beard",
    imagen: "beards/Splitted Grey.png",
    amount: "Few",
    NFTS: "218",
    occurrence: "2.18%",
    category: "beards",
  },
 {
    _id: "44",
    titulo: "Splitted Pink Beard",
    imagen: "beards/Splitted Pink.png",
    amount: "Few",
    NFTS: "216",
    occurrence: "2.16%",
    category: "beards",
  },
 {
    _id: "45",
    titulo: "Bold",
    imagen: "hairs/Bold.png",
    amount: "Few",
    NFTS: "216",
    occurrence: "2.16%",
    category: "hairs",
  },
  {
    _id: "46",
    titulo: "Long Black Hair",
    imagen: "hairs/Long Black.png",
    amount: "Many",
    NFTS: "1860",
    occurrence: "18.60%",
    category: "hairs",
  },
  {
    _id: "47",
    titulo: "Long Blonde Hair",
    imagen: "hairs/Long Blonde.png",
    amount: "Few",
    NFTS: "522",
    occurrence: "5.22%",
    category: "hairs",
  },
  {
    _id: "48",
    titulo: "Long Blue Hair",
    imagen: "hairs/Long Blue.png",
    amount: "Few",
    NFTS: "225",
    occurrence: "2.25%",
    category: "hairs",
  },
  {
    _id: "49",
    titulo: "Long Brown Hair",
    imagen: "hairs/Long Brown.png",
    amount: "Many",
    NFTS: "1743",
    occurrence: "17.43%",
    category: "hairs",
  },
  {
    _id: "50",
    titulo: "Long Green Hair",
    imagen: "hairs/Long Green.png",
    amount: "Few",
    NFTS: "211",
    occurrence: "2.11%",
    category: "hairs",
  },
  {
    _id: "51",
    titulo: "Long Pink Hair",
    imagen: "hairs/Long Pink.png",
    amount: "Few",
    NFTS: "299",
    occurrence: "2.99%",
    category: "hairs",
  },
  {
    _id: "52",
    titulo: "Short Black Hair",
    imagen: "hairs/Short Black.png",
    amount: "Many",
    NFTS: "1965",
    occurrence: "19.65%",
    category: "hairs",
  },
  {
    _id: "53",
    titulo: "Short Blond Hair",
    imagen: "hairs/Short Blonde.png",
    amount: "Few",
    NFTS: "532",
    occurrence: "5.32%",
    category: "hairs",
  },
  {
    _id: "54",
    titulo: "Short Blue Hair",
    imagen: "hairs/Short Blue.png",
    amount: "Few",
    NFTS: "173",
    occurrence: "1.73%",
    category: "hairs",
  },
  {
    _id: "54",
    titulo: "Short Brown Hair",
    imagen: "hairs/Short Brown.png",
    amount: "Many",
    NFTS: "1779",
    occurrence: "17.79%",
    category: "hairs",
  },
  {
    _id: "55",
    titulo: "Short Green Hair",
    imagen: "hairs/Short Green.png",
    amount: "Few",
    NFTS: "232",
    occurrence: "2.32%",
    category: "hairs",
  },
  {
    _id: "55",
    titulo: "Short Pink Hair",
    imagen: "hairs/Short Pink.png",
    amount: "Few",
    NFTS: "301",
    occurrence: "3.01%",
    category: "hairs",
  },
  {
    _id: "56",
    titulo: "Big Blue Bubble Mouth",
    imagen: "mouths/Big Blue Bubble.png",
    amount: "Few",
    NFTS: "301",
    occurrence: "3.01%",
    category: "mouth",
  },
  {
    _id: "57",
    titulo: "Big Green Bubble Mouth",
    imagen: "mouths/Big Green Bubble.png",
    amount: "Few",
    NFTS: "251",
    occurrence: "2.51%",
    category: "mouth",
  },
  {
    _id: "58",
    titulo: "Big Pink Bubble Mouth",
    imagen: "mouths/Big Pink Bubble.png",
    amount: "Few",
    NFTS: "267",
    occurrence: "2.67%",
    category: "mouth",
  },
  {
    _id: "59",
    titulo: "Bubble Pipe Mouth",
    imagen: "mouths/Bubble Pipe.png",
    amount: "Few",
    NFTS: "211",
    occurrence: "2.11%",
    category: "mouth",
  },
  {
    _id: "60",
    titulo: "Cigar Mouth",
    imagen: "mouths/Cigar.png",
    amount: "Few",
    NFTS: "253",
    occurrence: "2.53%",
    category: "mouth",
  },
  {
    _id: "61",
    titulo: "Cigarrette Mouth",
    imagen: "mouths/Cigarette.png",
    amount: "Few",
    NFTS: "296",
    occurrence: "2.96%",
    category: "mouth",
  },
  {
    _id: "62",
    titulo: "Funny Mouth",
    imagen: "mouths/Funny.png",
    amount: "Many",
    NFTS: "925",
    occurrence: "9.25%",
    category: "mouth",
  },
  {
    _id: "63",
    titulo: "Gold Teeth Mouth",
    imagen: "mouths/Gold Teeth.png",
    amount: "Many",
    NFTS: "797",
    occurrence: "7.97%",
    category: "mouth",
  },
  {
    _id: "64",
    titulo: "Missing Teeth Mouth",
    imagen: "mouths/Missing Teeth.png",
    amount: "Few",
    NFTS: "476",
    occurrence: "4.76%",
    category: "mouth",
  },
  {
    _id: "65",
    titulo: "Small Blue Bubble Mouth",
    imagen: "mouths/Small Blue Bubble.png",
    amount: "Few",
    NFTS: "250",
    occurrence: "4.76%",
    category: "mouth",
  },
  {
    _id: "66",
    titulo: "Small Green Bubble Mouth",
    imagen: "mouths/Small Green Bubble.png",
    amount: "Few",
    NFTS: "229",
    occurrence: "2.29%",
    category: "mouth",
  },
  {
    _id: "67",
    titulo: "Small Pink Bubble Mouth",
    imagen: "mouths/Small Pink Bubble.png",
    amount: "Few",
    NFTS: "249",
    occurrence: "2.49%",
    category: "mouth",
  },
  {
    _id: "68",
    titulo: "Small Mouth",
    imagen: "mouths/Small.png",
    amount: "Many",
    NFTS: "1406",
    occurrence: "14.06%",
    category: "mouth",
  },
  {
    _id: "69",
    titulo: "Tabacco Pipe Mouth",
    imagen: "mouths/Tabacco Pipe.png",
    amount: "Few",
    NFTS: "357",
    occurrence: "3.57%",
    category: "mouth",
  },
  {
    _id: "70",
    titulo: "Vampire Mouth",
    imagen: "mouths/Vampire.png",
    amount: "Zero",
    NFTS: "87",
    occurrence: "0.87%",
    category: "mouth",
  },
  {
    _id: "71",
    titulo: "White Teeth Mouth",
    imagen: "mouths/White Teeth.png",
    amount: "Lots",
    NFTS: "2770",
    occurrence: "27.70%",
    category: "mouth",
  },
  {
    _id: "72",
    titulo: "Wide Open Mouth",
    imagen: "mouths/Wide Open.png",
    amount: "Many",
    NFTS: "946",
    occurrence: "9.46%",
    category: "mouth",
  },

  {
    _id: "73",
    titulo: "Buger",
    imagen: "marks/buger.png",
    amount: "Many",
    NFTS: "7.77",
    occurrence: "7.77%",
    category: "head-marks",
  },
  {
    _id: "74",
    titulo: "Double Buger",
    imagen: "marks/double buger.png",
    amount: "Few",
    NFTS: "234",
    occurrence: "2.34%",
    category: "head-marks",
  },
  {
    _id: "75",
    titulo: "Earrings",
    imagen: "marks/Earrings.png",
    amount: "Few",
    NFTS: "548",
    occurrence: "5.48%",
    category: "head-marks",
  },
   {
    _id: "76",
    titulo: "Makeup",
    imagen: "marks/Makeup.png",
    amount: "Few",
    NFTS: "535",
    occurrence: "5.35%",
    category: "head-marks",
  },
   {
    _id: "77",
    titulo: "Mole",
    imagen: "marks/mole.png",
    amount: "Few",
    NFTS: "793",
    occurrence: "7.93%",
    category: "head-marks",
  },
   {
    _id: "78",
    titulo: "Nose Ring",
    imagen: "marks/nose ring.png",
    amount: "Many",
    NFTS: "1605",
    occurrence: "16.05%",
    category: "head-marks",
  },
  {
    _id: "79",
    titulo: "Nothing",
    imagen: "marks/nothing.png",
    amount: "Lots",
    NFTS: "4681",
    occurrence: "46.81%",
    category: "head-marks",
  },
  {
    _id: "80",
    titulo: "Scar",
    imagen: "marks/scar.png",
    amount: "Many",
    NFTS: "733",
    occurrence: "7.33%",
    category: "head-marks",
  },
  {
    _id: "81",
    titulo: "Viking Punks Tattoo",
    imagen: "marks/VP Tattoo.png",
    amount: "Zero",
    NFTS: "94",
    occurrence: "0.94%",
    category: "head-marks",
  },
  {
    _id: "82",
    titulo: "Nothing",
    imagen: "ranks/none.png",
    amount: "Lots",
    NFTS: "8391",
    occurrence: "83.91%",
    category: "military-rank",
  },
  {
    _id: "83",
    titulo: "Captain",
    imagen: "ranks/Captain.png",
    amount: "Many",
    NFTS: "1003",
    occurrence: "10.03%",
    category: "military-rank",
  },
  {
    _id: "84",
    titulo: "Colonel",
    imagen: "ranks/Colonel.png",
    amount: "Many",
    NFTS: "499",
    occurrence: "4.99%",
    category: "military-rank",
  },
  {
    _id: "85",
    titulo: "General",
    imagen: "ranks/General.png",
    amount: "Zero",
    NFTS: "107",
    occurrence: "1.07%",
    category: "military-rank",
  },

  {
    _id: "86",
    titulo: "3D Glasses",
    imagen: "gadgets/1.png",
    amount: "Few",
    NFTS: "235",
    occurrence: "2.35%",
    category: "eye-gadgets",
  },
   {
    _id: "87",
    titulo: "Broken Glasses",
    imagen: "gadgets/2.png",
    amount: "Few",
    NFTS: "362",
    occurrence: "3.62%",
    category: "eye-gadgets",
  },
   {
    _id: "88",
    titulo: "Double Pirate Patch",
    imagen: "gadgets/3.png",
    amount: "Zero",
    NFTS: "57",
    occurrence: "0.57%",
    category: "eye-gadgets",
  },
   {
    _id: "89",
    titulo: "Fashion Glasses",
    imagen: "gadgets/4.png",
    amount: "Many",
    NFTS: "850",
    occurrence: "8.50%",
    category: "eye-gadgets",
  },
   {
    _id: "90",
    titulo: "Lennon Glasses",
    imagen: "gadgets/5.png",
    amount: "Zero",
    NFTS: "119",
    occurrence: "1.19%",
    category: "eye-gadgets",
  },
  {
    _id: "91",
    titulo: "Golden Full Mask",
    imagen: "gadgets/6.png",
    amount: "Zero",
    NFTS: "61",
    occurrence: "0.61%",
    category: "eye-gadgets",
  },
   {
    _id: "92",
    titulo: "Golden Mask",
    imagen: "gadgets/7.png",
    amount: "Few",
    NFTS: "187",
    occurrence: "1.87%",
    category: "eye-gadgets",
  },
  {
    _id: "93",
    titulo: "White Mask",
    imagen: "gadgets/8.png",
    amount: "Few",
    NFTS: "316",
    occurrence: "3.16%",
    category: "eye-gadgets",
  },
  {
    _id: "94",
    titulo: "Nothing",
    imagen: "gadgets/9.png",
    amount: "Lots",
    NFTS: "4283",
    occurrence: "42.83%",
    category: "eye-gadgets",
  },
  {
    _id: "95",
    titulo: "Pirate Patch",
    imagen: "gadgets/10.png",
    amount: "Few",
    NFTS: "766",
    occurrence: "7.66%",
    category: "eye-gadgets",
  },
  {
    _id: "96",
    titulo: "Read Glasses",
    imagen: "gadgets/11.png",
    amount: "Few",
    NFTS: "877",
    occurrence: "8.77%",
    category: "eye-gadgets",
  },
  {
    _id: "97",
    titulo: "Sun Glasses",
    imagen: "gadgets/12.png",
    amount: "Few",
    NFTS: "834",
    occurrence: "8.34%",
    category: "eye-gadgets",
  },
    {
    _id: "98",
    titulo: "Golden Visor",
    imagen: "gadgets/13.png",
    amount: "Zero",
    NFTS: "138",
    occurrence: "1.38%",
    category: "eye-gadgets",
  },
  {
    _id: "99",
    titulo: "Green Visor",
    imagen: "gadgets/14.png",
    amount: "Few",
    NFTS: "469",
    occurrence: "4.69%",
    category: "eye-gadgets",
  },
  {
    _id: "100",
    titulo: "Pink Visor",
    imagen: "gadgets/15.png",
    amount: "Few",
    NFTS: "382",
    occurrence: "3.82%",
    category: "eye-gadgets",
  },
  {
    _id: "101",
    titulo: "VR Headset",
    imagen: "gadgets/16.png",
    amount: "Zero",
    NFTS: "64",
    occurrence: "0.64%",
    category: "eye-gadgets",
  },
   {
    _id: "102",
    titulo: "Long Bronze",
    imagen: "helmets/1.png",
    amount: "Few",
    NFTS: "307",
    occurrence: "3.07%",
    category: "helmets",
  },
     {
    _id: "103",
    titulo: "Long Green",
    imagen: "helmets/2.png",
    amount: "Few",
    NFTS: "243",
    occurrence: "2.43%",
    category: "helmets",
  },
    {
    _id: "104",
    titulo: "Long Pink",
    imagen: "helmets/3.png",
    amount: "Few",
    NFTS: "300",
    occurrence: "3.00%",
    category: "helmets",
  },
    {
    _id: "105",
    titulo: "Long Steel",
    imagen: "helmets/4.png",
    amount: "Few",
    NFTS: "541",
    occurrence: "5.41%",
    category: "helmets",
  },
   {
    _id: "106",
    titulo: "Long White",
    imagen: "helmets/5.png",
    amount: "Few",
    NFTS: "205",
    occurrence: "2.05%",
    category: "helmets",
  },
  {
    _id: "107",
    titulo: "McDonalds",
    imagen: "helmets/6.png",
    amount: "Zero",
    NFTS: "120",
    occurrence: "1.20%",
    category: "helmets",
  },
  {
    _id: "106",
    titulo: "Rounded Black",
    imagen: "helmets/7.png",
    amount: "Many",
    NFTS: "814",
    occurrence: "8.14%",
    category: "helmets",
  },
   {
    _id: "107",
    titulo: "Rounded Bronze",
    imagen: "helmets/8.png",
    amount: "Many",
    NFTS: "740",
    occurrence: "7.40%",
    category: "helmets",
  },
   {
    _id: "108",
    titulo: "Rounded Green",
    imagen: "helmets/9.png",
    amount: "Many",
    NFTS: "470",
    occurrence: "4.70%",
    category: "helmets",
  },
   {
    _id: "109",
    titulo: "Rounded Red",
    imagen: "helmets/10.png",
    amount: "Many",
    NFTS: "769",
    occurrence: "7.69%",
    category: "helmets",
  },
    {
    _id: "110",
    titulo: "Small Black",
    imagen: "helmets/11.png",
    amount: "Lots",
    NFTS: "1889",
    occurrence: "18.89%",
    category: "helmets",
  },
     {
    _id: "111",
    titulo: "Small Bronze",
    imagen: "helmets/12.png",
    amount: "Many",
    NFTS: "903",
    occurrence: "9.03%",
    category: "helmets",
  },
    {
    _id: "111",
    titulo: "Small Green",
    imagen: "helmets/13.png",
    amount: "Many",
    NFTS: "614",
    occurrence: "6.14%",
    category: "helmets",
  },
  {
    _id: "112",
    titulo: "Small Pink",
    imagen: "helmets/14.png",
    amount: "Many",
    NFTS: "944",
    occurrence: "9.44%",
    category: "helmets",
  },
  {
    _id: "112",
    titulo: "Small Steel",
    imagen: "helmets/15.png",
    amount: "Lots",
    NFTS: "1141",
    occurrence: "11.41%",
    category: "helmets",
  },
];

export function getData() {
  return raritydata;
}

export function getRarityById(id) {
  return raritydata.find((s) => s._id === id);
}

export function getRarityByCategory(category) {
  return raritydata.filter((rarity) => rarity.category === category);
}
