import React from "react";
import {
  FooterSection,
  FooterContainer,
  FooterNewsletter,
  FooterNewsletterTitle,
  FooterNewsletterText,
  FooterLinkContainer,
  FooterLinksWrapper,
  FooterLinks,
  FooterLinkTitle,
  FooterLink,
  FooterCopyRight,
} from "./Footer.styles";
function Footer() {
  return (
    <div>
      <FooterSection>
        <FooterContainer>
          <FooterNewsletter>
            <FooterNewsletterTitle>
              Thank you for Visiting - Viking Punks
            </FooterNewsletterTitle>
            <FooterNewsletterText>
              We created this collection with a lot of love and passion
            </FooterNewsletterText>
          </FooterNewsletter>
          <FooterLinkContainer>
            <FooterLinksWrapper>
              <FooterLinks>
                <FooterLinkTitle>Rarities</FooterLinkTitle>
                <FooterLink to="/rarities/backgrounds">Backgrounds</FooterLink>
                <FooterLink to="/rarities/heads">Heads</FooterLink>
                <FooterLink to="/rarities/beards">Beards</FooterLink>
                <FooterLink to="/rarities/eyes">Eyes</FooterLink>
                <FooterLink to="/rarities/eye-gadgets">Gadgets</FooterLink>
              </FooterLinks>
              <FooterLinks>
                <FooterLinkTitle>Rarities</FooterLinkTitle>
                <FooterLink to="/rarities/hairs">Hairs</FooterLink>
                <FooterLink to="/rarities/helmets">Helmets</FooterLink>
                <FooterLink to="/rarities/head-marks">Marks</FooterLink>
                <FooterLink to="/rarities/mouth">Mouths</FooterLink>
                <FooterLink to="/rarities/military-rank">Ranks</FooterLink>
              </FooterLinks>
            </FooterLinksWrapper>
          </FooterLinkContainer>
          <FooterCopyRight to="">
            {" "}
            &copy; Copyright 2021, The Viking Punks
          </FooterCopyRight>
        </FooterContainer>
      </FooterSection>
    </div>
  );
}

export default Footer;
