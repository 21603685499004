import React from "react";
import {Works, Rarities} from '../../components';

function About() {
    return (
        <div>
            <Rarities/>
            <Works/>
        </div>
    )
}

export default About;
